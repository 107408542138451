import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import ListContext from "layouts/apiContext/listContext";

function Controls() {

  const {
    boardList,
    fetchBoardList,
    classList,
    fetchClassList,
    subjectList,
    fetchSubjectList,
    topicList,
    fetchTopicList,
    rankList,
    fetchRankList,
    adminList,
    fetchAdminList,
    userList,
    fetchUserList,
    testList,
    fetchTestList,
    conceptList,
    fetchConceptList,
    bannerList,
    fetchBannerList,
    videoList,
    fetchVideoList,
    bestVideoList,
    fetchBestVideoList,
    subscriptionList,
    fetchSubscriptionList
  } = useContext(ListContext);

  const adminType = localStorage.getItem('userType')?.replace(/['"]+/g, '');

  useEffect(() => {

    fetchBoardList();
    fetchClassList();
    fetchSubjectList();
    fetchTopicList();
    fetchAdminList();
    fetchUserList();
    fetchTestList();
    fetchConceptList();
    fetchBannerList();
    fetchVideoList();
    fetchBestVideoList();
    fetchSubscriptionList();
    fetchRankList();
  
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
      <Grid container spacing={3}>
        {adminType === 'SuperAdmin' && <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <Link to={"/adminpermissions"}>
              <ComplexStatisticsCard
                color='primary'
                icon="verified"
                title="Admin Permissions"
                count={adminList?.length}
                percentage={{
                  color: "primary",
                  amount: "Create & Edit Admins",
                }}
                />
            </Link>
          </MDBox>
        </Grid>}
        {(adminType === 'SuperAdmin' || adminType === 'Sales Admin') && <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <Link to={"/userslist"}>
              <ComplexStatisticsCard
                color='success'
                icon="person_add"
                title="Users"
                count={userList.filter((data) => data.userType !== 'User' && data.userType !== null)?.length}
                percentage={{
                  color: "success",
                  amount: "View Users",
                }}
              />
            </Link>
          </MDBox>
        </Grid>}
        {(adminType === 'SuperAdmin' || adminType === 'Sales Admin') && <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <Link to={"/subscriptionList"}>
              <ComplexStatisticsCard
                color='error'
                icon="person_add"
                title="Subscriptions"
                count={subscriptionList?.length}
                percentage={{
                  color: "error",
                  amount: "View Subscriptions",
                }}
              />
            </Link>
          </MDBox>
        </Grid>}
        {/* {(adminType === 'SuperAdmin' || adminType === 'Sales Admin') && <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <Link to={"/userSubscriptionList"}>
              <ComplexStatisticsCard
                color='primary'
                icon="person_add"
                title="User Subscriptions"
                count={subscriptionList?.length}
                percentage={{
                  color: "primary",
                  amount: "View User Subscriptions",
                }}
              />
            </Link>
          </MDBox>
        </Grid>} */}
          {(adminType === 'SuperAdmin'  || adminType === 'Post Admin') && <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to={"/boardslist"}>
                <ComplexStatisticsCard
                  color='secondary'
                  icon="collections_bookmark"
                  title="Boards"
                  count={boardList?.length}
                  percentage={{
                    color: "secondary",
                    amount: "Create & Edit Boards",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>}
        {(adminType === 'SuperAdmin' || adminType === "Post Admin") && 
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to={"/classlist"}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="school"
                  title="Classes"
                  count={classList?.length}
                  percentage={{
                    color: "warning",
                    amount: "Create & Edit Classes",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>}
          {(adminType === 'SuperAdmin' || adminType === 'Post Admin') && <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to={"/subjectslist"}>
                <ComplexStatisticsCard
                  color='error'
                  icon="subject"
                  title="Subjects"
                  count={subjectList?.length}
                  percentage={{
                    color: "error",
                    amount: "Create & Edit Subjects",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>}
          {(adminType === 'SuperAdmin' || adminType === 'Post Admin') && <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to={"/topiclist"}>
                <ComplexStatisticsCard
                  color='info'
                  icon="topic"
                  title="Topics"
                  count={topicList?.length}
                  percentage={{
                    color: "info",
                    amount: "Create & Edit Topics",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>}
          {(adminType === 'SuperAdmin' || adminType === 'Post Admin') && <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to={"/ranklist"}>
                <ComplexStatisticsCard
                  color="secondary"
                  icon="book"
                  title="Rank List"
                  count={rankList?.length}
                  percentage={{
                    color: "secondary",
                    amount: "View & Edit Ranks",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>}
          {adminType === 'SuperAdmin' && <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to={"/testlist"}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="list"
                  title="Test List"
                  count={testList?.length}
                  percentage={{
                    color: "primary",
                    amount: "View & Edit Tests",
                  }}
                  />
              </Link>
            </MDBox>
          </Grid>}
          {(adminType === 'SuperAdmin' || adminType === 'Post Admin') && <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to={"/videolist"}>
                <ComplexStatisticsCard
                  color="success"
                  icon="videocamera"
                  title="Video List"
                  count={videoList?.length}
                  percentage={{
                    color: "success",
                    amount: "View & Edit Videos",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>}
          {(adminType === 'SuperAdmin' || adminType === 'Post Admin') && <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to={"/bestvideolist"}>
                <ComplexStatisticsCard
                  color="success"
                  icon="videocamera"
                  title="Best Video List"
                  count={bestVideoList?.length}
                  percentage={{
                    color: "success",
                    amount: "View & Edit Best Videos",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>}
          {(adminType === 'SuperAdmin' || adminType === 'Post Admin') && <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to={"/conceptlist"}>
                <ComplexStatisticsCard
                  color="secondary"
                  icon="book"
                  title="Concept List"
                  count={conceptList?.length}
                  percentage={{
                    color: "secondary",
                    amount: "View & Edit Concepts",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>}
          {adminType === 'SuperAdmin' && <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to={"/createtest"}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="list"
                  title="Create Test"
                  count={'+'}
                  percentage={{
                    color: "warning",
                    amount: "Create A Test",
                  }}
                  />
              </Link>
            </MDBox>
          </Grid>}
          {(adminType === 'SuperAdmin' || adminType === 'Post Admin') && <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to={"/videoupload"}>
                <ComplexStatisticsCard
                  color="error"
                  icon="upload"
                  title="Video Upload"
                  count='+'
                  percentage={{
                    color: "error",
                    amount: "Upload Videos",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>}
          {(adminType === 'SuperAdmin' || adminType === 'Post Admin') && <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to={"/bestvideoupload"}>
                <ComplexStatisticsCard
                  color="error"
                  icon="upload"
                  title="Best Video Upload"
                  count='+'
                  percentage={{
                    color: "error",
                    amount: "Upload Best Videos",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>}
          {(adminType === 'SuperAdmin' || adminType === 'Post Admin') && <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to={"/conceptupload"}>
                <ComplexStatisticsCard
                  color="info"
                  icon="upload"
                  title="Concept Upload"
                  count="+"
                  percentage={{
                    color: "info",
                    amount: "Upload Concepts",
                  }}
                  />
              </Link>
            </MDBox>
          </Grid>}
          {(adminType === 'SuperAdmin' || adminType === 'Post Admin') && <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to={"/banner"}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="image"
                  title="Banner Page"
                  count={bannerList?.length}
                  percentage={{
                    color: "primary",
                    amount: "Create & View Baners",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>}
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Controls;
