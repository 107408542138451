import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data

// Dashboard components
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import BackupIcon from "@mui/icons-material/Backup";
import AddIcon from "@mui/icons-material/Add";
import { useDropzone } from "react-dropzone";
import { SpinnerInfinity } from "spinners-react";
import globalURL from "../../config";

import ListContext from "layouts/apiContext/listContext";
import { useContext } from "react";
import { Box, Divider, InputLabel, MenuItem, Modal, Select } from "@mui/material";
import MDButton from "components/MDButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: '2rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function BannerPage() {
  
  const { 
    bannerList,
    fetchBannerList

  } = useContext(ListContext);

  const [loading, setLoading] = useState(false);
  const [ baseImage, setBaseImage ] = useState('');
  const [ activity, setActivity ] = useState('');
  const [ bannerId, setBannerId ] = useState('');
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".png"],
    },
  });

  const [openupdate, setOpenupdate] = useState(false);
  const handleCloseupdate = () => setOpenupdate(false);

  // const [openNone, setOpenNone] = useState(false);
  // const handleCloseNone = () => setOpenNone(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const uploadImage = async (image) => {
    
    const file = image[0]
    const base64 = await convertBase64(file)
    setBaseImage(base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''));
    return (base64.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''))
    
  }

  const convertBase64 = (file) => {

    return new Promise((resolve, reject) => {

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = (() => {
        resolve(fileReader.result);
      });

      fileReader.onerror = ((error) => {
        reject(error);
      });

    });

  }

  const handleSubmit = async () => {
    setLoading(true);
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    uploadImage(acceptedFiles).then((bsImg)=> {
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
      },
      body: JSON.stringify({
        banner: bsImg
      })
      }
      fetch (
      globalURL + "banner/create_banner", 
      requestOptions
    )
    .then((res) => res.json())
    .then((res) => {
      setLoading(false);
      fetchBannerList();
    })
  })})
  };

  const Updateapi = async () => {
    setLoading(true);
    handleCloseupdate();
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
      },
      body: JSON.stringify({
        banner: '',
        banner_id: bannerId,
        active: activity
      })
    };
    fetch(
      globalURL + "banner/update_banner",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {

          handleCloseupdate();
          // setMessage(res.message);
          setLoading(false);
          // setOpenMessage(true);
          fetchBannerList();
          setOpenupdate(false);
          // openSuccessSB();
        } else {
          handleCloseupdate();
          // setMessage(res.message);
          // setOpenMessage(true);
          setLoading(false);
          fetchBannerList();
          // openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    })
  };

  const deleteBanner = async () => {
    setLoading(true);
    handleCloseupdate();
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
      },
      body: JSON.stringify({
        banner: '',
        banner_id: bannerId,
        active: 2
      })
    };
    fetch(
      globalURL + "banner/update_banner",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {

          handleCloseDelete();
          // setMessage(res.message);
          setLoading(false);
          // setOpenMessage(true);
          fetchBannerList();
          // setOpenupdate(false);
          // openSuccessSB();
        } else {
          handleCloseDelete();
          // setMessage(res.message);
          // setOpenMessage(true);
          setLoading(false);
          fetchBannerList();
          // openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    })
  };

  useEffect(() => {
    setBaseImage(acceptedFiles[0]);
  }, [acceptedFiles])
  
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Modal
        open={openupdate}
        onClose={handleCloseupdate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
          >
            Update Your Banner
          </Typography>
          <Grid container justifyContent="space-evenly">
            <Grid item xs={3.5} mt={1}>
              <InputLabel id="activityLabel">Activity Status: </InputLabel>
              <Select
                fullWidth
                style={{padding: 11.5}}
                labelId="activityLabel"
                id="activitySelect"
                value={activity}
                label="Activity Status: "
                onChange={(e) => setActivity(e.target.value)}
              >
                <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} key={Math.random().toString()} >Active</MenuItem>
                <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} key={Math.random().toString()} >InActive</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid 
            container 
            justifyContent={'space-evenly'}
            style={{ marginTop: "20px" }}
          >
            <Grid item xs={4}>
              <MDButton
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={Updateapi}
              >
                Update
              </MDButton>
            </Grid>
            <Grid item xs={4}>
              <MDButton
                fullWidth
                variant="contained"
                color="dark"
                size="small"
                onClick={handleCloseupdate}
              >
                CLOSE
              </MDButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{textAlign: 'center'}}>
          <Typography
            id="modal-modal-title"
            variant="h3"
            component="h2"
            style={{marginBottom: '2rem'}}
          >
            Are You Sure You Want To Delete This Banner?
          </Typography>
          <Grid 
            container 
            justifyContent={'space-evenly'}
            style={{ marginTop: "2rem" }}
          >
            <Grid item xs={4}>
              <MDButton
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={deleteBanner}
              >
                Delete
              </MDButton>
            </Grid>
            <Grid item xs={4}>
              <MDButton
                fullWidth
                variant="contained"
                color="dark"
                size="small"
                onClick={handleCloseDelete}
              >
                CLOSE
              </MDButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {loading ? 
        <Grid container justifyContent={'center'}height={'80vh'}>
          <SpinnerInfinity 
            size={190} 
            thickness={180} 
            speed={100} 
            color="rgba(59, 172, 57, 1)" 
            secondaryColor="rgba(57, 145, 172, 0.44)" 
          />
        </Grid>
        :  
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3} {...getRootProps()}>
                <Grid item xs={12}>
                  <Card>
                    <Grid
                      container
                      justifyContent="space-around"
                      spacing="10"
                      py={10}
                      style={{ textAlign: "center" }}
                    >
                      <Grid item xs={12}>
                        <input {...getInputProps()} accept=".png"/>
                        {!acceptedFiles.length && (
                          <BackupIcon fontSize="large"></BackupIcon>
                        )}
                      </Grid>
                      {!acceptedFiles.length && (
                        <Grid item xs={12}>
                          <Typography variant="h6" component="h2">
                            Click or Drag and drop banner here
                          </Typography>
                        </Grid>
                      )}
                      {!!acceptedFiles.length && (
                        <Grid item >
                          {acceptedFiles.map((file) => (
                            <img
                              src={URL.createObjectURL(file)}
                              alt="upload"
                              width="200"
                            />
                          ))}
                        </Grid>
                      )}
                    </Grid>

                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing="10"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "green",
                            padding: "0 70px",
                            borderRadius: 0,
                          }}
                          size="large"
                        >
                          <AddIcon fontSize="large" color="white" />
                        </Button>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" component="h2">
                          Click to upload video here
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3} justifyContent={"center"}>
                <Grid item >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "green",
                      padding: "0 70px",
                      borderRadius: 20,
                      margin: "10px",
                      color: "white",
                    }}
                    size="large"
                    startIcon={<BackupIcon />}
                    onClick={handleSubmit}
                    disabled={
                      !baseImage? true : false
                    }
                  >
                    <Typography variant="span">upload</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent={'space-evenly'}>
              {bannerList?.map((bnr) => {
                return (
                  <Card style={{background: '#bee5ff', width: '25rem', alignItems: 'center', marginBottom: '2rem', paddingBottom: '0.75rem'}} key={Math.random().toString()} >
                      <Grid item lg={11} md={11} xs={11} key={Math.random().toString()}  >
                        <Grid container justifyContent={'center'} key={Math.random().toString()}  >
                          <Grid item lg={12} align={'center'}>
                            <Box
                              pt={1.5}
                              component="img"
                              sx={{
                                height: '13rem',
                                width: '100%',
                                borderRadius: '10px 10px'
                              }}
                              alt="alt text"
                              src={bnr.banner}
                            />
                            <Divider />
                          </Grid>
                          <Grid item xs={12} >
                            <Box >
                              <Grid container justifyContent={'space-between'}>
                                <div className="flex w-[100%] justify-between">
                                  <div>
                                    STATUS: 
                                  </div>
                                  <div>
                                    {bnr.active === 0 ? <p className="text-[red]">InActive</p> : <p className="text-[green]">Active</p>}
                                  </div>
                                </div>
                                <Grid item xs={6}>                                  
                                  <Button 
                                    fullWidth
                                    variant='contained'
                                    style={{color: '#ffffff', padding: '1rem'}}
                                    onClick={() => {
                                      setOpenupdate(true);
                                      setBannerId(bnr.banner_id);
                                      setActivity(bnr.active);
                                    }}
                                  >
                                    Edit</Button>
                                </Grid>
                                <Grid item xs={6} align={'right'}>
                                  <Button 
                                    variant='contained'
                                    style={{color: '#ffffff', padding: '1rem', width: '90%'}} //, marginTop: '6.2rem'
                                    onClick={() => {
                                      setBannerId(bnr.banner_id);
                                      setOpenDelete(true);
                                    }}
                                  >
                                    Delete</Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                    </Grid>
                  </Card>
                );
              })}
            </Grid>
          </Grid>
      </MDBox>}
    </DashboardLayout>
  );
}

export default BannerPage;
