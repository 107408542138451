/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { useContext, useState } from "react";
import { SubjectData } from "./data/SubjectData";
import tw from "twin.macro";
import MDButton from "components/MDButton";
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import { SketchPicker } from 'react-color'
import { SpinnerInfinity } from "spinners-react";

import globalURL from "../../config";
import '../../index.css'
import ListContext from "layouts/apiContext/listContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: '2rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AppContainer = tw.div`
  w-full
  max-w-full
  flex
  flex-col
  items-stretch
  justify-center
  pt-6
  pb-10
  pl-10
  pr-10
`;

function Tables() {
  
  const {
    boardList,
    filteredClassList,
    fetchClassByBoard,
    subjectList,
    fetchSubjectList,
  } = useContext(ListContext);
  

  const [ logo, setLogo ] = useState('');
  const [ oldLogo, setOldLogo ] = useState('');
  const [ background, setBackground ] = useState('');
  const [ oldBackground, setOldBackground ] = useState('');
  const [ className, setClassName ] = useState('');
  const [ boardName, setBoardName ] = useState('');
  const [ activity, setActivity ] = useState("");
  const [subject, setsubject] = useState("");
  const [ color, setColor ] = useState({background: '#000'});
  const [ showColorPanel, setShowColorPanel ] = useState(false);
  const [ updatedClassId, setUpdatedClassId ] = useState('');
  const [ updatedSubjectName, setUpdatedSubjectName ] = useState('');
  const [ updatedSubjectId, setUpdatedSubjectId ] = useState('');
  const [index, setIndex] = useState(null);
  const [updateIndex, setUpdateIndex] = useState(null);
  const [ loading, setLoading ] = useState(false);
  
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setsubject('');
    setClassName('');
    setLogo('');
    setBackground('');
  };
  const [errorSB, setErrorSB] = useState(false);

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [openupdate, setOpenupdate] = useState(false);
  const handleCloseupdate = () => {
    setOpenupdate(false);
    setUpdatedSubjectName();
    setOldLogo('');
    setOldBackground('');
    setLogo('');
    setBackground('');
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => {
    setOpenDelete(false)
    setsubject('');
  };

  const [openNone, setOpenNone] = useState(false);
  const handleCloseNone = () => setOpenNone(false);

  const [ message, setMessage ] = useState('');
  const [ openMessage, setOpenMessage ] = useState(false);
  const handleCloseMessage = () => setOpenMessage(false);

  const colorChangeHandler = (color) => {
    setColor({background : color.hex})
  }

  const createSub = async () => {
    setLoading(true);
    handleClose();

    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1c2VyX2lkIjoiMSIsImVtYWlsIjoic2hpdmtyOTgxODhAZ21haWwuY29tIiwibG9naW5UaW1lIjoxNjUxNTcyODIwOTYwLCJnZW5lcmF0ZWRfYXQiOjE2NTE1NzI4MjA5NjAsImlhdCI6MTY1MTU3MjgyMCwiZXhwIjoxNjgzMTMwNDIwLCJpc3MiOiJSZWNydWl0In0.T4fHdvweSB4iFL7oXrKXiIh9u6IHvXQtVUfX-mafNZ3Gl3hJyC7AyXSCPgin_r_FRKz2nHnFe5a6epsbM_Idqw'
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
      },
      body: JSON.stringify({
        class_id: className,
        subject: subject,
        colour: color.background,
        created_by: "1",
        index:Number(index),
        board_id: boardName,
        logo : logo.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''),
        backgroud: background.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''),
      }),
    };
    fetch(
      globalURL + "subject/create_subject",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          handleClose();
          fetchSubjectList();
          setMessage(res.message);
          setOpenMessage(true);
          setLoading(false);
          setClassName("");
          setLogo('');
          setBoardName('');
          setIndex(null);
          setBackground('');
          setsubject("");
          openSuccessSB();
        } else {
          handleClose();
          setLoading(false);
          setLogo('');
          setBackground('');
          fetchSubjectList();
          setBoardName('');
          setIndex(null);
          setMessage(res.message);
          setOpenMessage(true);
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    })
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Subject Updated Successfully"
      content="Your Subject is now updated successfully"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content="Getting Error while updating Subject"
      dateTime="Just Now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const uploadImage = async (image) => {
    
    const file = image.target.files[0]
    const base64 = await convertBase64(file)
    setLogo(base64);

  }

  const uploadBackground = async (image) => {
    
    const file = image.target.files[0]
    const base64 = await convertBase64(file)
    setBackground(base64);

  }

  const convertBase64 = (file) => {

    return new Promise((resolve, reject) => {

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = (() => {
        resolve(fileReader.result);
      });

      fileReader.onerror = ((error) => {
        reject(error);
      });

    });

  }

  const Updateapi = async () => {
    setLoading(true);
    handleCloseupdate();
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
      },
      body: JSON.stringify({
        class_id: updatedClassId,
        subject_id: updatedSubjectId,
        subject: updatedSubjectName,
        active: activity,
        colour: color.background,
        index:Number(updateIndex),
        board_id: boardName,
        logo: logo.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''),
        backgroud:  background.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''),
      }),
    };
    fetch(
      globalURL + "subject/update_subject",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {

          // tryingfunc();
          handleCloseupdate();
          setMessage(res.message);
          setOpenMessage(true);
          setBoardName('');
          fetchSubjectList();
          setLogo('');
          setBackground('');
          setUpdatedSubjectId("");
          setUpdateIndex(null);
          setLoading(false);
          setUpdatedSubjectName("");
          setUpdatedClassId("");
          openSuccessSB();
        } else {
          // tryingfunc();
          handleCloseupdate();
          fetchSubjectList();
          setMessage(res.message);
          setLogo('');
          setBoardName('');
          setUpdateIndex(null);
          setBackground('');
          setLoading(false);
          setOpenMessage(true);
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    })
  };

  const deleteSubject = async () => {
    setLoading(true);
    handleCloseDelete();
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subject_id: updatedSubjectId,
      }),
    };
    await fetch(
      globalURL + "subject/delete_subject",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {

          // tryingfunc();
          handleCloseDelete();
          fetchSubjectList();
          setMessage(res.message);
          setOpenMessage(true);
          setUpdatedSubjectId("");
          setUpdateIndex(null);
          setLoading(false);
          setUpdatedSubjectName("");
          openSuccessSB();
        } else {
          // tryingfunc();
          setUpdatedSubjectId("");
          setUpdatedSubjectName("");
          setUpdateIndex(null);
          setMessage(res.message);
          setLoading(false);
          setOpenMessage(true);
          fetchSubjectList();
          handleCloseDelete();
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="error"
                borderRadius="lg"
                coloredShadow="error"
              >
                <MDButton
                  variant="contained"
                  size="small"
                  onClick={handleOpen}
                  className={'text-[#000000] bg-white'}
                >
                  Create new subject
                </MDButton>

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h3"
                      component="h2"
                    >
                      Create New Subject
                    </Typography>
                    <Grid container justifyContent={'space-between'}>
                      <Grid item xs={6} justifyContent={'center'} style={{textAlign: 'center'}}>
                        <Grid item mb={2}>
                          <label for='logo'>Logo</label>
                          <input
                            id='logo'
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={(image) => uploadImage(image)}
                          />
                        </Grid>
                        <Grid item xs={12} justifyContent={'center'}>
                          {!logo? null : <>
                          <img 
                            id='logo'
                            src={logo} 
                            style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                            alt=''
                          />
                          </>
                          }
                        </Grid>
                      </Grid>
                      <Grid item xs={6} justifyContent={'center'} style={{textAlign: 'center'}}>
                        <Grid item mb={2}>
                          <label for='background'>Background</label>
                          <input
                            id='background'
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={(image) => uploadBackground(image)}
                          />
                        </Grid>
                        <Grid item xs={12} justifyContent={'center'}>
                          {!background? null : <>
                          <img 
                            id='newBackground'
                            src={background} 
                            style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                            alt=''
                          />
                          </>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={'center '}>
                      <Grid item xs={10} align={'center'}>
                        <Button 
                          size='small'
                          variant="contained"
                          style={{color: '#ffffff'}}
                          onClick={() => setShowColorPanel(!showColorPanel)}
                        >
                          {showColorPanel? 'Hide Color': 'Select Color' }
                        </Button>
                      </Grid>

                      <Grid item xs={5} style={{align: 'center'}}>
                        { showColorPanel? 
                          <SketchPicker
                            color={color.background}
                            onChangeComplete={(color) => {
                              colorChangeHandler(color);
                            }}/>
                          : null
                        }
                      </Grid>
                    </Grid>
                    <FormControl style={{ margin: "1rem 0" }} fullWidth>
                            <TextField
                        id="outlined-basic"
                        label="Index"
                        variant="outlined"
                        value={index}
                        style={{ margin: "1rem 0" }}
                        onChange={(e) => setIndex(e.target.value)}
                      />
                      </FormControl>
                      <FormControl style={{ margin: "1rem 0" }} fullWidth>
                        <InputLabel id="demo-simple-select-label">BOARD</InputLabel>
                        <Select
                          style={{padding: 11}}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={boardName}
                          label="BOARD"
                          onChange={(e) => {
                            setBoardName(e.target.value);
                            fetchClassByBoard(e.target.value)
                          }}
                        >
                          {boardList.map((brd) => {
                            if (brd.active === 1) return <MenuItem value={brd.board_id} key={brd.board_id}>{brd.board_name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                           
                      <FormControl fullWidth>
                        <FormControl fullWidth style={{margin: '1rem 0'}} >
                          <InputLabel id="classSelectLabel">CLASS</InputLabel>
                          <Select
                            style={{padding: 10}}
                            labelId="classSelectLabel"
                            id="classSelect"
                            label="CLASS"
                            value={className}
                            onChange={(e) => setClassName(e.target.value)}
                          >
                            {filteredClassList.map((cls) => {
                              if (cls.active === 1) return <MenuItem value={cls.class_id} key={cls.class_id}>{cls.class_name}</MenuItem>
                            })}
                          </Select>
                      </FormControl>
                      <TextField
                        id="outlined-basic"
                        label="SUBJECT NAME"
                        variant="outlined"
                        value={subject}
                        style={{ margin: "1rem 0" }}
                        onChange={(e) => setsubject(e.target.value)}
                      />
                        {/* <TextField
                          id="outlined-basic"
                          label="CREATED BY"
                          variant="outlined"
                          value={created_by}
                          onChange={(e) => setcreated_by(e.target.value)}
                          style={{ marginTop: "20px", marginBottom: "20px" }}
                        /> */}
                      <Grid container justifyContent={'space-evenly'} mt={1} >
                        <Grid item xs={4}>
                          <MDButton
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={createSub}
                            disabled={
                              !subject ? true : false
                            }
                          >
                            SEND
                          </MDButton>
                        </Grid>
                        <Grid item xs={4}>
                          <MDButton
                            fullWidth
                            variant="contained"
                            color="dark"
                            size="medium"
                            onClick={handleClose}
                          >
                            CLOSE
                          </MDButton>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Box>
                </Modal>

                <Modal
                  open={openupdate}
                  onClose={handleCloseupdate}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h2"
                    >
                      Update Your Subject
                    </Typography>
                    <Grid container justifyContent={'space-between'}>
                      <Grid item xs={6} justifyContent={'center'} style={{textAlign: 'center'}}>
                        <Grid item xs={12} justifyContent={'center'}>
                          {!oldLogo? null : <>
                          <label for='oldLogo'>Old Logo</label>
                            <img 
                              id='oldLogo'
                              src={oldLogo} 
                              style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                              alt=''
                            />
                          </>}
                        </Grid>
                      </Grid>
                      <Grid item xs={6} justifyContent={'center'} style={{textAlign: 'center'}}>
                        <Grid item xs={12} justifyContent={'center'}>
                          {!oldBackground? null : <>
                          <label for='oldBackground'>Old Background</label>
                          <img 
                            id='oldBackground'
                            src={oldBackground} 
                            style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                            alt=''
                          />
                          </>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={'space-between'}>
                      <Grid item xs={6} justifyContent={'center'} style={{textAlign: 'center'}}>
                        <Grid item mb={2}>
                          <label for='logo'>New Logo</label>
                          <input
                            id='logo'
                            type="file"
                            accept="image/*"
                            onChange={(image) => uploadImage(image)}
                          />
                        </Grid>
                        <Grid item xs={12} justifyContent={'center'}>
                          {!logo? null : <>
                            <img 
                              id='newLogo'
                              src={logo} 
                              style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                              alt=''
                            />
                          </>}
                        </Grid>
                      </Grid> 
                      <Grid item xs={6} justifyContent={'center'} style={{textAlign: 'center'}}>
                        <Grid item mb={2}>
                          <label for='background'>New Background</label>
                          <input
                            id='background'
                            type="file"
                            accept="image/*"
                            onChange={(image) => uploadBackground(image)}
                          />
                        </Grid>
                        <Grid item xs={12} justifyContent={'center'}>
                          {!background? null : <>
                          <img 
                            id='newBackground'
                            src={background} 
                            style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                            alt=''
                          />
                          </>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={'center '}>
                      <Grid item xs={10} align={'center'}>
                        <Button 
                          size='small'
                          variant="contained"
                          style={{color: '#ffffff'}}
                          onClick={() => setShowColorPanel(!showColorPanel)}
                        >
                          {showColorPanel? 'Hide Color': 'Select Color' }
                        </Button>
                      </Grid>
                      <Grid item xs={5} style={{align: 'center'}}>
                        { showColorPanel? 
                          <SketchPicker
                            color={color.background}
                            onChangeComplete={(color) => {
                              colorChangeHandler(color);
                            }}/>
                          : null
                        }
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={'space-around'}>
                      <Grid item xs={5.5}>
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            label="Index"
                            variant="outlined"
                            value={updateIndex}
                            onChange={(e) => setUpdateIndex(e.target.value)}
                            style={{ marginTop: "20px" }}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            label="SUBJECT"
                            variant="outlined"
                            value={updatedSubjectName}
                            onChange={(e) => setUpdatedSubjectName(e.target.value)}
                            style={{ marginTop: "20px" }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={5.5} mt={1}>
                        <InputLabel id="activityLabel">Activity Status: </InputLabel>
                        <Select
                          fullWidth
                          style={{padding: 11}}
                          labelId="activityLabel"
                          id="activitySelect"
                          value={activity}
                          label="Activity Status: "
                          onChange={(e) => setActivity(e.target.value)}
                        >
                          <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
                          <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={5.5} mt={1}>
                        <InputLabel id="demo-simple-select-label">BOARD</InputLabel>
                        <Select
                          fullWidth
                          style={{padding: 11}}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={boardName}
                          label="BOARD"
                          onChange={(e) => {
                            setBoardName(e.target.value)
                          }}
                        >
                          {boardList.map((brd) => {
                            if (brd.active === 1) return <MenuItem value={brd.board_id} key={brd.board_id}>{brd.board_name}</MenuItem>
                          })}
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={'space-evenly'} mt={4}>
                      <Grid item xs={4}>
                        <MDButton
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={Updateapi}
                            disabled={
                              !updatedSubjectName
                              || !boardName ? true : false
                            }
                          >
                            Update
                          </MDButton>
                      </Grid>
                      <Grid item xs={4}>
                          <MDButton
                            fullWidth
                            variant="contained"
                            color="dark"
                            size="small"
                            onClick={handleCloseupdate}
                          >
                            CLOSE
                          </MDButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                <Modal
                  open={openDelete}
                  onClose={handleCloseDelete}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} style={{textAlign: 'center'}}>
                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h2"
                      style={{marginBottom: '2rem'}}
                    >
                      Are You Sure You Want To Delete This Subject?
                    </Typography>
                    {updatedSubjectName}
                    <Grid 
                      container 
                      justifyContent={'space-evenly'}
                      style={{ marginTop: "2rem" }}
                    >
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={deleteSubject}
                        >
                          Delete
                        </MDButton>
                      </Grid>
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="dark"
                          size="small"
                          onClick={handleCloseDelete}
                        >
                          CLOSE
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                <Modal
                  open={openNone}
                  onClose={handleCloseNone}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} style={{textAlign: 'center'}}>
                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h2"
                      style={{marginBottom: '2rem'}}
                    >
                      No Data Available!!
                    </Typography>
                    <Grid 
                      container 
                      justifyContent={'center'}
                      style={{ marginTop: "2rem" }}
                    >
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="dark"
                          size="small"
                          onClick={handleCloseNone}
                        >
                          CLOSE
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                <Modal
                  open={openMessage}
                  onClose={handleCloseMessage}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} style={{textAlign: 'center'}}>
                    <Grid item xs={12}>
                      <Typography
                        id="modal-modal-title"
                        variant="h4"
                        component="h2"
                        style={{marginBottom: '2rem',}}
                      >
                        {message}
                      </Typography>
                    </Grid>
                    <Grid 
                      container 
                      justifyContent={'center'}
                      style={{ marginTop: "2rem" }}
                    >
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="dark"
                          size="small"
                          onClick={handleCloseMessage}
                        >
                          CLOSE
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>
                
                {renderSuccessSB}
                {renderErrorSB}
              </MDBox>
              <MDBox pt={3} style={{height: '44rem', overflow: 'auto'}}>
                {loading ? 
                  <Grid container justifyContent={'center'}height={'80vh'}>
                    <SpinnerInfinity 
                      size={190} 
                      thickness={180} 
                      speed={100} 
                      color="rgba(59, 172, 57, 1)" 
                      secondaryColor="rgba(57, 145, 172, 0.44)" 
                    />
                  </Grid> :
                  <AppContainer>
                    <SubjectData 
                      setUpdatedClassId={setUpdatedClassId}
                      setUpdatedSubjectName={setUpdatedSubjectName}
                      setUpdatedSubjectId={setUpdatedSubjectId}
                      setActivity={setActivity}
                      setOldLogo={setOldLogo}
                      setOldBackground={setOldBackground}
                      setBoardName={setBoardName}
                      setIndex={setIndex}
                      setUpdateIndex={setUpdateIndex}
                      setOpenupdate={setOpenupdate}
                      setOpenDelete={setOpenDelete}
                      setOpenNone={setOpenNone}
                      subjectList={subjectList}
                    />
                  </AppContainer>
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;