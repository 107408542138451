import { createContext, useEffect, useState } from 'react';
import globalURL from "../../config";

const ListContext = createContext();

export function ListProvider({ children }) {

  const [adminList, setAdminList] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [filteredClassList, setFilteredClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [filteredSubjectList, setFilteredSubjectList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [filteredTopicList, setFilteredTopicList] = useState([]);
  const [mediumList, setMediumList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [bestVideoList, setBestVideoList] = useState([]);
  const [conceptList, setConceptList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [testList, setTestList] = useState([]);
  const [rankList, setRankList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [userSubscriptionList, setUserSubscriptionList] = useState([]);

  useEffect(() => {

    fetchMediumList();
    fetchClassList();
    fetchSubjectList();
    fetchTopicList();
    fetchBoardList();
    fetchAdminList();
    fetchUserList();
    fetchVideoList();
    fetchBestVideoList();
    fetchConceptList();
    fetchBannerList();
    fetchTestList();
    fetchSubscriptionList();
    fetchUserSubscriptionList();

  }, [])

  const fetchAdminList = async () => {

    return (
      await fetch(
        globalURL + "admin/get_admin"
      )
        .then((res) => res.json())
        .then((res) => setAdminList(res.data.filter((dt) => dt.active !== 2)))
        .catch((e) => console.log('get_admin is down'))
    )
  };

  const fetchSubscriptionList = async () => {

    return (
      await fetch(
        globalURL + "adminSubcription/get_subcription"
      )
        .then((res) => res.json())
        .then((res) => setSubscriptionList(res.data.filter((dt) => dt.active !== 2)))
        .catch((e) => console.log('get_subscription is down'))
    )
  };

  const fetchUserSubscriptionList = async () => {

    return (
      await fetch(
        globalURL + "adminSubcription/get_user_subcription"
      )
        .then((res) => res.json())
        .then((res) => setUserSubscriptionList(res.data.filter((dt) => dt.active !== 2)))
        .catch((e) => console.log('get_user_subcription is down'))
    )
  };

  const fetchClassList = async () => {

    return (
      await fetch(
        globalURL + "class_master/get_classes"
      )
        .then((res) => res.json())
        .then((res) => {
          setClassList(res.data.filter((dt) => dt.active !== 2));
          setFilteredClassList(res.data.filter((dt) => dt.active !== 2))
        })
        .catch((e) => console.log('get_classes is down'))
    )
  };

  const fetchClassByBoard = async (boardId) => {

    return (
      await fetch(
        globalURL + `class_master/get_classes?board_id=${boardId}`
      )
        .then((res) => res.json())
        .then((res) => setFilteredClassList(res.data.filter((dt) => dt.active !== 2)))
        .catch((e) => console.log('get_classes is down'))
    )
  };

  const fetchRankList = async () => {

    return (
      await fetch(
        globalURL + `admin/get_reward`
      )
        .then((res) => res.json())
        .then((res) => setRankList(res.data.filter((dt) => dt.active !== 2)))
        .catch((e) => console.log('get_reward is down'))
    )
  };

  const fetchSubjectList = async () => {

    return (
      await fetch(
        globalURL + "subject/get_allsubject"
      )
        .then((res) => res.json())
        .then((res) => {
          setSubjectList(res.data.filter((dt) => dt.active !== 2));
          setFilteredSubjectList(res.data.filter((dt) => dt.active !== 2));
        })
        .catch((e) => console.log('get_allsubject is down'))
    )
  };

  const fetchSubjectByClass = async (classId) => {

    if (classId !== 'ALL') {
      return (
        await fetch(
          globalURL + "subject/get_subject_by_class", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            class_id: classId
          }),
        }
        )
          .then((res) => res.json())
          .then((res) => setFilteredSubjectList(res.data.data.filter((dt) => dt.active !== 2)))
      )
    }

  };

  const fetchMediumList = async () => {

    return (
      await fetch(
        globalURL + "medium_master/get_mediums"
      )
        .then((res) => res.json())
        .then((res) => setMediumList(res.data.filter((dt) => dt.active !== 2)))
        .catch((e) => console.log('get_mediums is down'))
    )
  };

  const fetchTopicList = async () => {

    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
      const requestOptions = {
        method: "GET",
        headers: {
          'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
        },
      }
      fetch(
        globalURL + "topic/get_topiclist",
        requestOptions
      )
        .then((res) => res.json())
        .then((res) => {
          setTopicList(res.data.filter((dt) => dt?.active !== 2))
          setFilteredTopicList(res.data.filter((dt) => dt?.active !== 2))
        })
        .catch((e) => console.log('get_topiclist api is down'));
    })
  };

  const fetchTopicBySubject = async (subjectId) => {
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    if (subjectId !== 'ALL') {
      getUserToken().then((userToken) => {
        const requestOptions = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
          },
          body: JSON.stringify({
            subject_id: subjectId
          })
        }
        fetch(globalURL + "topic/get_topic_by_subject",
          requestOptions
        )
          .then((res) => res.json())
          .then((res) => setFilteredTopicList(res.data.data.filter((dt) => dt.active !== 2)))
      })
    }

  };

  const fetchBoardList = async () => {

    return (
      await fetch(
        globalURL + "board_master/get_boards"
      )
        .then((res) => res.json())
        .then((res) => setBoardList(res.data.filter((dt) => dt.active !== 2)))
        .catch((e) => console.log('get_boards is down'))
    )
  };

  const fetchUserList = async () => {

    return (
      await fetch(
        globalURL + "admin/get_users"
      )
        .then((res) => res.json())
        .then((res) => setUserList(res.data.filter((dt) => dt.active !== 2)))
        .catch((e) => console.log('get_users is down'))
    )
  }

  const fetchVideoList = async () => {

    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
      const requestOptions = {
        headers: {
          'Authorization': "Bearer " + userToken?.replace('"', "").replace('"', ""),
        },
      }
      fetch(
        globalURL + "adminVideo/get_video",
        requestOptions
      )
        .then((res) => res.json())
        .then((res) => setVideoList(res.data.filter((dt) => dt.active !== 2)))
        .catch((e) => console.log('get_video is down'));
    })
  };

  const fetchBestVideoList = async () => {

    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
      const requestOptions = {
        headers: {
          'Authorization': "Bearer " + userToken?.replace('"', "").replace('"', ""),
        },
      }
      fetch(
        globalURL + "adminVideo/get_best_video",
        requestOptions
      )
        .then((res) => res.json())
        .then((res) => setBestVideoList(res.data.filter((dt) => dt.active !== 2)))
        .catch((e) => console.log('get_best_video is down'));
    })
  };

  const fetchConceptList = async () => {

    return (
      await fetch(
        globalURL + "adminVideo/get_concept"
      )
        .then((res) => res.json())
        .then((res) => setConceptList(res.data.filter((dt) => dt.active !== 2)))
        .catch((e) => console.log('get_concept is down'))
    )
  };

  const fetchBannerList = async () => {

    return (
      await fetch(
        globalURL + "banner/show_banner"
      )
        .then((res) => res.json())
        .then((res) => setBannerList(res.data.filter((dt) => dt.active !== 2)))
        .catch((e) => console.log('ahow_banner is down'))
    )
  };

  const fetchTestList = async () => {

    return (
      await fetch(
        globalURL + "test/showTest"
      )
        .then((res) => res.json())
        .then((res) => setTestList(res.data.filter((dt) => dt.active !== 2)))
        .catch((e) => console.log('showTest is down'))
    )
  };


  return (
    <ListContext.Provider value={{
      adminList, fetchAdminList,
      boardList, fetchBoardList,
      classList, fetchClassList,
      filteredClassList, fetchClassByBoard,
      subjectList, fetchSubjectList,
      filteredSubjectList, fetchSubjectByClass,
      topicList, fetchTopicList,
      filteredTopicList, fetchTopicBySubject,
      mediumList, fetchMediumList,
      videoList, fetchVideoList,
      bestVideoList, fetchBestVideoList,
      conceptList, fetchConceptList,
      bannerList, fetchBannerList,
      userList, fetchUserList,
      testList, fetchTestList,
      rankList, fetchRankList,
      subscriptionList, fetchSubscriptionList,
      userSubscriptionList, fetchUserSubscriptionList
    }}>
      {children}
    </ListContext.Provider>
  )

}

export default ListContext;