/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useEffect, useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { SpinnerInfinity } from "spinners-react";

import AuthContext from 'AuthContext';
import globalURL from "../../../config";
import axios from "axios";
import { Box, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: '2rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Basic() {

  const {setIsAuth} = useContext(AuthContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [ adminEmail, setAdminEmail ] = useState('');
  const [ adminPassword, setAdminPassword ] = useState('');
  const [ adminList, setAdminList ] = useState([]);

  const [ message, setMessage ] = useState('');
  const [ openMessage, setOpenMessage ] = useState(false);
  const handleCloseMessage = () => setOpenMessage(false);

  const verifyAdmin = async () => {
    setLoading(true);
    return(await fetch (
      globalURL + "admin/adminLogin", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "adminEmail": adminEmail,
          "password": adminPassword 
        })
      }
    )
    .then((res) => res.json())
    .then((res) => {
      if (res.message === "Success") {
        setMessage(res.message);
        setOpenMessage(true);
        localStorage.setItem("userInfo", JSON.stringify(res.data.token));
        localStorage.setItem("userType", JSON.stringify(res.data.admin.user_type));
        localStorage.setItem("adminEmail", JSON.stringify(res.data.admin.adminEmail));
        localStorage.setItem("userName", adminList.filter((admin) => admin.adminId === res.data?.admin.adminId)[0]?.adminName.toUpperCase())
        setIsAuth(true);
        setLoading(false);
        navigate('/controls')
      }
      else {
        setMessage(res.message);
        setLoading(false);
        setOpenMessage(true);
        setOpenMessage(true);
      }
    })
    .catch((e) => console.log(e)))
  }

  const fetchAdminList = async () => {
    const response = await axios
      .get(globalURL + "admin/get_admin")
      .catch((err) => console.log(err));

    if (response) {
      const users = response.data;

      setAdminList(users.data);
    }
  };

  useEffect(() => {
    fetchAdminList();
  }, [])

  return (
    <BasicLayout image={bgImage}>
      <Card><Modal
            open={openMessage}
            onClose={handleCloseMessage}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{textAlign: 'center'}}>
              <Grid xs={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h4"
                  component="h2"
                  style={{marginBottom: '2rem',}}
                >
                  {message}
                </Typography>
              </Grid>
              <Grid 
                container 
                justifyContent={'center'}
                style={{ marginTop: "2rem" }}
              >
                <Grid item xs={4}>
                  <MDButton
                    fullWidth
                    variant="contained"
                    color="dark"
                    size="small"
                    onClick={handleCloseMessage}
                  >
                    CLOSE
                  </MDButton>
                </Grid>
              </Grid>
            </Box>
          </Modal>
    {loading ? 
      <Grid container justifyContent={'center'}height={'0vh'} mt={-5}>
        <SpinnerInfinity 
          size={190} 
          thickness={180} 
          speed={100} 
          color="rgba(59, 172, 57, 1)" 
          secondaryColor="rgba(57, 145, 172, 0.44)" 
        />
      </Grid> :<>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput 
                type="email" 
                label="EMAIL" 
                fullWidth 
                onChange={(e) => setAdminEmail(e.target.value)}
              />
            </MDBox>
            {/* {showotp?  */}
            <MDBox mb={2}>
              <MDInput 
                type="password" 
                label="PASSWORD" 
                fullWidth 
                onChange={(e) => setAdminPassword(e.target.value)}
              />
            </MDBox> 
            <MDBox mt={4} mb={1}>
              <MDButton 
                variant="gradient" 
                color="info" 
                fullWidth
                onClick={() => {
                  verifyAdmin();
                }}
                disabled={
                  !adminEmail || !adminPassword ? true : false
                }
              >
                Sign In
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox></>}
      </Card>
    </BasicLayout>
  );
}

export default Basic;
