import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import tw from "twin.macro";
import { GlobalFilter } from "./GlobalFilter";
import '../../../index.css'
import ListContext from "layouts/apiContext/listContext";
import { useContext } from "react";

const Table = tw.table`
  table-fixed
  text-base
  text-gray-900
`;

const TableHead = tw.thead`
  p-2
  bg-gradient-to-r
  from-[#F44335]
  to-[#f65f53]
`;

const TableRow = tw.tr`
  text-center
  border
  border-blue-500
`;

const TableHeader = tw.th`
  border
  border-blue-500
  p-2
`;

const TableBody = tw.tbody`

`;

const TableData = tw.td`
  border
  border-blue-500
  p-5
`;

const Button = tw.button`
  pl-4
  pr-4
  pt-2
  pb-2
  text-white
  rounded-md
  bg-gradient-to-r
  from-[#F44335]
  to-[#f65f53]
  hover:bg-blue-300
  hover:cursor-pointer
  transition-colors
`;

export function SubjectData(props) {

  const {
    filteredClassList,
    fetchClassByBoard,
    boardList
  } = useContext(ListContext);

  const [ filteredSubject, setFilteredSubject ] = useState([]);
  const [ className, setClassName ] = useState('ALL');
  const [ csvReport, setCsvReport ] = useState({});
  const [ activity, setActivity ] = useState('ALL');
  const [ board, setBoard ] = useState('ALL');

  console.log("===subjectList", props.subjectList);

  const activityChangeHandler = (e) => {
    setActivity(e.target.value)
    if (e.target.value === 'ALL') {
      setFilteredSubject(props.subjectList)
    }
    else {
      setFilteredSubject(props.subjectList.filter((brd) => brd.active === e.target.value))
    }
  }

  const boardChangeHandler = (e) => {
    setBoard(e.target.value)
    if (e.target.value === 'ALL') {
      setFilteredSubject(props.subjectList)
    }
    else {
      setFilteredSubject(props.subjectList.filter((brd) => brd.board_id === e.target.value))
    }
  }
  

  useEffect(() => {
    
    const headers = [
      { label: "Subject Name", key: "subject" },
      { label: "Status", key: "active"},
      { label: "Date Created", key: "date_created"},
    ]

    setCsvReport({
      data: filteredSubject,
      headers: headers,
      filename: 'Subject List.csv'
    })

  }, [filteredSubject])

  useEffect(() => {
    setFilteredSubject(props.subjectList)
  },[props.subjectList])

  const subjectData = useMemo(() => [...filteredSubject], [filteredSubject]);

  const subjectColumns = useMemo(
    () =>
      filteredSubject[0]
        ? Object.keys(filteredSubject[0])
            .filter((key) => key !== 'date_modified' && key !== 'class_id' && key !== 'subject_id' && key !== 'board_id' && key !== 'created_by')
            .map((key) => {
              if (key === "logo")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <img 
                    src={value} 
                    style={{ margin: 'auto', width: 70, height: 70 }} 
                    alt=''
                  />,
                  
                };
              if (key === "adminMaster")
                return {
                  Header: "Created by",
                  accessor: key,
                  Cell: ({ value }) => <p>{value.adminName}</p>
                  
                };
              if (key === "class_master")
                return {
                  Header: "Class",
                  accessor: key,
                  Cell: ({ value }) => <p>{value.class_name}</p>
                  
                };
              if (key === "backgroud")
                return {
                  Header: 'background',
                  accessor: key,
                  Cell: ({ value }) => !value? null : <img 
                    src={value} 
                    style={{ margin: 'auto', width: 70, height: 70 }} 
                    alt=''
                  />,
                  
                };
              if (key === "active")
                return {
                  Header: 'status',
                  accessor: key,
                  Cell: ({ value }) => <h4><b>{value===0? <p style={{color: 'red'}}>InActive</p>: <p style={{color: 'green'}}>Active</p>}</b></h4>,
                  
                };
              if (key === "date_created")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <p>{value.slice(0,-14)}</p>,
                  
                };
              if (key === "colour")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <div style={{ width: 70, height: 70, background: value, margin: 'auto', borderRadius: '50%' }}></div>,  
              };
              if (key === "board_master")
                return {
                  Header: "Board",
                  accessor: key,
                  Cell: ({ value }) => <p>{value.board_name}</p>,
                  
                };

              return { Header: key, accessor: key };
            })
        : [],
    [filteredSubject]
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "ACTIONS",
        Header: "ACTIONS",
        Cell: ({ row }) => (
          <>
            <Button 
              style={{marginRight: '1rem', width: '5rem'}}
              onClick={() => {
                props.setUpdatedClassId(row.original.class_id)
                props.setUpdatedSubjectId(row.original.subject_id)
                props.setUpdatedSubjectName(row.original.subject)
                props.setActivity(row.original.active)
                props.setUpdateIndex(row.original.index);
                props.setOldLogo(row.original.logo);
                props.setBoardName(row.original.board_id)
                props.setOldBackground(row.original.backgroud);
                props.setOpenupdate(true)
            }}>
              Edit
            </Button>
            <Button 
              style={{width: '5rem'}}
              onClick={() => {
                props.setUpdatedSubjectName(row.original.subject)
                props.setUpdatedSubjectId(row.original.subject_id)
                props.setOpenDelete(true)
            }}>
              Delete
            </Button>
            
          </>
        ),
      },
    ]);
  };

  const tableInstance = useTable(
    {
      columns: subjectColumns,
      data: subjectData,
    },
    useGlobalFilter,
    tableHooks,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = tableInstance;

  const isEven = (idx) => idx % 2 === 0;

  const classChangeHandler = (e) => {
    setClassName(e.target.value)
    if (e.target.value === 'ALL') {
      setFilteredSubject(props.subjectList)
    }
    else {
      setFilteredSubject(props.subjectList.filter((sub) => sub.class_id === e.target.value))
    }
  }

  const { setOpenNone } = props

  useEffect(() => {
    setOpenNone(true? filteredSubject.length  === 0 : false)
  }, [filteredSubject, setOpenNone])

  return (
    <>
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={4}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="board-label">Board: </InputLabel>
            <Select
              value={board}
              name="board"
              label="Board: "
              labelId="board-label"
              style={{padding: 11}}
              onChange={(e) => {
                boardChangeHandler(e);
                fetchClassByBoard(e.target.value)
              }}
            >
              <MenuItem value={'ALL'} key={Math.random().toString()} >ALL</MenuItem>
              {boardList?.map((brd) => {

                if( brd.active === 1 ) return  <MenuItem value={brd.board_id} key={Math.random().toString()} >{brd.board_name}</MenuItem>

              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="class-label">Class: </InputLabel>
            <Select
              value={className}
              name="class"
              label="Class: "
              labelId="class-label"
              style={{padding: 11}}
              onChange={classChangeHandler}
            >
              <MenuItem value={'ALL'} key={Math.random().toString()} >ALL</MenuItem>
              {filteredClassList.map((clas) => {

                if (clas.active === 1) return  <MenuItem value={clas.class_id} key={Math.random().toString()} >{clas.class_name}</MenuItem>

              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl 
            fullWidth
          >
            <InputLabel id="activityLabel">Activity Status: </InputLabel>
              <Select
                fullWidth
                style={{padding: 11}}
                labelId="activityLabel"
                id="activitySelect"
                value={activity}
                label="Activity Status: "
                onChange={activityChangeHandler}
              >
                <MenuItem value={'ALL'} style={{color: 'green', fontWeight: 'bold'}} >ALL</MenuItem>
                <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
                <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
              </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'}>
        <Grid item flex style={{background: '#F44335', color: '#ffffff', fontSize: '1.2rem', padding: '0.8rem', textAlign: 'center', borderRadius: '50%', width: '70px', border: '4px solid',}}>
          <FormControl fullWidth>
            {filteredSubject.length < 2 ? null :
              <CSVLink {...csvReport}>&#8595;</CSVLink>
            }
          </FormControl>
        </Grid>
      </Grid>
      <Table {...getTableProps()} style={{maxWidth: '100%'}}>
        <TableHead className={"bg-[#1D72E8] text-white"}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeader
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header").toUpperCase().replace('_',' ')}
                  {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);

            return (
              <TableRow
                {...row.getRowProps()}
                className={!isEven(idx) ? "bg-[#F44335] bg-opacity-30" : ""}
                key={Math.random().toString()}
              >
                {row.cells.map((cell, idx) => (
                  <TableData {...cell.getCellProps()}
                  key={Math.random().toString()}>
                    {cell.render("Cell")}
                  </TableData>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}