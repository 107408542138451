/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { useEffect, useState } from "react";
import { AdminData } from "../tables/data/AdminData";
import tw from "twin.macro";
import { SpinnerInfinity } from "spinners-react";
import MDButton from "components/MDButton";
import { Box, FormControl, FormGroup, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import globalURL from "../../config";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: '2rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AppContainer = tw.div`
  w-full
  max-w-full
  flex
  flex-col
  items-stretch
  justify-center
  pt-6
  pb-10
  pl-10
  pr-10
`;

function Tables() {

  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ adminId, setAdminId ] = useState('');
  const [ userType, setUserType ] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [ userTypeList, setUserTypeList ] = useState([]);
  const [ adminList, setAdminList ] = useState([]);
  const [ activity, setActivity ] = useState("");
  const [ adminPassword, setAdminPassword ] = useState('');
  const [ loading, setLoading ] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [errorSB, setErrorSB] = useState(false);

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [openupdate, setOpenupdate] = useState(false);
  const handleCloseupdate = () => setOpenupdate(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => setOpenDelete(false);

  const [ message, setMessage ] = useState('');
  const [ openMessage, setOpenMessage ] = useState(false);
  const handleCloseMessage = () => setOpenMessage(false);

  const [openMore, setOpenMore] = useState(false);
  const handleCloseMore = () => {
    setOpenMore(false)
  };

  useEffect(() => {
    fetchUserTypeList();
    fetchAdminList();
  }, [])

  const fetchUserTypeList = async () => {

    return(await fetch(
      globalURL + "userType/show_userType"
    )
    .then((res) => res.json())
    .then((res) => setUserTypeList(res.data))
    .catch((e) => console.log('get_classes api is down'))
    )

  }

  const fetchAdminList = async () => {
    const response = await axios
      .get(globalURL + "admin/get_admin")
      .catch((err) => console.log(err));

    if (response) {
      const users = response.data;

      setAdminList(users.data.filter((usr) => usr.active !== 2));
    }
  };

  const handleAPI = async () => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "adminEmail": email,
        "password": adminPassword,
        "adminName": firstName+' '+lastName,
        "userType": userType,
        "emp_id": employeeId,
        // "adminId": adminId,
      }),
    };
    await fetch(
      globalURL + "admin/admin_create",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          setMessage(res.message);
          setOpenMessage(true);
          fetchAdminList();
          handleClose();
          setLoading(false);
          setFirstName("");
          setLastName('');
          setEmail('');
          setUserType('');
          setAdminPassword('')
          openSuccessSB();
        } else {
          // fetchUsersList();
          handleClose();
          setMessage(res.message);
          setLoading(false);
          setOpenMessage(true);
          fetchAdminList();
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateAPI = async () => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "adminEmail": email,
        // "password": adminPassword,
        "adminName": firstName,
        "userType": userType,
        "emp_id": employeeId,
        "adminId": adminId,
        active: activity
      }),
    };
    await fetch(
      globalURL + "admin/update_admin",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          fetchAdminList();
          handleCloseupdate();
          setMessage(res.message);
          setLoading(false);
          setOpenMessage(true);
          setAdminId('');
          setFirstName("");
          setLastName('');
          setEmail('');
          setAdminPassword();
          openSuccessSB();
        } else {
          setMessage(res.message);
          setOpenMessage(true);
          setLoading(false);
          // fetchUsersList();
          fetchAdminList();
          handleCloseupdate();
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteUser = async () => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "adminEmail": email,
        // "password": adminPassword,
        "adminName": firstName,
        "userType": userType,
        "emp_id": employeeId,
        "adminId": adminId,
        active: 2
      }),
    };
    await fetch(
      globalURL + "admin/update_admin",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          fetchAdminList();
          handleCloseDelete();
          setMessage(res.message);
          setLoading(false);
          setOpenMessage(true);
          setAdminId('');
          setFirstName("");
          setLastName('');
          setEmail('');
          setAdminPassword();
          openSuccessSB();
        } else {
          setMessage(res.message);
          setOpenMessage(true);
          setLoading(false);
          // fetchUsersList();
          fetchAdminList();
          handleCloseDelete();
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="User Updated Successfully"
      content="Your User is now updated successfully"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content="Getting Error while updating User"
      dateTime="Just Now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
              <MDButton
                  variant="contained"
                  size="small"
                  onClick={handleOpen}
                  className={'text-[#000000] bg-white'}
              >
                Create new Admin
              </MDButton>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                  >
                    Create New Admin
                  </Typography>
                  <Grid container m={'1.5rem 0'} py={2} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="FIRST NAME"
                        variant="outlined"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="LAST NAME"
                        variant="outlined"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="EMAIL"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container mb={'1.5rem'} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <FormControl fullWidth >
                        <FormGroup>
                          <InputLabel id="adminTypeSelectLabel">DESIGNATION</InputLabel>
                          <Select
                            style={{padding: 12}}
                            labelId="adminTypeSelectLabel"
                            id="adminTypeSelect"
                            label="DESIGNATION"
                            value={userType}
                            onChange={(e) => setUserType(e.target.value)}
                          >
                            {userTypeList.map((ut) => {
                              return <MenuItem value={ut.userType} key={ut.userType_id}>{ut.userType}</MenuItem>
                            })}
                          </Select>
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="EMPLOYEE ID"
                        variant="outlined"
                        value={employeeId}
                        onChange={(e) => setEmployeeId(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="PASSWORD"
                        variant="outlined"
                        value={adminPassword}
                        onChange={(e) => setAdminPassword(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid container mb={'1.5rem'} pt={2} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <FormControl style={{width: '100%'}}>
                        <TextField
                          fullWidth
                          type='date'
                          id="outlined-basic"
                          variant="outlined"
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="ADDRESS"
                        variant="outlined"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="POST CODE"
                        variant="outlined"
                        value={postcode}
                        onChange={(e) => setPostcode(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}
                  {/* <Grid container mb={'1.5rem'} py={2} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="MOBILE NO"
                        variant="outlined"
                        value={mobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}
                  <Grid container justifyContent={'space-evenly'}>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={handleAPI}
                        disabled={
                          !firstName || !lastName 
                          || !email || !userType 
                          || !employeeId || !adminPassword 
                          ? true : false
                        }
                      >
                        SEND
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="medium"
                        onClick={handleClose}
                        >
                        CLOSE
                      </MDButton>
                    </Grid>                        
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openupdate}
                onClose={handleCloseupdate}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                  >
                    Update User
                  </Typography>
                  <Grid container m={'1.5rem 0'} py={2} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="FIRST NAME"
                        variant="outlined"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Grid>
                    {/* <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="LAST NAME"
                        variant="outlined"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Grid> */}
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="EMAIL"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <FormControl fullWidth >
                        <FormGroup>
                          <InputLabel id="adminTypeSelectLabel">DESIGNATION</InputLabel>
                          <Select
                            style={{padding: 12}}
                            labelId="adminTypeSelectLabel"
                            id="adminTypeSelect"
                            label="DESIGNATION"
                            value={userType}
                            onChange={(e) => setUserType(e.target.value)}
                          >
                            {userTypeList.map((ut) => {
                              return <MenuItem value={ut.userType} key={ut.userType_id}>{ut.userType}</MenuItem>
                            })}
                          </Select>
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mb={'1.5rem'} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5} mt={-1.4}>
                      <InputLabel id="activityLabel">Activity Status: </InputLabel>
                      <Select
                        fullWidth
                        style={{padding: 11}}
                        labelId="activityLabel"
                        id="activitySelect"
                        value={activity}
                        label="Activity Status: "
                        onChange={(e) => setActivity(e.target.value)}
                      >
                        <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
                        <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={'space-evenly'}>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={updateAPI}
                        disabled={
                          !firstName || !userType
                          || !email? true : false
                        }
                      >
                        SEND
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="medium"
                        onClick={handleCloseupdate}
                        >
                        CLOSE
                      </MDButton>
                    </Grid>                        
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: 'center'}}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                    style={{marginBottom: '2rem'}}
                  >
                    Are You Sure You Want To Delete This User?
                  </Typography>
                  {firstName.toUpperCase()}
                  <Grid 
                    container 
                    justifyContent={'space-evenly'}
                    style={{ marginTop: "2rem" }}
                  >
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={deleteUser}
                      >
                        Delete
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="small"
                        onClick={handleCloseDelete}
                      >
                        CLOSE
                      </MDButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openMessage}
                onClose={handleCloseMessage}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: 'center'}}>
                  <Grid item xs={12}>
                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h2"
                      style={{marginBottom: '2rem',}}
                    >
                      {message}
                    </Typography>
                  </Grid>
                  <Grid 
                    container 
                    justifyContent={'center'}
                    style={{ marginTop: "2rem" }}
                  >
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="small"
                        onClick={handleCloseMessage}
                      >
                        CLOSE
                      </MDButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openMore}
                onClose={handleCloseMore}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: 'center'}}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                    style={{marginBottom: '2rem'}}
                  >
                    More Info About: -
                  </Typography>
                  {/* {firstName?.toUpperCase()} {lastName?.toUpperCase()}
                  <Grid container m={'1.5rem 0'} py={2} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="CITY"
                        variant="outlined"
                        value={city?.toUpperCase()}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="COUNTRY"
                        variant="outlined"
                        value={country?.toUpperCase()}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="GENDER"
                        variant="outlined"
                        value={gender?.toUpperCase()}
                        disabled
                      />
                    </Grid> */}
                  {/* </Grid> */}
                  <Grid 
                    container 
                    justifyContent={'space-evenly'}
                    style={{ marginTop: "2rem" }}
                  >
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="small"
                        // onClick={MoreUser}
                      >
                        Okay
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="small"
                        onClick={handleCloseMore}
                      >
                        CLOSE
                      </MDButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
              
              {renderSuccessSB}
              {renderErrorSB}
              </MDBox>
              <MDBox pt={3} style={{height: '44rem', overflow: 'auto'}}>
                {loading ? 
                  <Grid container justifyContent={'center'}height={'80vh'}>
                    <SpinnerInfinity 
                      size={190} 
                      thickness={180} 
                      speed={100} 
                      color="rgba(59, 172, 57, 1)" 
                      secondaryColor="rgba(57, 145, 172, 0.44)" 
                    />
                  </Grid> :
                <AppContainer>
                  <AdminData 
                    setOpenupdate={setOpenupdate}
                    setAdminId={setAdminId}
                    setFirstName={setFirstName}
                    setLastName={setLastName}
                    setEmail={setEmail}
                    setEmployeeId={setEmployeeId}
                    setAdminPassword={setAdminPassword}
                    setActivity={setActivity}
                    setUserType={setUserType}
                    setOpenDelete={setOpenDelete}
                    adminList={adminList}
                    setOpenMore={setOpenMore}
                  />
                </AppContainer>}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;