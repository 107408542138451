import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import tw from "twin.macro";
import { GlobalFilter } from "./GlobalFilter";
import '../../../index.css'
import ListContext from "layouts/apiContext/listContext";
import { useContext } from "react";

const Table = tw.table`
  table-fixed
  text-base
  text-gray-900
`;

const TableHead = tw.thead`
  p-2
  bg-gradient-to-r
  from-[#F44335]
  to-[#f65f53]
`;

const TableRow = tw.tr`
  text-center
  border
  border-blue-500
`;

const TableHeader = tw.th`
  border
  border-blue-500
  p-2
`;

const TableBody = tw.tbody`

`;

const TableData = tw.td`
  border
  border-blue-500
  p-5
`;

const Button = tw.button`
  pl-4
  pr-4
  pt-2
  pb-2
  text-white
  rounded-md
  bg-gradient-to-r
  from-[#F44335]
  to-[#f65f53]
  hover:bg-blue-300
  hover:cursor-pointer
  transition-colors
`;

export function RankData(props) {

  const {
    rankList,
    fetchRankList
  } = useContext(ListContext);

  // const [ rankList, setRankList ] = useState([]);
  const [ className, setClassName ] = useState('ALL');
  const [ csvReport, setCsvReport ] = useState({
    data: rankList,
    headers: [
      { label: "Rank Id", key: "reward_id" },
      { label: "Status", key: "active"},
      { label: "Date Created", key: "date_created"},
    ],
    filename: 'Class List.csv'
  });
  const [ activity, setActivity ] = useState('ALL');
  // const [ board, setBoard ] = useState('ALL');


  // const activityChangeHandler = (e) => {
  //   setActivity(e.target.value)
  //   if (e.target.value === 'ALL') {
  //     setFilteredSubject(props.subjectList)
  //   }
  //   else {
  //     setFilteredSubject(props.subjectList.filter((brd) => brd.active === e.target.value))
  //   }
  // }
  

  useEffect(() => {
    
    const headers = [
      { label: "Rank Id", key: "reward_id" },
      { label: "Status", key: "active"},
      { label: "Date Created", key: "date_created"},
    ]

    setCsvReport({
      data: props?.rankList,
      headers: headers,
      filename: 'Rank List.csv'
    })

  }, [props])

  useEffect(() => {
    fetchRankList();
  }, [])

  const rankData = useMemo(() => [...rankList], [rankList]);

  const rankColoumns = useMemo(
    () =>
      rankList[0]
        ? Object.keys(rankList[0])
            .filter((key) => key !== 'date_modified' && key !== 'created_by')
            .map((key) => {
              if (key === "reward_image")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <img 
                    src={value} 
                    style={{ margin: 'auto', width: 70, height: 70 }} 
                    alt=''
                  />,
                  
                };
              if (key === "active")
                return {
                  Header: 'status',
                  accessor: key,
                  Cell: ({ value }) => <h4><b>{value===0? <p style={{color: 'red'}}>InActive</p>: <p style={{color: 'green'}}>Active</p>}</b></h4>,
                  
                };
              if (key === "date_created")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <p>{value.slice(0,-14)}</p>,
                  
                };
              if (key === "color")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <div style={{ width: 70, height: 70, background: value, margin: 'auto', borderRadius: '50%' }}></div>,  
              };

              return { Header: key, accessor: key };
            })
        : [],
    [rankList]
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "ACTIONS",
        Header: "ACTIONS",
        Cell: ({ row }) => (
          <>
            <Button 
              style={{marginRight: '1rem', width: '5rem'}}
              onClick={() => {
                props.setHeading(row.original?.heading)
                props.setSubHeading(row.original?.sub_heading)
                props.setDescription(row.original?.description)
                props.setActivity(row.original?.active)
                props.setOldLogo(row.original?.reward_image);
                props.setColor(row.original?.color)
                props.setOpenupdate(true)
            }}>
              Edit
            </Button>
            <Button 
              style={{width: '5rem'}}
              onClick={() => {
                props.setDescription(row.original?.description)
                props.setRewardId(row.original?.reward_id)
                props.setOpenDelete(true)
            }}>
              Delete
            </Button>
            
          </>
        ),
      },
    ]);
  };

  const tableInstance = useTable(
    {
      columns: rankColoumns,
      data: rankData,
    },
    useGlobalFilter,
    tableHooks,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = tableInstance;

  const isEven = (idx) => idx % 2 === 0;

  const { setOpenNone } = props

  useEffect(() => {
    setOpenNone(true? rankList.length  === 0 : false)
  }, [rankList, setOpenNone])

  return (
    <>
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={4}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
        </Grid>
        <Grid item xs={2}>
          {/* <FormControl 
            fullWidth
          >
            <InputLabel id="activityLabel">Activity Status: </InputLabel>
              <Select
                fullWidth
                style={{padding: 11}}
                labelId="activityLabel"
                id="activitySelect"
                value={activity}
                label="Activity Status: "
                onChange={activityChangeHandler}
              >
                <MenuItem value={'ALL'} style={{color: 'green', fontWeight: 'bold'}} >ALL</MenuItem>
                <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
                <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
              </Select>
          </FormControl> */}
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'}>
        <Grid item flex style={{background: '#F44335', color: '#ffffff', fontSize: '1.2rem', padding: '0.8rem', textAlign: 'center', borderRadius: '50%', width: '70px', border: '4px solid',}}>
          <FormControl fullWidth>
            {rankList.length < 2 ? null :
              <CSVLink {...csvReport}>&#8595;</CSVLink>
            }
          </FormControl>
        </Grid>
      </Grid>
      <Table {...getTableProps()} style={{maxWidth: '100%'}}>
        <TableHead className={"bg-[#1D72E8] text-white"}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeader
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header").toUpperCase().replace('_',' ')}
                  {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);

            return (
              <TableRow
                {...row.getRowProps()}
                className={!isEven(idx) ? "bg-[#F44335] bg-opacity-30" : ""}
                key={Math.random().toString()}
              >
                {row.cells.map((cell, idx) => (
                  <TableData {...cell.getCellProps()}
                  key={Math.random().toString()}>
                    {cell.render("Cell")}
                  </TableData>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}