/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts

// @mui icons
import Icon from "@mui/material/Icon";
import Controls from "layouts/Controls/Controls";
import VideoUpload from "layouts/ControlComponents/VideoUpload";
import ConceptUpload from "layouts/ControlComponents/ConceptUpload";
import BannerPage from "layouts/ControlComponents/BannerPage";
import VideoList from "layouts/ControlComponents/VideoList";
import ConceptList from "layouts/ControlComponents/ConceptList";
import CreateTest from "layouts/ControlComponents/CreateTest";
import AdminPermissions from "layouts/ControlComponents/AdminPermissions"
import SubscriptionList from "layouts/ControlComponents/SubscriptionList"
import UserSubscriptionList from "layouts/ControlComponents/UserSubscriptionList"
import SubjectsList from "layouts/tables/SubjectsList"
import BoardsList from "layouts/tables/BoardsList"
import ClassList from "layouts/tables/index"
import UsersList from "layouts/tables/UsersList"
import TopicList from "layouts/tables/TopicList"
import RankList from "layouts/tables/RankList"
import TestList from "layouts/ControlComponents/TestList";
import TestDetails from "layouts/ControlComponents/TestDetails";
import UpdateTest from "layouts/ControlComponents/UpdateTest";
import BestVideoList from "layouts/ControlComponents/BestVideoList";
import BestVideoUpload from "layouts/ControlComponents/BestVideoUpload";
import UserPerformance from "layouts/ControlComponents/UserPerformance";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <UsersList />,
  },
  {
    type: "collapse",
    name: "Controls",
    key: "controls",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/controls",
    component: <Controls />,
  },
  {
    nodisplay: true,
    type: "collapse",
    name: "Video Upload",
    key: "VideoUpload",
    icon: <Icon fontSize="small">upload</Icon>,
    route: "/videoupload",
    component: <VideoUpload />,
  },
  {
    nodisplay: true,
    type: "collapse",
    name: "Video Upload",
    key: "BestVideoUpload",
    icon: <Icon fontSize="small">upload</Icon>,
    route: "/bestvideoupload",
    component: <BestVideoUpload />,
  },
  {
    nodisplay: true,
    type: "collapse",
    name: "Concept Upload",
    key: "conceptupload",
    icon: <Icon fontSize="small">upload</Icon>,
    route: "/conceptupload",
    component: <ConceptUpload />,
  },
  {
    nodisplay: true,
    type: "collapse",
    name: "Banner",
    key: "banner",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/banner",
    component: <BannerPage />,
  },
  {
    nodisplay: true,
    type: "collapse",
    name: "Video List",
    key: "videoList",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/videolist",
    component: <VideoList />,
  },
  {
    nodisplay: true,
    type: "collapse",
    name: "Best Video List",
    key: "bestVideoList",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/bestvideolist",
    component: <BestVideoList />,
  },
  {
    nodisplay: true,
    type: "collapse",
    name: "Concept List",
    key: "conceptList",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/conceptlist",
    component: <ConceptList />,
  },
  {
    nodisplay: true,
    type: "collapse",
    key: "createtest",
    route: "/createtest",
    component: <CreateTest />,
  },
  {
    nodisplay: true,
    type: "collapse",
    key: "testlist",
    route: "/testlist",
    component: <TestList />,
  },
  {
    nodisplay: true,
    type: "collapse",
    key: "testdetails",
    route: "/testdetails",
    component: <TestDetails />,
  },
  {
    nodisplay: true,
    type: "collapse",
    key: "updatetest",
    route: "/updatetest",
    component: <UpdateTest />,
  },
  {
    nodisplay: true,
    type: "collapse",
    name: "Admin Permissions",
    key: "AdminPermissions",
    icon: <Icon fontSize="small">list</Icon>,
    route: "/adminpermissions",
    component: <AdminPermissions />,
  },
  {
    nodisplay: true,
    type: "collapse",
    key: "userslist",
    route: "/userslist",
    component: <UsersList />,
  },
  {
    nodisplay: true,
    type: "collapse",
    key: "classlist",
    route: "/classlist",
    component: <ClassList />,
  },
  {
    nodisplay: true,
    type: "collapse",
    key: "subjectslist",
    route: "/subjectslist",
    component: <SubjectsList />,
  },
  {
    nodisplay: true,
    type: "collapse",
    key: "boardslist",
    route: "/boardslist",
    component: <BoardsList />,
  },
  {
    nodisplay: true,
    type: "collapse",
    key: "topiclist",
    route: "/topiclist",
    component: <TopicList />,
  },
  {
    nodisplay: true,
    type: "collapse",
    key: "ranklist",
    route: "/ranklist",
    component: <RankList />,
  },
  {
    nodisplay: true,
    type: "collapse",
    key: "subscriptionlist",
    route: "/subscriptionList",
    component: <SubscriptionList />,
  },
  {
    nodisplay: true,
    type: "collapse",
    key: "usersubscriptionlist",
    route: "/userSubscriptionList",
    component: <UserSubscriptionList />,
  },
  {
    nodisplay: true,
    type: "collapse",
    key: "userperformance",
    route: "/userPerformance",
    component: <UserPerformance />,
  },
  // {
  //   nodisplay: true,
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/",
  //   component: <SignIn />,
  // },
];

export default routes;
