/**
  =========================================================
  * Material Dashboard 2 React - v2.1.0
  =========================================================

  * Product Page: https://www.creative-tim.com/product/material-dashboard-react
  * Copyright 2022 Creative Tim (https://www.creative-tim.com)

  Coded by www.creative-tim.com

  =========================================================

  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
  */

  import * as React from "react";
  
  // @mui material components
  import Grid from "@mui/material/Grid";
  import Card from "@mui/material/Card";
  
  // Material Dashboard 2 React components
  import MDBox from "components/MDBox";
  
  // Material Dashboard 2 React example components
  import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
  import DashboardNavbar from "examples/Navbars/DashboardNavbar";
  import MDButton from "components/MDButton";
  import { useState, useEffect } from "react";
  import { useLocation } from "react-router-dom";
  import globalURL from "../../config";
  
  function TestDetails() {
  
    const location = useLocation();
    const [ testName, setTestName ] = useState('');
    const [ questionNo, setQuestionNo ] = useState('');
    const [ className, setClassName ] = useState('');
    const [ subjectName, setSubjectName ] = useState('');
    const [ topicName, setTopicName ] = useState('');
    const [ totalMarks, setTotalMarks ] = useState('');
    const [ totalTime, setTotalTime ] = useState('');
    const [ testDate, setTestDate ] = useState(new Date());
    const [ instruction, setInstruction ] = useState('Choose');
    const [ solDesc, setSolDesc ] = useState('');
    const [ count, setCount ] = useState(1);
    const [ testFetched, setTestFetched ] = useState(false);
    const [ testDetails, setTestDetails ] = useState({});

    useEffect(() => {
        setTestName(testDetails.test_name);
        setQuestionNo(testDetails.no_question);
        setTotalTime(testDetails.total_time);
        setInstruction(testDetails.instruction);
        setClassName(testDetails.classData?.class_name);
        setSubjectName(testDetails.subjectData?.subject);
        setTopicName(testDetails.TopicData?.topic);
        setTotalMarks(testDetails.total_mark);
        setTestDate(testDetails.test_date);
        setSolDesc(testDetails.solutionDescription)
    }, [testDetails])

    useEffect(() => {
        async function getUserToken() {
          return await localStorage.getItem("userInfo");
        }
        getUserToken().then((userToken) => {
        const requestOptionsSet = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
            // 'Authorization': 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1c2VyX2lkIjoiMSIsImVtYWlsIjoic2hpdmtyOTgxODhAZ21haWwuY29tIiwibG9naW5UaW1lIjoxNjUxNTcyODIwOTYwLCJnZW5lcmF0ZWRfYXQiOjE2NTE1NzI4MjA5NjAsImlhdCI6MTY1MTU3MjgyMCwiZXhwIjoxNjgzMTMwNDIwLCJpc3MiOiJSZWNydWl0In0.T4fHdvweSB4iFL7oXrKXiIh9u6IHvXQtVUfX-mafNZ3Gl3hJyC7AyXSCPgin_r_FRKz2nHnFe5a6epsbM_Idqw'
          },
          body: JSON.stringify({
              "test_id": location.state.testId
              // "test_id": "test_idd547fc67c1bf"
          })
        }
          fetch(
          globalURL + "test/showTestData",
          requestOptionsSet
        )
          .then((res) => res.json())
          .then((res) => {
              setTestDetails(res.data);
              setTestFetched(true);
          });
        })
    }, [location.state.testId])
  
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card style={{padding: 30}}>
                <MDBox
                  mx={2}
                  mt={-7}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                >
                  {/* <div> */}
                  <MDButton
                    variant="contained"
                    color="white"
                    size="small"
                  >
                    Enter Test Details
                  </MDButton>
                </MDBox>
                {testFetched && 
                  <>
                    <Grid container mt={10} justifyContent={'space-evenly'}>
                        <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                            <Grid item xs={3.5}>
                                Test Name:  
                            </Grid>
                            <Grid item xs={8} style={{textAlign: 'right'}}>
                                {testName}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                            <Grid item xs={5.9}>
                                No of Questions:  
                            </Grid>
                            <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                {questionNo}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                            <Grid item xs={5.9}>
                                Total Time: 
                            </Grid>
                            <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                {totalTime}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                            <Grid item xs={3.3}>
                                Instructions:
                            </Grid>
                            <Grid item xs={8.7} style={{textAlign: 'right'}}>
                                {instruction}
                            </Grid> 
                        </Grid>
                        <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                            <Grid item xs={5.9}>
                                Class:  
                            </Grid>
                            <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                {className}
                            </Grid>
                        </Grid>
                        <Grid container xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}} justifyContent={'space-between'}>
                            <Grid item xs={5.9}>
                                Subject: 
                            </Grid>
                            <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                {subjectName}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                            <Grid item xs={5.9}>
                                Topic:  
                            </Grid>
                            <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                {topicName}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                            <Grid item xs={5.9}>
                                Total Marks:  
                            </Grid>
                            <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                {totalMarks}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                            <Grid item xs={5.9}>
                                Test Date:  
                            </Grid>
                            <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                {testDate}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container mt={3} justifyContent={'center'}>
                      <Grid item>
                        {testDetails.QuestionData.map((ques) => {
                          return (<>
                            <Grid container style={{border: '2px solid #1A73E8', borderRadius: '25px', marginTop: '1rem', padding: '1rem'}} key={Math.random().toString()}>
                              <Grid container style={{borderBottom: '1px solid #1A73E8'}}>
                                <Grid item xs={1.2} style={{fontSize: '1.5rem'}}>
                                  Question {ques.id}:
                                </Grid>
                                <Grid item xs={10}>
                                  {ques.question}
                                </Grid>
                              </Grid>
                              <Grid container style={{borderBottom: '1px  #1A73E8'}} py={1.5} justifyContent={'space-evenly'} alignItems={'center'}>
                                <Grid item xs={2}>
                                  Marks : {ques.marks}
                                </Grid>
                                <Grid item xs={2}>
                                  {<p> Compulsory: {ques.compulsory === 0? <span style={{color: 'red'}}>NO</span>: <span style={{color: 'green'}}>YES</span>}</p>}
                                </Grid>
                                <Grid item xs={2}>
                                  {<p> Active: {ques.active === 0? <span style={{color: 'red'}}>NO</span>: <span style={{color: 'green'}}>YES</span>}</p>}
                                </Grid>
                                <Grid item>
                                  <img 
                                    src={ques.image} 
                                    style={{ margin: 'auto', width: 70, height: 70 }} 
                                    alt=''
                                  />
                                </Grid>
                              </Grid>
                              <Grid container style={{borderBottom: '1px  #1A73E8'}} py={1.5} justifyContent={'space-evenly'} alignItems={'center'}>
                                {ques.solutionDescription && 
                                  <Grid item xs={2}>
                                    SOLUTION : {ques.solutionDescription}
                                  </Grid>}
                              </Grid>
                              {ques.optionData.map((opt) => {
                                return (
                                  <Grid 
                                    container 
                                    style={{borderTop: '1px solid #1A73E8'}} 
                                    py={1.5} 
                                    justifyContent={'space-evenly'}
                                    alignItems={'center'}
                                    onLoad={() => {
                                      setCount(count+1);
                                    }}
                                    key={Math.random().toString()}
                                  >
                                    <Grid item xs={8}>
                                      Option : {opt.option}
                                    </Grid>
                                    <Grid item xs={2}>
                                      {<p> Is Corret: {opt.is_correct === 0? <span style={{color: 'red'}}>NO</span>: <span style={{color: 'green'}}>YES</span>}</p>}
                                    </Grid>
                                    <Grid item>
                                    <img 
                                      src={opt.image} 
                                      style={{ margin: 'auto', width: 70, height: 70 }} 
                                      alt=''
                                    />
                                    </Grid>
                                    {/* <Grid item xs={2}>
                                      {<p> Active: {opt.active === 0? <span style={{color: 'red'}}>NO</span>: <span style={{color: 'green'}}>YES</span>}</p>}
                                    </Grid> */}
                                  </Grid>
                                  )
                              })}
                            </Grid></>
                          )
                        })
                        }
                      </Grid>
                    </Grid>
                  </>
                    }
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {/* <Footer /> */}
      </DashboardLayout>
    );
  }
  
  export default TestDetails;
  