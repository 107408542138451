/**
  =========================================================
  * Material Dashboard 2 React - v2.1.0
  =========================================================

  * Product Page: https://www.creative-tim.com/product/material-dashboard-react
  * Copyright 2022 Creative Tim (https://www.creative-tim.com)

  Coded by www.creative-tim.com

  =========================================================

  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
  */

  import * as React from "react";
  
  // @mui material components
  import Grid from "@mui/material/Grid";
  import Card from "@mui/material/Card";
  
  // Material Dashboard 2 React components
  import MDBox from "components/MDBox";
  
  // Material Dashboard 2 React example components
  import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
  import DashboardNavbar from "examples/Navbars/DashboardNavbar";
  import MDButton from "components/MDButton";
  import { useState, useEffect } from "react";
  import { useLocation } from "react-router-dom";
  import globalURL from "../../config";
import { Box, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import ListContext from "layouts/apiContext/listContext";
import { useContext } from "react";
import { SpinnerInfinity } from "spinners-react";
  
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: '2rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 6,
};
  
  function TestDetails() {

    const {
      boardList,
      fetchClassByBoard,
      filteredClassList,
      filteredSubjectList,
      filteredTopicList,
      fetchSubjectByClass,
      fetchTopicBySubject
    } = useContext(ListContext)
  
    const location = useLocation();
    
    const [ loading, setLoading ] = useState('');
    const [ testName, setTestName ] = useState('');
    const [ questionNo, setQuestionNo ] = useState('');
    const [ boardId, setBoardId ] = useState('');
    const [ boardName, setBoardName ] = useState('');
    const [ classId, setClassId ] = useState('');
    const [ className, setClassName ] = useState('');
    const [ subjectId, setSubjectId ] = useState('');
    const [ subjectName, setSubjectName ] = useState('');
    const [ topicId, setTopicId ] = useState('');
    const [ topicName, setTopicName ] = useState('');
    const [ totalMarks, setTotalMarks ] = useState('');
    const [ totalTime, setTotalTime ] = useState('');
    const [ testDate, setTestDate ] = useState(new Date());
    const [ instruction, setInstruction ] = useState('Choose');
    const [ count, setCount ] = useState(1);
    const [ testFetched, setTestFetched ] = useState(false);
    const [ testDetails, setTestDetails ] = useState({});
    const [ oldLogo, setOldLogo ] = useState('');
    const [ newLogo, setNewLogo ] = useState('');
    const [ questionId, setQuestionId] = useState('')
    const [ question, setQuestion ] = useState('');
    const [ compulsory, setCompulsory ] = useState('');
    const [ activity, setActivity ] = useState('');
    const [ qMarks, setQMarks ] = useState('');
    const [ solDesc, setSolDesc ] = useState('');
    const [ isCorrect, setIsCorrect ] = useState('');
    const [ option, setOption ] = useState('');
    const [ optionId, setOptionId ] = useState('');
    // const [ testId, setTestId ] = useState('')

    const [ message, setMessage ] = useState('');
    const [ openMessage, setOpenMessage ] = useState(false);
    const handleCloseMessage = () => setOpenMessage(false);

    useEffect(() => {
        setTestName(testDetails.test_name);
        setQuestionNo(testDetails.no_question);
        setTotalTime(testDetails.total_time);
        setInstruction(testDetails.instruction);
        setBoardId(testDetails.board_id);
        setBoardName(testDetails.TopicData?.board_master.board_name);
        setClassId(testDetails.class_id);
        setClassName(testDetails.classData?.class_name);
        setSubjectId(testDetails.subject_id);
        setSubjectName(testDetails.subjectData?.subject);
        setTopicName(testDetails.TopicData?.topic);
        setTotalMarks(testDetails.total_mark);
        setTestDate(testDetails.test_date);
    }, [testDetails])

    const fetchTestData = () => {
        async function getUserToken() {
          return await localStorage.getItem("userInfo");
        }
        getUserToken().then((userToken) => {
        const requestOptionsSet = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
            // 'Authorization': 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1c2VyX2lkIjoiMSIsImVtYWlsIjoic2hpdmtyOTgxODhAZ21haWwuY29tIiwibG9naW5UaW1lIjoxNjUxNTcyODIwOTYwLCJnZW5lcmF0ZWRfYXQiOjE2NTE1NzI4MjA5NjAsImlhdCI6MTY1MTU3MjgyMCwiZXhwIjoxNjgzMTMwNDIwLCJpc3MiOiJSZWNydWl0In0.T4fHdvweSB4iFL7oXrKXiIh9u6IHvXQtVUfX-mafNZ3Gl3hJyC7AyXSCPgin_r_FRKz2nHnFe5a6epsbM_Idqw'
          },
          body: JSON.stringify({
              "test_id": location.state.testId
              // "test_id": "test_idd547fc67c1bf"
          })
        }
          fetch(
          globalURL + "test/showTestData",
          requestOptionsSet
        )
          .then((res) => res.json())
          .then((res) => {
              setTestDetails(res.data);
              setTestFetched(true);
          });
        })
    }

    useEffect(() => {
      fetchTestData();
    }, [location.state.testId])

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const detailsChangeHandler = () => {
      setOpen(false);
    }
    const handleClose = () => setOpen(false);

    const [openupdate, setOpenupdate] = useState(false);
    const handleCloseupdate = () => {
      setOpenupdate(false)
      setOldLogo('')
      setQuestion('')
      setSolDesc('')
      setQMarks('')
      setCompulsory('')
      setActivity('')
      setNewLogo('')
      // setLogo('');
      // setBoardName('');
    };

    const [ openOptionUpdate, setOpenOptionUpdate] = useState(false);
    const handleCloseOptionUpdate = () => {
      setOpenOptionUpdate(false)
      setOldLogo('')
      setQuestion('')
      setSolDesc('')
      setQMarks('')
      setCompulsory('')
      setActivity('')
      setNewLogo('')
      // setLogo('');
      // setBoardName('');
    };

    const testDetailsUpdate = async () => {
      setLoading(true);
      handleClose();
      async function getUserToken() {
        return await localStorage.getItem("userInfo");
      }
      getUserToken().then((userToken) => {
      const requestOptionsSet = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
        },
        body: JSON.stringify({
          test_id: location.state.testId,
          test_name: testName,
          no_question: questionNo.toString(),
          total_time: totalTime.toString(),
          instruction: instruction,
          subject_id: subjectId,
          class_id: classId,
          topic_id: topicId,
          total_mark: totalMarks.toString(),
          test_date: testDate,
        }),
      }
        fetch(
        globalURL + "test/update_test",
        requestOptionsSet
      )
        .then((res) => res.json())
        .then((res) => {
          if( res.messade === "Success") {
            setLoading(false);
            fetchTestData();
            setMessage(res.message);
            setOpenMessage(true);
            // navigate('/testlist');
          }
          else {
            fetchTestData();
            setOpenMessage(true);
            setMessage(res.message);
            setLoading(false);
          }
        })
        .catch((e) => {
          fetchTestData();
          setLoading(false);
        })
      })
    };

    const testQuestionUpdate = async () => {
      setLoading(true);
      handleClose();
      async function getUserToken() {
        return await localStorage.getItem("userInfo");
      }
      getUserToken().then((userToken) => {
      const requestOptionsSet = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
        },
        body: JSON.stringify({
          question_id: questionId,
          question: question,
          question_image: newLogo.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''),
          compulsory: compulsory,
          marks: parseInt(qMarks),
          solutionDescription: solDesc,
          active: activity,
        }),
      }
        fetch(
        globalURL + "test/updateQuestion",
        requestOptionsSet
      )
        .then((res) => res.json())
        .then((res) => {
          if( res.messade === "Success") {
            setLoading(false);
            fetchTestData();
            setOpenupdate(false)
            setOldLogo('')
            setMessage(res.message);
            setOpenMessage(true);
            setQuestion('')
            setQuestionId('')
            setSolDesc('')
            setQMarks('')
            setCompulsory('')
            setActivity('')
            setNewLogo('')
            // navigate('/testlist');
          }
          else {
            setOpenupdate(false)
            setOldLogo('')
            setQuestion('')
            setSolDesc('')
            setQuestionId('')
            setQMarks('')
            setCompulsory('')
            setActivity('')
            fetchTestData();
            setMessage(res.message);
            setOpenMessage(true);
            setNewLogo('')
            setLoading(false);
          }
        })
        .catch((e) => {
          setOpenupdate(false)
          setOldLogo('')
          setQuestion('')
          setSolDesc('')
          setQMarks('')
          setQuestionId('')
          fetchTestData();
          setCompulsory('')
          setActivity('')
          setNewLogo('')
          setLoading(false);
        })
      })
    };

    const testOptionUpdate = async () => {
      setLoading(true);
      handleClose();
      async function getUserToken() {
        return await localStorage.getItem("userInfo");
      }
      getUserToken().then((userToken) => {
      const requestOptionsSet = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
        },
        body: JSON.stringify({
          question_id: questionId,
          option_id: optionId,
          option: option,
          option_image: newLogo.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''),
          is_correct: isCorrect,
          active: activity,
        }),
      }
        fetch(
        globalURL + "test/udpateOption",
        requestOptionsSet
      )
        .then((res) => res.json())
        .then((res) => {
          if( res.messade === "Success") {
            setLoading(false);
            fetchTestData();
            setOptionId('')
            setOpenOptionUpdate(false)
            setOldLogo('')
            setOption('')
            setQuestionId('')
            setSolDesc('')
            setQMarks('')
            setMessage(res.message);
            setOpenMessage(true);
            setIsCorrect('')
            setActivity('')
            setNewLogo('')
            // navigate('/testlist');
          }
          else {
            setOpenOptionUpdate(false)
            setOldLogo('')
            setOption('')
            setQuestionId('')
            setOptionId('')
            setSolDesc('')
            setQMarks('')
            setMessage(res.message);
            setOpenMessage(true);
            setIsCorrect('')
            setActivity('')
            fetchTestData();
            setNewLogo('')
            setLoading(false);
          }
        })
        .catch((e) => {
          setOpenOptionUpdate(false)
          setOldLogo('')
          setQuestion('')
          setSolDesc('')
          setQuestionId('')
          setOptionId('')
          setQMarks('')
          fetchTestData();
          setIsCorrect('')
          setActivity('')
          setNewLogo('')
          setLoading(false);
        })
      })
    };

    const uploadImage = async (image) => {
      
      const file = image.target.files[0]
      const base64 = await convertBase64(file)
      setNewLogo(base64);
  
    }
  
    const convertBase64 = (file) => {
  
      return new Promise((resolve, reject) => {
  
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
  
        fileReader.onload = (() => {
          resolve(fileReader.result);
        });
  
        fileReader.onerror = ((error) => {
          reject(error);
        });
  
      });
  
    }
  
    return (
      <DashboardLayout>
        <DashboardNavbar />
        {loading ? 
          <Grid container justifyContent={'center'}height={'80vh'}>
            <SpinnerInfinity 
              size={190} 
              thickness={180} 
              speed={100} 
              color="rgba(59, 172, 57, 1)" 
              secondaryColor="rgba(57, 145, 172, 0.44)" 
            />
          </Grid> :
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card style={{padding: 30}}>
                  <MDBox
                    mx={2}
                    mt={-7}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="dark"
                    borderRadius="lg"
                    coloredShadow="dark"
                  >
                    {/* <div> */}
                    <MDButton
                      variant="contained"
                      color="white"
                      size="small"
                      onClick={handleOpen}
                    >
                      Enter Test Details
                    </MDButton>

                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h4"
                          component="h2"
                        >
                          Enter Test Details
                        </Typography>
                        <Grid container mb={2} justifyContent={'space-between'}>
                          <Grid item xs={3.5}>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="TEST NAME"
                              variant="outlined"
                              value={testName}
                              style={{ margin: "2rem auto" }}
                              onChange={(e) => setTestName(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={3.5}>
                            <TextField
                              fullWidth
                              type='number'
                              id="outlined-basic"
                              label="QUESTION NO"
                              variant="outlined"
                              value={questionNo}
                              style={{ margin: "2rem auto" }}
                              onChange={(e) => setQuestionNo(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={3.5}>
                            <FormControl 
                              fullWidth
                              style={{ margin: "2rem auto" }}
                            >
                              <InputLabel id="demo-simple-select-label">BOARD</InputLabel>
                              <Select
                                style={{padding: 11}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={boardId}
                                label="BOARD"
                                onChange={(e) => {
                                  setBoardId(e.target.value)
                                  fetchClassByBoard(e.target.value)
                                }}
                              >
q                              <MenuItem value={'ALL'}>ALL</MenuItem>
                                {boardList?.map((brd) => {
                                  if( brd.active === 1 ) return  <MenuItem value={brd.board_id} key={Math.random().toString()} onClick={() => setBoardName(brd.board_name)}>{brd.board_name}</MenuItem>        
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={3.5}>
                            <FormControl 
                              fullWidth
                              style={{ marginBottom: "1rem" }}
                            >
                              <InputLabel id="demo-simple-select-label">CLASS</InputLabel>
                              <Select
                                style={{padding: 10}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={classId}
                                label="CLASS"
                                onChange={(e) => {
                                  setClassId(e.target.value)
                                  fetchSubjectByClass(e.target.value)
                                }}
                              >
                                <MenuItem value={'ALL'}>ALL</MenuItem>
                                {filteredClassList.map((cls) => {
                                  return <MenuItem value={cls.class_id} key={cls.class_id} onClick={() => setClassName(cls.class_name)}>{cls.class_name}</MenuItem>
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={3.5}>
                            <FormControl fullWidth>
                              <InputLabel id="subject-select-label">SUBJECT</InputLabel>
                              <Select
                                style={{padding: 10}}
                                labelId="subject-select-label"
                                id="subject-select"
                                value={subjectId}
                                label="SUBJECT"
                                onChange={(e) => {
                                  setSubjectId(e.target.value)
                                  fetchTopicBySubject(e.target.value)
                                }}
                              >
                                <MenuItem value={'ALL'}>ALL</MenuItem>
                                {filteredSubjectList.map((sub) => {
                                  return <MenuItem value={sub.subject_id} key={sub.subject_id} onClick={() => setSubjectName(sub.subject)}>{sub.subject}</MenuItem>
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={3.5}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">TOPIC</InputLabel>
                            <Select
                              style={{padding: 10}}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={topicId}
                              label="TOPIC"
                              onChange={(e) => setTopicId(e.target.value)}
                            >
                              <MenuItem value={'ALL'}>ALL</MenuItem>
                              {filteredTopicList.map((tpc) => {
                                return <MenuItem value={tpc.topic_id} key={tpc.topic_id} onClick={() => setTopicName(tpc.topic)}>{tpc.topic}</MenuItem>
                              })}
                            </Select>
                          </FormControl>
                          </Grid>
                          <Grid item xs={3.5}>
                            <TextField
                              fullWidth
                              id="date"
                              label="TEST DATE"
                              type="date"
                              style={{ margin: "  1rem auto" }}
                              // value={testDate}
                              onChange={(e) => setTestDate(e.target.value)}
                              // sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={3.5}>
                          <FormControl 
                            fullWidth
                            style={{ margin: "1rem auto" }}
                          >
                            <InputLabel id="demo-simple-select-label">SET INSTRUCTIONS</InputLabel>
                            <Select
                              style={{padding: 11}}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={instruction}
                              label="SET INSTRUCTIONS"
                              onChange={(e) => setInstruction(e.target.value)}
                            >
                              <MenuItem value={'Choose'}>Choose</MenuItem>
                              <MenuItem value={'YES'}>YES</MenuItem>
                              <MenuItem value={'NO'}>NO</MenuItem>
                            </Select>
                          </FormControl>
                          </Grid>
                          <Grid item xs={3.5}>
                            <TextField
                              fullWidth
                              type='number'
                              id="outlined-basic"
                              label="TOTAL MARKS"
                              variant="outlined"
                              value={totalMarks}
                              style={{ margin: "1rem auto" }}
                              onChange={(e) => setTotalMarks(e.target.value)}
                            />
                          </Grid>
                        </Grid>
                        <Grid container justifyContent={'center'}>
                          <Grid item xs={3.5}>
                            <TextField
                              fullWidth
                              type='number'
                              id="outlined-basic"
                              label="TOTAL TIME"
                              variant="outlined"
                              value={totalTime}
                              style={{ marginBottom: "2rem" }}
                              onChange={(e) => setTotalTime(e.target.value)}
                            />
                          </Grid>
                        </Grid>
                        <Grid container justifyContent={'space-evenly'}>
                          <Grid item xs={4}>
                            <MDButton
                              fullWidth
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={detailsChangeHandler}
                              disabled={
                                !testName || !questionNo || !classId || !subjectId || !topicId || !testDate || !instruction || !totalMarks || !totalTime ? true : false
                              }
                            >
                              SAVE
                            </MDButton>

                          </Grid>
                          <Grid item xs={4}>
                            <MDButton
                              fullWidth
                              variant="contained"
                              color="dark"
                              size="small"
                              onClick={handleClose}
                            >
                              CLOSE
                            </MDButton>
                          </Grid>
                        </Grid>
                      </Box>
                    </Modal>

                    <Modal
                      open={openupdate}
                      onClose={handleCloseupdate}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h3"
                          component="h2"
                        >
                          Update Your Test Question
                        </Typography>
                        <Grid container justifyContent={'space-between'}>
                          <Grid item xs={6} justifyContent={'center'} style={{textAlign: 'center'}}>
                            <Grid item xs={12} justifyContent={'center'}>
                              {!oldLogo? null : <>
                              <label>Old Logo</label>
                                <img 
                                  id='oldLogo'
                                  src={oldLogo} 
                                  style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                                  alt=''
                                />
                              </>}
                            </Grid>
                          </Grid>
                          <Grid item xs={6} justifyContent={'center'} style={{textAlign: 'center'}}>
                            <Grid item>
                              <label>New Logo</label>
                              {newLogo? null :
                                  <input
                                    id='newLogo'
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    onChange={(image) => uploadImage(image)}
                                  />
                              }
                            </Grid>
                            <Grid item xs={12} justifyContent={'center'}>
                              {!newLogo? null : <>
                                <img 
                                  id='newLogo'
                                  src={newLogo} 
                                  style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                                  alt=''
                                />
                              </>}
                            </Grid>
                          </Grid> 
                        </Grid>                   
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Question"
                          variant="outlined"
                          value={question}
                          onChange={(e) => setQuestion(e.target.value)}
                          style={{ margin: "1rem 0" }}
                        />
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Solution Description"
                          variant="outlined"
                          value={solDesc}
                          onChange={(e) => setSolDesc(e.target.value)}
                          style={{ margin: "1rem 0" }}
                        />  
                        <Grid container justifyContent={'space-between'}>
                          <Grid item xs={3.5}>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="Marks"
                              variant="outlined"
                              value={qMarks}
                              onChange={(e) => setQMarks(e.target.value)}
                              style={{ margin: "1rem 0" }}
                            />
                          </Grid>
                          <Grid item xs={3.5}>
                            <FormControl 
                              fullWidth
                              style={{ margin: "1rem 0" }}
                            >
                              <InputLabel id="compulsoryLabel">Compulsory: </InputLabel>
                                <Select
                                  fullWidth
                                  style={{padding: 11}}
                                  labelId="compulsoryLabel"
                                  id="compulsorySelect"
                                  value={compulsory}
                                  label="Compulsory: "
                                  onChange={(e) => setCompulsory(e.target.value)}
                                >
                                  <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >YES</MenuItem>
                                  <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >NO</MenuItem>
                                </Select>
                            </FormControl>  
                          </Grid>
                          <Grid item xs={3.5}>
                            <FormControl 
                              fullWidth
                              style={{ margin: "1rem 0" }}
                            >
                              <InputLabel id="activityLabel">Activity Status: </InputLabel>
                                <Select
                                  fullWidth
                                  style={{padding: 11}}
                                  labelId="activityLabel"
                                  id="activitySelect"
                                  value={activity}
                                  label="Activity Status: "
                                  onChange={(e) => setActivity(e.target.value)}
                                >
                                  <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
                                  <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
                                </Select>
                              </FormControl>
                          </Grid>
                        </Grid>
                        <Grid 
                          container 
                          justifyContent={'space-evenly'}
                          style={{ marginTop: "20px" }}
                        >
                          <Grid item xs={4}>
                            <MDButton
                              fullWidth
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={testQuestionUpdate}
                              // onClick={Updateapi}
                              // disabled={
                              //   !updatedClassName || !updatedMedium
                              //   || !boardName
                              //     ? true
                              //     : false
                              // }
                            >
                              Update
                            </MDButton>
                          </Grid>
                          <Grid item xs={4}>
                            <MDButton
                              fullWidth
                              variant="contained"
                              color="dark"
                              size="small"
                              onClick={handleCloseupdate}
                            >
                              CLOSE
                            </MDButton>
                          </Grid>
                        </Grid>
                      </Box>
                    </Modal>

                    <Modal
                      open={openOptionUpdate}
                      onClose={handleCloseOptionUpdate}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h3"
                          component="h2"
                        >
                          Update Your Test Option
                        </Typography>
                        <Grid container justifyContent={'space-between'}>
                          <Grid item xs={6} justifyContent={'center'} style={{textAlign: 'center'}}>
                            <Grid item xs={12} justifyContent={'center'}>
                              {!oldLogo? null : <>
                              <label>Old Logo</label>
                                <img 
                                  id='oldLogo'
                                  src={oldLogo} 
                                  style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                                  alt=''
                                />
                              </>}
                            </Grid>
                          </Grid>
                          <Grid item xs={6} justifyContent={'center'} style={{textAlign: 'center'}}>
                            <Grid item>
                              <label>New Logo</label>
                              {newLogo? null :
                                  <input
                                    id='newLogo'
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    onChange={(image) => uploadImage(image)}
                                  />
                              }
                            </Grid>
                            <Grid item xs={12} justifyContent={'center'}>
                              {!newLogo? null : <>
                                <img 
                                  id='newLogo'
                                  src={newLogo} 
                                  style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                                  alt=''
                                />
                              </>}
                            </Grid>
                          </Grid> 
                        </Grid>                   
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Option"
                          variant="outlined"
                          value={option}
                          onChange={(e) => setOption(e.target.value)}
                          style={{ margin: "1rem 0" }}
                        />
                        <Grid container justifyContent={'space-between'}>
                          <Grid item xs={3.5}>
                            <FormControl 
                              fullWidth
                              style={{ margin: "1rem 0" }}
                            >
                              <InputLabel id="isCorrectLabel">Is Correct: </InputLabel>
                                <Select
                                  fullWidth
                                  style={{padding: 11}}
                                  labelId="isCorrectLabel"
                                  id="isCorrectSelect"
                                  value={isCorrect}
                                  label="IsCorrect: "
                                  onChange={(e) => setIsCorrect(e.target.value)}
                                >
                                  <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >YES</MenuItem>
                                  <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >NO</MenuItem>
                                </Select>
                            </FormControl>  
                          </Grid>
                          <Grid item xs={3.5}>
                            <FormControl 
                              fullWidth
                              style={{ margin: "1rem 0" }}
                            >
                              <InputLabel id="activityLabel">Activity Status: </InputLabel>
                                <Select
                                  fullWidth
                                  style={{padding: 11}}
                                  labelId="activityLabel"
                                  id="activitySelect"
                                  value={activity}
                                  label="Activity Status: "
                                  onChange={(e) => setActivity(e.target.value)}
                                >
                                  <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
                                  <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
                                </Select>
                              </FormControl>
                          </Grid>
                        </Grid>
                        <Grid 
                          container 
                          justifyContent={'space-evenly'}
                          style={{ marginTop: "20px" }}
                        >
                          <Grid item xs={4}>
                            <MDButton
                              fullWidth
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={testOptionUpdate}
                              // onClick={Updateapi}
                              // disabled={
                              //   !updatedClassName || !updatedMedium
                              //   || !boardName
                              //     ? true
                              //     : false
                              // }
                            >
                              Update
                            </MDButton>
                          </Grid>
                          <Grid item xs={4}>
                            <MDButton
                              fullWidth
                              variant="contained"
                              color="dark"
                              size="small"
                              onClick={handleCloseOptionUpdate}
                            >
                              CLOSE
                            </MDButton>
                          </Grid>
                        </Grid>
                      </Box>
                    </Modal>

                    <Modal
                      open={openMessage}
                      onClose={handleCloseMessage}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style} style={{textAlign: 'center'}}>
                        <Grid item xs={12}>
                          <Typography
                            id="modal-modal-title"
                            variant="h4"
                            component="h2"
                            style={{marginBottom: '2rem',}}
                          >
                            {message}
                          </Typography>
                        </Grid>
                        <Grid 
                          container 
                          justifyContent={'center'}
                          style={{ marginTop: "2rem" }}
                        >
                          <Grid item xs={4}>
                            <MDButton
                              fullWidth
                              variant="contained"
                              color="dark"
                              size="small"
                              onClick={handleCloseMessage}
                            >
                              CLOSE
                            </MDButton>
                          </Grid>
                        </Grid>
                      </Box>
                    </Modal>

                  </MDBox>
                  {testFetched && 
                    <>
                      <Grid container mt={10} justifyContent={'space-evenly'}>
                          <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                              <Grid item xs={3.5}>
                                  Test Name:  
                              </Grid>
                              <Grid item xs={8} style={{textAlign: 'right'}}>
                                  {testName}
                              </Grid>
                          </Grid>
                          <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                              <Grid item xs={5.9}>
                                  No of Questions:  
                              </Grid>
                              <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                  {questionNo}
                              </Grid>
                          </Grid>
                          <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                              <Grid item xs={5.9}>
                                  Total Time: 
                              </Grid>
                              <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                  {totalTime}
                              </Grid>
                          </Grid>
                          <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                              <Grid item xs={3.3}>
                                  Instructions:
                              </Grid>
                              <Grid item xs={8.7} style={{textAlign: 'right'}}>
                                  {instruction}
                              </Grid> 
                          </Grid>
                          <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                              <Grid item xs={5.9}>
                                  Board:  
                              </Grid>
                              <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                  {boardName}
                              </Grid>
                          </Grid>
                          <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                              <Grid item xs={5.9}>
                                  Class:  
                              </Grid>
                              <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                  {className}
                              </Grid>
                          </Grid>
                          <Grid container xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}} justifyContent={'space-between'}>
                              <Grid item xs={5.9}>
                                  Subject: 
                              </Grid>
                              <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                  {subjectName}
                              </Grid>
                          </Grid>
                          <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                              <Grid item xs={5.9}>
                                  Topic:  
                              </Grid>
                              <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                  {topicName}
                              </Grid>
                          </Grid>
                          <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                              <Grid item xs={5.9}>
                                  Total Marks:  
                              </Grid>
                              <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                  {totalMarks}
                              </Grid>
                          </Grid>
                          <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                              <Grid item xs={5.9}>
                                  Test Date:  
                              </Grid>
                              <Grid item xs={5.9} style={{textAlign: 'right'}}>
                                  {testDate}
                              </Grid>
                          </Grid>
                          <Grid container justifyContent={'center'} xs={12} mt={2} p={1}>
                            <Grid item xs={12}>
                              <MDButton
                                fullWidth
                                variant="contained"
                                color="primary"
                                // onClick={detailsChangeHandler}
                                onClick={testDetailsUpdate}
                                // disabled={
                                //   !testName || !questionNo || !classId || !subjectId || !topicId || !testDate || !instruction || !totalMarks || !totalTime ? true : false
                                // }
                              >
                                Update
                              </MDButton>
                            </Grid>                            
                          </Grid>
                      </Grid>
                      <Grid container mt={3} justifyContent={'center'}>
                        <Grid item>
                          {testDetails.QuestionData.map((ques) => {
                            return (<>
                              <Grid container style={{border: '2px solid #1A73E8', borderRadius: '25px', marginTop: '1rem', padding: '1rem'}} key={Math.random().toString()}>
                                <Grid container style={{borderBottom: '1px solid #1A73E8'}}>
                                  <Grid item xs={1.2} style={{fontSize: '1.5rem'}}>
                                    Question {ques.id}:
                                  </Grid>
                                  <Grid item xs={10}>
                                    {ques.question}
                                  </Grid>
                                </Grid>
                                <Grid container style={{borderBottom: '1px  #1A73E8'}} py={1.5} justifyContent={'space-evenly'} alignItems={'center'}>
                                  <Grid item xs={2}>
                                    Marks : {ques.marks}
                                  </Grid>
                                  <Grid item xs={2}>
                                    {<p> Compulsory: {ques.compulsory === 0? <span style={{color: 'red'}}>NO</span>: <span style={{color: 'green'}}>YES</span>}</p>}
                                  </Grid>
                                  <Grid item xs={2}>
                                    {<p> Active: {ques.active === 0? <span style={{color: 'red'}}>NO</span>: <span style={{color: 'green'}}>YES</span>}</p>}
                                  </Grid>
                                  <Grid item>
                                    <img 
                                      src={ques.image} 
                                      style={{ margin: 'auto', width: 70, height: 70 }} 
                                      alt=''
                                    />
                                  </Grid>
                                  <Grid item>
                                    <MDButton
                                      fullWidth
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        console.log('hi');
                                        setQuestionId(ques.question_id)
                                        setOldLogo(ques.image)
                                        setQuestion(ques.question)
                                        setSolDesc(ques.solutionDescription)
                                        setQMarks(ques.marks)
                                        setCompulsory(ques.compulsory)
                                        setActivity(ques.active)
                                        setOpenupdate(true)
                                      }}
                                    >
                                      Update
                                    </MDButton>
                                  </Grid>
                                  <Grid container style={{borderBottom: '1px  #1A73E8'}} py={1.5} justifyContent={'space-evenly'} alignItems={'center'}>
                                    {ques.solutionDescription && 
                                      <Grid item xs={2}>
                                        SOLUTION : {ques.solutionDescription}
                                      </Grid>}
                                  </Grid>
                                </Grid>
                                {ques.optionData.map((opt) => {
                                  return (
                                    <Grid 
                                      container 
                                      style={{borderTop: '1px solid #1A73E8'}} 
                                      py={1.5} 
                                      justifyContent={'space-evenly'}
                                      alignItems={'center'}
                                      onLoad={() => {
                                        setCount(count+1);
                                      }}
                                      key={Math.random().toString()}
                                    >
                                      <Grid item xs={6}>
                                        Option : {opt.option}
                                      </Grid>
                                      <Grid item xs={2}>
                                        {<p> Is Corret: {opt.is_correct === 0? <span style={{color: 'red'}}>NO</span>: <span style={{color: 'green'}}>YES</span>}</p>}
                                      </Grid>
                                      <Grid item>
                                        <img 
                                          src={opt.image} 
                                          style={{ margin: 'auto', width: 70, height: 70 }} 
                                          alt=''
                                        />
                                      </Grid>
                                      <Grid item>
                                        <MDButton
                                          fullWidth
                                          variant="contained"
                                          color="primary"
                                          onClick={() => {
                                            setQuestionId(ques.question_id)
                                            setOptionId(opt.option_id)
                                            setOldLogo(opt.image)
                                            setOption(opt.option)
                                            setIsCorrect(opt.is_correct)
                                            setActivity(opt.active)
                                            setOpenOptionUpdate(true)
                                          }}
                                          // disabled={
                                          //   !testName || !questionNo || !classId || !subjectId || !topicId || !testDate || !instruction || !totalMarks || !totalTime ? true : false
                                          // }
                                        >
                                          Update
                                        </MDButton>
                                      </Grid>
                                      {/* <Grid item xs={2}>
                                        {<p> Active: {opt.active === 0? <span style={{color: 'red'}}>NO</span>: <span style={{color: 'green'}}>YES</span>}</p>}
                                      </Grid> */}
                                    </Grid>
                                    )
                                })}
                              </Grid></>
                            )
                          })
                          }
                        </Grid>
                      </Grid>
                    </>
                      }
                </Card>
              </Grid>
            </Grid>
          </MDBox>}
        {/* <Footer /> */}
      </DashboardLayout>
    );
  }
  
  export default TestDetails;
  