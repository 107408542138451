/**
  =========================================================
  * Material Dashboard 2 React - v2.1.0
  =========================================================

  * Product Page: https://www.creative-tim.com/product/material-dashboard-react
  * Copyright 2022 Creative Tim (https://www.creative-tim.com)

  Coded by www.creative-tim.com

  =========================================================

  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
  */

  import * as React from "react";
  import Box from "@mui/material/Box";
  import Typography from "@mui/material/Typography";
  import Modal from "@mui/material/Modal";
  
  // @mui material components
  import Grid from "@mui/material/Grid";
  import Card from "@mui/material/Card";
  
  // Material Dashboard 2 React components
  import MDBox from "components/MDBox";
  
  // Material Dashboard 2 React example components
  import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
  import DashboardNavbar from "examples/Navbars/DashboardNavbar";
  import MDButton from "components/MDButton";
  import { SpinnerInfinity } from "spinners-react";
  import { useState } from "react";
  import TextField from "@mui/material/TextField";
  // import { useNavigate } from "react-router-dom";
  import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
  import globalURL from "../../config";
  import ListContext from "layouts/apiContext/listContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    borderRadius: '2rem',
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 6,
  };
  
  function Classes() {
  
    const { 
      boardList,
      fetchClassByBoard,
      filteredClassList,
      filteredSubjectList,
      filteredTopicList,
      fetchSubjectByClass,
      fetchTopicBySubject

    } = useContext(ListContext);
  
    // const navigate = useNavigate(); 
    const [ testName, setTestName ] = useState('');
    const [ questionNo, setQuestionNo ] = useState('');
    const [ boardId, setBoardId ] = useState('');
    const [ boardName, setBoardName ] = useState('');
    const [ classId, setClassId ] = useState('');
    const [ className, setClassName ] = useState('');
    const [ subjectId, setSubjectId ] = useState('');
    const [ subjectName, setSubjectName ] = useState('');
    const [ topicId, setTopicId ] = useState('');
    const [ topicName, setTopicName ] = useState('');
    const [ totalMarks, setTotalMarks ] = useState('');
    const [ totalTime, setTotalTime ] = useState('');
    const [ testDate, setTestDate ] = useState(new Date());
    const [ instruction, setInstruction ] = useState('Choose');
    const [ solutionDesc, setSolutionDesc ] = useState('');
    const [ testQuestion, setTestQuestion ] = useState([]);
    const [ question, setQuestion ] = useState('');
    const [ optionsSet, setOptionsSet ] = useState([]);
    const [ option, setOption ] = useState('');
    const [ count, setCount ] = useState(1);
    const [ qCount, setQCount ] = useState(1);
    const [ isCorrect, setIsCorrect ] = useState('Choose');
    const [ isCompulsory, setIsCompulsory ] = useState('Choose');
    const [ marks, setMarks ] = useState('0');
    const [ detailsEntered, setDetailsEntered ] = useState(false);
    const [ optionImage, setOptionImage ] = useState('');
    const [ questionImage, setQuestionImage ] = useState('');
    // const [ testEntered, setTestEntered ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const navigate = useNavigate();
    const [ file, setFile ] = useState(null)
    const [ fileName, setFileName ] = useState(null)

    const fileToBase64 = (file, cb) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        cb(null, reader.result)
      }
      reader.onerror = function (error) {
        cb(error, null)
      }
    }
  
    const onUploadFileChange = ({ target }) => {
      if (target.files < 1 || !target.validity.valid) {
        return
      }
      fileToBase64(target.files[0], (err, result) => {
        if (result) {
          setFile(result)
          setFileName(target.files[0])
        }
      })
    }

    const createTest = async () => {
      setLoading(true);
      handleClose();
      async function getUserToken() {
        return await localStorage.getItem("userInfo");
      }
      getUserToken().then((userToken) => {
      const requestOptionsSet = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
          // 'Authorization': 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1c2VyX2lkIjoiMSIsImVtYWlsIjoic2hpdmtyOTgxODhAZ21haWwuY29tIiwibG9naW5UaW1lIjoxNjUxNTcyODIwOTYwLCJnZW5lcmF0ZWRfYXQiOjE2NTE1NzI4MjA5NjAsImlhdCI6MTY1MTU3MjgyMCwiZXhwIjoxNjgzMTMwNDIwLCJpc3MiOiJSZWNydWl0In0.T4fHdvweSB4iFL7oXrKXiIh9u6IHvXQtVUfX-mafNZ3Gl3hJyC7AyXSCPgin_r_FRKz2nHnFe5a6epsbM_Idqw',
        },
        body: JSON.stringify({
          test_name: testName,
          no_question: questionNo,
          total_time: (parseInt(totalTime)*60).toString(),
          instruction: instruction,
          subject_id: subjectId,
          board_id: boardId,
          class_id: classId,
          topic_id: topicId,
          total_mark: totalMarks,
          test_date: testDate,
          test_question: testQuestion
        })
      }
        fetch(
        globalURL + "test/create_test",
        requestOptionsSet
      )
        .then((res) => res.json())
        .then((res) => {
          if( res.message === "Success") {
            setLoading(false);
            navigate('/testlist');
          }
          else {
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log(e)
          setLoading(false);
        })
      })
    };
  
    const topicFilterHandler = (event) => {
      setTopicId(event.target.value)
    }

    const optionAddHandler = () => {
      setOptionsSet([...optionsSet, { 'option': option, 'option_image': optionImage.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), 'is_correct': isCorrect}]);
      setOption('');
      setOptionImage('');
      setCount(count + 1);
      setIsCorrect('Choose');
    }
    
    const finalOptionHandler = () => {
      setTestQuestion([...testQuestion, {'count': qCount, 'question': question, 'question_image': questionImage.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), 'compulsory': isCompulsory, 'marks': marks, 'solutionDescription': file, 'option' : optionsSet}]);
      setTestQuestion([...testQuestion, { 'question': question, 'question_image': questionImage.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''), 'compulsory': isCompulsory, 'marks': marks, 'solutionDescription': file, 'option' : optionsSet}]);
      setOptionsSet([]);
      setSolutionDesc('')
      setQCount(qCount + 1);
      setFile("");
      setOptionImage('');
      setQuestionImage('');
      setQuestion('');
      setMarks('');
      setOption('');
      setCount(1);
      setIsCompulsory(1);
      setIsCorrect(0);
    }
    
    // const addAllHandler = () => {
    //   setQuestion('');
    //   setMarks('');
    //   setOption('');
    //   setOptionImage('');
    //   setSolutionDesc('')
    //   setQuestionImage('');
    //   setCount(1);
    //   setIsCompulsory(1);
    //   setIsCorrect(0);
    //   setQCount(1);
    //   // setTestEntered(true);
    // }
  
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const detailsChangeHandler = () => {
      setDetailsEntered(true);
      setOpen(false);
    }
    const handleClose = () => setOpen(false);

    const uploadImage = async (image) => {
      
      const file = image.target.files[0]
      const base64 = await convertBase64(file)
      setQuestionImage(base64);
  
    }

    const uploadOptionImg = async (image) => {
      
      const file = image.target.files[0]
      const base64 = await convertBase64(file)
      setOptionImage(base64);
  
    }
  
    const convertBase64 = (file) => {
  
      return new Promise((resolve, reject) => {
  
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
  
        fileReader.onload = (() => {
          resolve(fileReader.result);
        });
  
        fileReader.onerror = ((error) => {
          reject(error);
        });
  
      });
  
    }
  
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
            {loading ? 
              <Grid container justifyContent={'center'}height={'80vh'}>
                <SpinnerInfinity 
                  size={190} 
                  thickness={180} 
                  speed={100} 
                  color="rgba(59, 172, 57, 1)" 
                  secondaryColor="rgba(57, 145, 172, 0.44)" 
                />
              </Grid> :
              <Card style={{padding: 30}}>
                <MDBox
                  mx={2}
                  mt={-7}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="warning"
                  borderRadius="lg"
                  coloredShadow="warning"
                >
                  {/* <div> */}
                  <MDButton
                    variant="contained"
                    color="white"
                    size="small"
                    onClick={handleOpen}
                  >
                    Enter Test Details
                  </MDButton>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h3"
                        component="h2"
                      >
                        Enter Test Details
                      </Typography>
                      <Grid container mb={2} justifyContent={'space-between'}>
                        <Grid item xs={3.5}>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label="TEST NAME"
                            variant="outlined"
                            value={testName}
                            style={{ margin: "2rem auto" }}
                            onChange={(e) => setTestName(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={3.5}>
                          <TextField
                            fullWidth
                            type='number'
                            id="outlined-basic"
                            label="QUESTION NO"
                            variant="outlined"
                            value={questionNo}
                            style={{ margin: "2rem auto" }}
                            onChange={(e) => setQuestionNo(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={3.5}>
                          <FormControl 
                            fullWidth
                            style={{ margin: "2rem auto" }}
                          >
                            <InputLabel id="demo-simple-select-label">BOARD</InputLabel>
                            <Select
                              style={{padding: 11}}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={boardId}
                              label="BOARD"
                              onChange={(e) => {
                                setBoardId(e.target.value)
                                fetchClassByBoard(e.target.value)
                              }}
                            >
                              {boardList?.map((brd) => {
                                if( brd.active === 1 ) return  <MenuItem value={brd.board_id} key={Math.random().toString()} onClick={() => setBoardName(brd.board_name)}>{brd.board_name}</MenuItem>        
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3.5}>
                          <FormControl 
                            fullWidth
                            style={{ marginBottom: "1rem" }}
                          >
                            <InputLabel id="demo-simple-select-label">CLASS</InputLabel>
                            <Select
                              style={{padding: 10}}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={classId}
                              label="CLASS"
                              onChange={(e) => {
                                setClassId(e.target.value)
                                fetchSubjectByClass(e.target.value)
                              }}
                            >
                              {filteredClassList.map((cls) => {
                                if( cls.active === 1 ) return <MenuItem value={cls.class_id} key={cls.class_id} onClick={() => setClassName(cls.class_name)}>{cls.class_name}</MenuItem>
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3.5}>
                          <FormControl fullWidth>
                            <InputLabel id="subject-select-label">SUBJECT</InputLabel>
                            <Select
                              style={{padding: 10}}
                              labelId="subject-select-label"
                              id="subject-select"
                              value={subjectId}
                              label="SUBJECT"
                              onChange={(e) => {
                                fetchTopicBySubject(e.target.value);
                                setSubjectId(e.target.value);
                              
                              }}
                            >
                              {filteredSubjectList.map((sub) => {
                                if( sub.active === 1 ) return <MenuItem value={sub.subject_id} key={sub.subject_id} onClick={() => setSubjectName(sub.subject)}>{sub.subject}</MenuItem>
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3.5}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">TOPIC</InputLabel>
                          <Select
                            style={{padding: 10}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={topicId}
                            label="TOPIC"
                            onChange={topicFilterHandler}
                          >
                            {filteredTopicList.map((tpc) => {
                              if( tpc.active === 1 ) return <MenuItem value={tpc.topic_id} key={tpc.topic_id} onClick={() => setTopicName(tpc.topic)}>{tpc.topic}</MenuItem>
                            })}
                          </Select>
                        </FormControl>
                        </Grid>
                        <Grid item xs={3.5}>
                          <TextField
                            fullWidth
                            style={{ margin: "  1rem auto" }}
                            id="date"
                            label="TEST DATE"
                            type="date"
                            value={testDate}
                            onChange={(e) => setTestDate(e.target.value)}
                            // sx={{ width: 220 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3.5}>
                        <FormControl 
                          fullWidth
                          style={{ margin: "1rem auto" }}
                        >
                          <InputLabel id="demo-simple-select-label">SET INSTRUCTIONS</InputLabel>
                          <Select
                            style={{padding: 11}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={instruction}
                            label="SET INSTRUCTIONS"
                            onChange={(e) => setInstruction(e.target.value)}
                          >
                            <MenuItem value={'Choose'}>Choose</MenuItem>
                            <MenuItem value={'YES'}>YES</MenuItem>
                            <MenuItem value={'NO'}>NO</MenuItem>
                          </Select>
                        </FormControl>
                        </Grid>
                        <Grid item xs={3.5}>
                          <TextField
                            fullWidth
                            type='number'
                            id="outlined-basic"
                            label="TOTAL MARKS"
                            variant="outlined"
                            value={totalMarks}
                            style={{ margin: "1rem auto" }}
                            onChange={(e) => setTotalMarks(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={3.5}>
                          <TextField
                            fullWidth
                            type='number'
                            id="outlined-basic"
                            label="TOTAL TIME (in mins)"
                            variant="outlined"
                            value={totalTime}
                            style={{ margin: "1rem auto" }}
                            onChange={(e) => setTotalTime(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container justifyContent={'space-evenly'}>
                        <Grid item xs={4}>
                          <MDButton
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={detailsChangeHandler}
                            disabled={
                              !testName || !questionNo || !classId || !subjectId || !topicId || !testDate || !instruction || !totalMarks || !totalTime ? true : false
                            }
                          >
                            SAVE
                          </MDButton>

                        </Grid>
                        <Grid item xs={4}>
                          <MDButton
                            fullWidth
                            variant="contained"
                            color="dark"
                            size="small"
                            onClick={handleClose}
                          >
                            CLOSE
                          </MDButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </Modal>
                </MDBox>
                {!detailsEntered? '' : <>
                <Grid container mt={10} justifyContent={'space-between'}>
                  <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                      <Grid item xs={3.5}>
                          Test Name:  
                      </Grid>
                      <Grid item xs={8} style={{textAlign: 'right'}}>
                          {testName}
                      </Grid>
                  </Grid>
                  <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                      <Grid item xs={5.9}>
                          No of Questions:  
                      </Grid>
                      <Grid item xs={5.9} style={{textAlign: 'right'}}>
                          {questionNo}
                      </Grid>
                  </Grid>
                  <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                      <Grid item xs={5.9}>
                          Total Time: 
                      </Grid>
                      <Grid item xs={5.9} style={{textAlign: 'right'}}>
                          {totalTime}
                      </Grid>
                  </Grid>
                  <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                      <Grid item xs={3.3}>
                          Instructions:
                      </Grid>
                      <Grid item xs={8.5} style={{textAlign: 'right'}}>
                          {instruction}
                      </Grid> 
                  </Grid>
                  <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                      <Grid item xs={5.9}>
                          Board:  
                      </Grid>
                      <Grid item xs={5.9} style={{textAlign: 'right'}}>
                          {boardName}
                      </Grid>
                  </Grid>
                  <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                      <Grid item xs={5.9}>
                          Class:  
                      </Grid>
                      <Grid item xs={5.9} style={{textAlign: 'right'}}>
                          {className}
                      </Grid>
                  </Grid>
                  <Grid container xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}} justifyContent={'space-between'}>
                      <Grid item xs={5.9}>
                          Subject: 
                      </Grid>
                      <Grid item xs={5.9} style={{textAlign: 'right'}}>
                          {subjectName}
                      </Grid>
                  </Grid>
                  <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                      <Grid item xs={5.9}>
                          Topic:  
                      </Grid>
                      <Grid item xs={5.9} style={{textAlign: 'right'}}>
                          {topicName}
                      </Grid>
                  </Grid>
                  <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                      <Grid item xs={5.9}>
                          Total Marks:  
                      </Grid>
                      <Grid item xs={5.9} style={{textAlign: 'right'}}>
                          {totalMarks}
                      </Grid>
                  </Grid>
                  <Grid container justifyContent={'space-between'} xs={3.5} mt={2} p={1} style={{border: '2px solid #1A73E8', borderRadius: '20px'}}>
                      <Grid item xs={5.9}>
                          Test Date:  
                      </Grid>
                      <Grid item xs={5.9} style={{textAlign: 'right'}}>
                          {testDate}
                      </Grid>
                  </Grid>
                </Grid>
                <Grid container justifyContent={'space-between'}>
                <Grid item xs={1.8} mt={5} style={{textAlign: 'center'}}>
                    <Grid item xs={12}>
                      Question Image:
                    </Grid>
                    <Grid item xs={12}>
                      {!questionImage? 
                      <input
                        style={{width: '100%'}}
                        id='logo'
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={(image) => uploadImage(image)}
                      /> : 
                      <img
                        src={questionImage}
                        style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                        alt=''
                      />                      
                      }
                    </Grid>
                    </Grid>
                  <Grid item xs={8} justifyContent={'center'}>
                    <Grid item xs={12} mt={5} style={{textAlign: 'center'}}>
                      Enter Question:
                    </Grid>
                    <Grid container>
                      <Grid item xs={9}>
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label={`Q. ${qCount}`}
                            variant="outlined"
                            value={question}
                            style={{ marginTop: "2rem" }}
                            onChange={(e) => setQuestion(e.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={1}>
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            type='number'
                            id="outlined-basic"
                            label='Marks'
                            variant="outlined"
                            value={marks}
                            style={{ marginTop: "2rem" }}
                            onChange={(e) => setMarks(e.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl 
                          fullWidth
                          style={{ marginTop: "2rem" }}
                        >
                          <InputLabel id="demo-simple-select-label">Is Compulsory?</InputLabel>
                          <Select
                            style={{padding: 12}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={isCompulsory}
                            label="Is Compulsory?"
                            onChange={(e) => setIsCompulsory(e.target.value)}
                          >
                            <MenuItem value={'Choose'}>Choose</MenuItem>
                            <MenuItem value={1}>YES</MenuItem>
                            <MenuItem value={0}>NO</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label={`Option: ${count}`}
                          variant="outlined"
                          value={option}
                          style={{ margin: "0.5rem auto" }}
                          onChange={(e) => setOption(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl 
                          fullWidth
                          style={{ margin: "0.5rem auto" }}
                        >
                          <InputLabel id="demo-simple-select-label">Is Correct?</InputLabel>
                          <Select
                            style={{padding: 12}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={isCorrect}
                            label="Is Correct?"
                            onChange={(e) => setIsCorrect(e.target.value)}
                          >
                            <MenuItem value={'Choose'}>Choose</MenuItem>
                            <MenuItem value={1}>YES</MenuItem>
                            <MenuItem value={0}>NO</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container xs={12} justifyContent={'space-evenly'}>
                      <Grid item xs={3}>
                        <Button
                          fullWidth
                          variant='contained'
                          style={{color: '#ffffff'}}
                          onClick={optionAddHandler}
                          disabled={!option ? true : false}
                        >
                          Add Option
                        </Button>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          fullWidth
                          variant='contained'
                          style={{color: '#ffffff'}}
                          onClick={optionAddHandler}
                          disabled={!option ? true : false}
                        >
                          Final Option
                        </Button>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          fullWidth
                          variant='contained'
                          style={{color: '#ffffff'}}
                          onClick={finalOptionHandler}
                          disabled={!question ? true : false}
                        >
                          Next Question || Done
                        </Button>
                      </Grid>
                    </Grid>
                    {/* <Grid container justifyContent={'center'}>
                      <Grid item xs={2} mt={2}>
                        <Button
                          fullWidth
                          variant='contained'
                          style={{background: '#48A509', color: '#ffffff'}}
                          onClick={addAllHandler}
                          // onClick={createTest}
                        >
                          View Test
                        </Button>
                      </Grid>
                    </Grid> */}
                  </Grid>
                  <Grid item xs={1.8} mt={5} style={{textAlign: 'center'}}>
                    <Grid item xs={12}>
                      Option Image:
                    </Grid>
                    <Grid item xs={12}>
                      {!optionImage? 
                      <input
                        style={{width: '100%'}}
                        id='logo'
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={(image) => uploadOptionImg(image)}
                      /> : 
                      <img
                        src={optionImage}
                        style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                        alt=''
                      />                      
                      }
                    </Grid>
                  </Grid>
                  <div className="upload-area">
                    { fileName && <p className="filename">{fileName.name}</p> }
                    <input type="file" name="filetobase64" onChange={onUploadFileChange} accept="application/pdf" />
                  </div>
                </Grid>
                <Grid container justifyContent={'center'}>
                  <Grid container justifyContent={'center'} alignItems={'center'}>
                    {/* {testEntered? '' :  */}
                      <Grid container justifyContent={'center'}>
                        <Grid item xs={8} mt={5} style={{textAlign:'center'}}>
                          <h3>Your Test Details</h3>
                        </Grid>
                        <Grid item xs={10}>
                          {testQuestion?.map((ques) => {
                            return (
                            <Grid container key={Math.random().toString()}>  
                              <Grid item xs={10} mt={5}>
                                <h4>Question No. {ques.count}: {ques.question} ||| Is Compulsory: {ques.compulsory} ||| Marks: {ques.marks}</h4>
                              </Grid>
                              <Grid item mt={1}>
                                {ques?.option.map((opt) => {
                                  return (<h5 key={Math.random().toString()}>Option No. {opt.count}: {opt.option} ||| Is Correct: {opt.is_correct}</h5>)
                                })}
                              </Grid>
                            </Grid>)
                          })}
                        </Grid>
                        <Grid item xs={3}>
                          <Button 
                            fullWidth 
                            variant='contained' 
                            style={{color: '#ffffff'}} 
                            onClick={createTest}
                            disabled={!testQuestion ? true : false}
                          >
                            Submit test
                          </Button>
                        </Grid>
                      </Grid>
                    {/* } */}
                  </Grid>
                </Grid>
                </>}
              </Card>}
            </Grid>
          </Grid>
        </MDBox>
        {/* <Footer /> */}
      </DashboardLayout>
    );
  }
  
  export default Classes;
  