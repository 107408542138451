/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { useContext, useEffect, useState } from "react";
import { TopicData } from "./data/TopicData";
import tw from "twin.macro";
import MDButton from "components/MDButton";
import { Box, FormControl, FormGroup, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import { SpinnerInfinity } from "spinners-react";

import globalURL from "../../config";
import '../../index.css'
import ListContext from "layouts/apiContext/listContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: '2rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AppContainer = tw.div`
  w-full
  max-w-full
  flex
  flex-col
  items-stretch
  justify-center
  pt-6
  pb-10
  pl-10
  pr-10
`;

function Tables() {

  const {
    filteredClassList,
    fetchClassByBoard,
    subjectList,
    topicList,
    boardList,
    fetchTopicList,
    filteredSubjectList,
    fetchSubjectByClass
  } = useContext(ListContext);

  const [ baseImage, setBaseImage ] = useState('');
  const [ oldImage, setOldImage ] = useState('');
  const [ className, setClassName ] = useState('');
  const [ subjectName, setSubjectName ] = useState("");
  const [ topicName, setTopicName ] = useState('');
  const [ topic_defination, setTopicDefination ] = useState('');
  const [ updatedClassId, setUpdatedClassId ] = useState("");
  const [ updatedSubjectId, setUpdatedSubjectId ] = useState("");
  const [ updatedTopicId, setUpdatedTopicId ] = useState("");
  const [ activity, setActivity ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const [ board, setBoard ] = useState('')
  const [index, setIndex] = useState(null);
    const [lock, setLock] = useState(null);

  const [updateIndex, setUpdateIndex] = useState(null);
    const [updateLock, setUpdateLock] = useState(null);

  useEffect(() => fetchTopicList, [])

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setBaseImage('');
    setClassName('');
    setSubjectName('');
    setTopicName('');
  };
  const [errorSB, setErrorSB] = useState(false);

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [openupdate, setOpenupdate] = useState(false);
  const handleCloseupdate = () => {
    setOpenupdate(false)
    setOldImage('');
    setBaseImage('');
    setTopicName('');
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => {
    setOpenDelete(false)
    setTopicName('');
  };

  const [openNone, setOpenNone] = useState(false);
  const handleCloseNone = () => {
    setOpenNone(false)
  };

  const [ message, setMessage ] = useState('');
  const [ openMessage, setOpenMessage ] = useState(false);
  const handleCloseMessage = () => setOpenMessage(false);

  const uploadImage = async (image) => {
    
    const file = image.target.files[0]
    const base64 = await convertBase64(file)
    setBaseImage(base64);

  }

  const convertBase64 = (file) => {

    return new Promise((resolve, reject) => {

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = (() => {
        resolve(fileReader.result);
      });

      fileReader.onerror = ((error) => {
        reject(error);
      });

    });

  }

  const createTopic = async () => {
    setLoading(true);
    handleClose();
    async function getUserToken() {
      return localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
        // Authorization: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1c2VyX2lkIjoiMSIsImVtYWlsIjoic2hpdmtyOTgxODhAZ21haWwuY29tIiwibG9naW5UaW1lIjoxNjUxNTcyODIwOTYwLCJnZW5lcmF0ZWRfYXQiOjE2NTE1NzI4MjA5NjAsImlhdCI6MTY1MTU3MjgyMCwiZXhwIjoxNjgzMTMwNDIwLCJpc3MiOiJSZWNydWl0In0.T4fHdvweSB4iFL7oXrKXiIh9u6IHvXQtVUfX-mafNZ3Gl3hJyC7AyXSCPgin_r_FRKz2nHnFe5a6epsbM_Idqw'
      },
      body: JSON.stringify({
        board_id: board,
        subject_id: subjectName,
        topic: topicName,
        topic_defination,
        index: index,
        lock:lock,
        class_id: className,
        logo: baseImage.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '')
      }),
    };
    fetch(
      globalURL + "topic/create_topic",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          handleClose();
          setSubjectName('');
          setMessage(res.message);
          setOpenMessage(true);
          setClassName('');
          fetchTopicList();
          setTopicName('');
          setBaseImage('');
          setTopicDefination("")
          setBoard('');
          openSuccessSB();
          setLoading(false);
        } else {
          setMessage(res.message);
          setOpenMessage(true);
          handleClose();
          setBoard('');
          setTopicDefination("")
          fetchTopicList();
          setLoading(false);
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
        setBoard('');
      });
    })
  };
  
  const Updateapi = async () => {
    setLoading(true);
    handleCloseupdate();
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
        // Authorization: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1c2VyX2lkIjoiMSIsImVtYWlsIjoic2hpdmtyOTgxODhAZ21haWwuY29tIiwibG9naW5UaW1lIjoxNjUxNTcyODIwOTYwLCJnZW5lcmF0ZWRfYXQiOjE2NTE1NzI4MjA5NjAsImlhdCI6MTY1MTU3MjgyMCwiZXhwIjoxNjgzMTMwNDIwLCJpc3MiOiJSZWNydWl0In0.T4fHdvweSB4iFL7oXrKXiIh9u6IHvXQtVUfX-mafNZ3Gl3hJyC7AyXSCPgin_r_FRKz2nHnFe5a6epsbM_Idqw'
      },
      
      body: JSON.stringify({
        class_id: updatedClassId,
        subject_id: updatedSubjectId,
        topic_id: updatedTopicId,
        topic: topicName,
        active: activity,
        board_id: board,
        index:updateIndex,
        lock:updateLock,
        topic_defination,
        "logo": baseImage.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''),
      }),
    };
    fetch(
      globalURL + "topic/update_topic",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          handleCloseupdate();
          fetchTopicList();
          setTopicName("");
          setUpdatedClassId('');
          setTopicDefination("")
          setUpdatedSubjectId('');
          setLoading(false);
          setUpdateIndex(null);
          setUpdateLock(null);
          setBoard('');
          setMessage(res.message);
          setOpenMessage(true);
          setUpdatedTopicId("");
          setBaseImage("");
          openSuccessSB();
        } else {
          setMessage(res.message);
          setOpenMessage(true);
          setTopicDefination("")
          setLoading(false);
          handleCloseupdate();
          setUpdateIndex(null);
          setUpdateLock(null);
          fetchTopicList();
          openErrorSB();
          setBoard('');
        }
      })
      .catch((e) => {
        console.log(e);
        setBoard('');
      });
    })
  };

  const deleteTopic = async () => {
    setLoading(true);
    handleCloseDelete();
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
        // Authorization: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1c2VyX2lkIjoiMSIsImVtYWlsIjoic2hpdmtyOTgxODhAZ21haWwuY29tIiwibG9naW5UaW1lIjoxNjUxNTcyODIwOTYwLCJnZW5lcmF0ZWRfYXQiOjE2NTE1NzI4MjA5NjAsImlhdCI6MTY1MTU3MjgyMCwiZXhwIjoxNjgzMTMwNDIwLCJpc3MiOiJSZWNydWl0In0.T4fHdvweSB4iFL7oXrKXiIh9u6IHvXQtVUfX-mafNZ3Gl3hJyC7AyXSCPgin_r_FRKz2nHnFe5a6epsbM_Idqw'
      },
      body: JSON.stringify({
        topic_id: updatedTopicId,
      }),
    };
    fetch(
      globalURL + "topic/delete_topic",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          handleCloseDelete();
          fetchTopicList();
          setLoading(false);
          setUpdatedTopicId("");
          setTopicName("");
          setMessage(res.message);
          setOpenMessage(true);
          openSuccessSB();
        } else {
          setUpdatedTopicId("");
          setMessage(res.message);
          setLoading(false);
          setOpenMessage(true);
          fetchTopicList();
          setTopicName("");
          handleCloseDelete();
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    })
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Topic Updated Successfully"
      content="Your Topic is now updated successfully"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content="Getting Error while updating Topic"
      dateTime="Just Now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
              <MDButton
                  variant="contained"
                  size="small"
                  onClick={handleOpen}
                  className={'text-[#000000] bg-white'}
              >
                Create new topic
              </MDButton>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h3"
                    component="h2"
                  >
                    Create New Topic
                  </Typography>
                  <Grid container justifyContent={'center'}>
                    <Grid item xs={4} mb={2} alignContent={'center'}>
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={(image) => uploadImage(image)}
                      />
                    </Grid>
                    <Grid item xs={11} justifyContent={'center'}>
                      {!baseImage? null : <img 
                        src={baseImage} 
                        style={{display: 'flex' , margin: 'auto', height: '13rem' , width: '13rem'}} 
                        alt=''
                      />}
                    </Grid>
                  </Grid>
                    <FormControl fullWidth style={{margin: '1rem 0'}} >
                      <InputLabel id="board-label">Board</InputLabel>
                      <Select
                        value={board}
                        name="board"
                        label="Board"
                        labelId="board-label"
                        style={{padding: 10}}
                        onChange={(e) => {
                          setBoard(e.target.value)
                          fetchClassByBoard(e.target.value)
                        }}
                      >
                        <MenuItem value={'ALL'} key={Math.random().toString()} >ALL</MenuItem>
                        {boardList?.map((brd) => {

                          if( brd.active === 1 ) return  <MenuItem value={brd.board_id} key={Math.random().toString()} >{brd.board_name}</MenuItem>

                        })}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth style={{margin: '1rem 0'}} >
                      <FormGroup>
                        <InputLabel id="classSelectLabel">CLASS</InputLabel>
                        <Select
                          style={{padding: 10}}
                          labelId="classSelectLabel"
                          id="classSelect"
                          label="CLASS"
                          value={className}
                          onChange={(e) => {
                            setClassName(e.target.value)
                            fetchSubjectByClass(e.target.value)
                          }}
                        >
                          {filteredClassList.map((cls) => {
                            if (cls.active === 1) return <MenuItem value={cls.class_id} key={cls.class_id}>{cls.class_name}</MenuItem>
                          })}
                        </Select>
                      </FormGroup>
                    </FormControl>
                    <FormControl fullWidth style={{margin: '1rem 0'}} >
                      <InputLabel id="subject-label">Subject</InputLabel>
                      <Select
                        value={subjectName}
                        name="subject"
                        label="Subject"
                        labelId="subject-label"
                        style={{padding: 10}}
                        onChange={(e) => setSubjectName(e.target.value)}
                      >
                        {filteredSubjectList?.map((sub) => {

                          if (sub.active === 1) return  <MenuItem value={sub.subject_id} key={Math.random().toString()} >{sub.subject}</MenuItem>

                        })}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="TOPIC NAME"
                      variant="outlined"
                      value={topicName}
                      onChange={(e) => setTopicName(e.target.value)}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="TOPIC DEFINATION"
                      variant="outlined"
                      value={topic_defination}
                      onChange={(e) => setTopicDefination(e.target.value)}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="INDEX"
                      variant="outlined"
                      value={index}
                      onChange={(e) => setIndex(e.target.value)}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="LOCK"
                      variant="outlined"
                      value={lock}
                      onChange={(e) => setLock(e.target.value)}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                    <Grid container justifyContent={'space-evenly'} mt={4} >
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="medium"
                          onClick={createTopic}
                          disabled={
                            !subjectName || !className || !topicName || !index || !topic_defination ? true : false
                          }
                        >
                          SEND
                        </MDButton>
                      </Grid>
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="dark"
                          size="medium"
                          onClick={handleClose}
                        >
                          CLOSE
                        </MDButton>
                      </Grid>
                    </Grid>
                </Box>
              </Modal>

              <Modal
                open={openupdate}
                onClose={handleCloseupdate}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h3"
                    component="h2"
                  >
                    Update Your Topic
                  </Typography>
                  <Grid container justifyContent={'space-between'}>
                    <Grid item xs={5} justifyContent={'center'}>
                      <Grid item mb={2}>
                        <input
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={(image) => uploadImage(image)}
                        />
                      </Grid>
                      <Grid item xs={11} justifyContent={'center'}>
                        {!baseImage? null : <img 
                          src={baseImage} 
                          style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                          alt=''
                        />}
                      </Grid>
                    </Grid>
                    <Grid item xs={5} justifyContent={'center'}>
                      <Grid item xs={11} style={{textAlign: 'center'}} pt={1} mb={1.7}>
                        <Typography
                          id="modal-modal-title"
                          variant="h4"
                          component="h2"
                        >
                          Old Image
                        </Typography>
                      </Grid>
                      <Grid item xs={11} justifyContent={'center'}>
                        <img 
                          src={oldImage} 
                          style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                          alt=''
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={'space-around'}>
                  <Grid item xs={5.5}>  
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Index"
                        variant="outlined"
                        value={updateIndex}
                        onChange={(e) => setUpdateIndex(e.target.value)}
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                      />
                    </Grid>
                    <Grid item xs={5.5}>  
                      <Select
                        fullWidth
                        id="outlined-basic"
                        label="Lock topic"
                        variant="outlined"
                        value={updateLock}
                        onChange={(e) => setUpdateLock(e.target.value)}
                        style={{ marginTop: "20px", marginBottom: "20px" ,padding:'10px' }}
                      >
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>false</MenuItem>
                      </Select>
                      
                    </Grid>
                    <Grid item xs={5.5} mt={1}>
                      <InputLabel id="board-label">Board</InputLabel>
                      <Select
                        fullWidth
                        style={{padding: 11}}
                        value={board}
                        name="board"
                        label="Board"
                        labelId="board-label"
                        onChange={(e) => setBoard(e.target.value)}
                      >
                        <MenuItem value={'ALL'} key={Math.random().toString()} >ALL</MenuItem>
                        {boardList?.map((brd) => {

                          if( brd.active === 1 ) return  <MenuItem value={brd.board_id} key={Math.random().toString()} >{brd.board_name}</MenuItem>

                        })}
                      </Select>
                    </Grid>
                    <Grid item xs={5.5}>
                      <FormControl fullWidth style={{marginBottom: '2rem'}}>
                        <TextField
                          id="outlined-basic"
                          label="TOPIC NAME"
                          variant="outlined"
                          value={topicName}
                          style={{ marginTop: "20px" }}
                          onChange={(e) => setTopicName(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={5.5}>  
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="TOPIC DEFINATION"
                        variant="outlined"
                        value={topic_defination}
                        onChange={(e) => setTopicDefination(e.target.value)}
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                      />
                    </Grid>
                    <Grid item xs={5.5} mb={2}>
                      <InputLabel id="activityLabel">Activity Status: </InputLabel>
                      <Select
                        fullWidth
                        style={{padding: 11}}
                        labelId="activityLabel"
                        id="activitySelect"
                        value={activity}
                        label="Activity Status: "
                        onChange={(e) => setActivity(e.target.value)}
                      >
                        <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
                        <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={'space-evenly'}>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={Updateapi}
                        disabled={
                          !topicName
                            ? true
                            : false
                        }
                      >
                        Update
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="medium"
                        onClick={handleCloseupdate}
                      >
                        CLOSE
                      </MDButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: 'center'}}>
                  <Typography
                    id="modal-modal-title"
                    variant="h3"
                    component="h2"
                    style={{marginBottom: '2rem'}}
                  >
                    Are You Sure You Want To Delete This Topic?
                  </Typography>
                  {topicName}
                  <Grid 
                    container 
                    justifyContent={'space-evenly'}
                    style={{ marginTop: "2rem" }}
                  >
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={deleteTopic}
                      >
                        Delete
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="small"
                        onClick={handleCloseDelete}
                      >
                        CLOSE
                      </MDButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openNone}
                onClose={handleCloseNone}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: 'center'}}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                    style={{marginBottom: '2rem'}}
                  >
                    No Data Available!!
                  </Typography>
                  <Grid 
                    container 
                    justifyContent={'center'}
                    style={{ marginTop: "2rem" }}
                  >
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="small"
                        onClick={handleCloseNone}
                      >
                        CLOSE
                      </MDButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openMessage}
                onClose={handleCloseMessage}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: 'center'}}>
                  <Grid item xs={12}>
                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h2"
                      style={{marginBottom: '2rem',}}
                    >
                      {message}
                    </Typography>
                  </Grid>
                  <Grid 
                    container 
                    justifyContent={'center'}
                    style={{ marginTop: "2rem" }}
                  >
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="small"
                        onClick={handleCloseMessage}
                      >
                        CLOSE
                      </MDButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              {renderSuccessSB}
              {renderErrorSB}
              </MDBox>
              <MDBox pt={3} style={{height: '44rem', overflow: 'auto'}}>
                {loading ? 
                  <Grid container justifyContent={'center'}height={'80vh'}>
                    <SpinnerInfinity 
                      size={190} 
                      thickness={180} 
                      speed={100} 
                      color="rgba(59, 172, 57, 1)" 
                      secondaryColor="rgba(57, 145, 172, 0.44)" 
                    />
                  </Grid> :
                  <AppContainer>
                    <TopicData 
                      setTopicName={setTopicName}
                      setOldImage={setOldImage}
                      setUpdatedClassId={setUpdatedClassId}
                      setUpdatedSubjectId={setUpdatedSubjectId}
                      setUpdatedTopicId={setUpdatedTopicId}
                      setActivity={setActivity}
                      setIndex={setIndex}
                      setLock = {setLock}
                      setTopicDefination = {setTopicDefination}
                      setUpdateIndex={setUpdateIndex}
                      setUpdateLock = {setUpdateLock}
                      setOpenupdate={setOpenupdate}
                      subjectList={subjectList}
                      setBoard={setBoard}
                      setOpenDelete={setOpenDelete}
                      setOpenNone={setOpenNone}
                      topicList={topicList}
                    />
                  </AppContainer>
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;