import { FormControl, Grid, InputLabel, Menu, MenuItem, Select, TextField } from "@mui/material";
import moment from "moment";
import { CSVLink } from "react-csv";
import React, { useEffect, useMemo, useState } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import tw from "twin.macro";
import { GlobalFilter } from "./GlobalFilter";
import '../../../index.css'
import ListContext from "layouts/apiContext/listContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const Table = tw.table`
  table-fixed
  text-base
  text-gray-900
`;

const TableHead = tw.thead`
  p-2
  bg-[#4CAF50]
`;

const TableRow = tw.tr`
  text-center
  border
  border-blue-500
`;

const TableHeader = tw.th`
  border
  border-blue-500
  bg-[#4CAF50]
  p-2
`;

const TableBody = tw.tbody`

`;

const TableData = tw.td`
  border
  border-blue-500
  p-5
`;

const Button = tw.button`
  pl-4
  pr-4
  pt-2
  pb-2
  text-white
  rounded-md
  bg-[#4CAF50]
  hover:bg-blue-300
  hover:cursor-pointer
  transition-colors
`;

export function UserData(props) {
  
  const { 
    boardList,
    fetchClassByBoard,
    filteredClassList,
  } = useContext(ListContext);

  const navigate = useNavigate();

  const [users, setusers] = useState([]);
  const [ filterByInput, setFilterByInput ] = useState('');
  const [ filterCategory, setFilterCategory ] = useState('All');
  const [ filterCategoryList, setFilterCategoryList ] = useState([]);
  const [creationFrom, setCreationFrom] = useState(new Date());
  const [creationTo, setCreationTo] = useState(new Date());
  const [userActivity, setUserActivity] = useState('');
  const [ csvReport, setCsvReport ] = useState({});
  const [ userBoard, setUserBoard ] = useState('');
  const [ userClass, setUserClass] = useState('');

  useEffect(() => {
    
    const headers = [
      { label: "First Name", key: "first_name" },
      { label: "Last Name", key: "last_name"},
      { label: "Status", key: "active"},
      { label: "Address", key: "city"},
      { label: "Country", key: "country"},
      { label: "D-O-B", key: "dob"},
      { label: "Gender", key: "gender"},
      { label: "Mobile No", key: "mobile_no"},
      { label: "Post Code", key: "postcode"},
      { label: "User Type", key: "userType"},
      { label: "Date Created", key: "date_created"},
    ]

    setCsvReport({
      data: filterCategoryList,
      headers: headers,
      filename: 'trial.csv'
    })

  }, [filterCategoryList])

  const { 
    userList,
    setOpenNone
  } = props

  useEffect(() => {
    setusers(userList);
    setFilterCategoryList(userList);
  }, [userList])

  const creationFromChangeHandler = (e) => {
    setCreationFrom(e.target.value);
  };

  const creationToChangeHandler = (e) => {
    setCreationTo(e.target.value);
  };

  const filterButtonClickHandler = () => {
    if (filterCategory === "All") {
      setFilterCategoryList(users);
    }
    else if (filterCategory === "Board & Class:") {
      setFilterCategoryList(users.filter((users) => users.profile_masters.board_id === userBoard && users.profile_masters.class_id === userClass))
    }
    else if (filterCategory === "Board:") {
      setFilterCategoryList(users.filter((users) => users.profile_masters.board_id === userBoard))
    }
    else if (filterCategory === "Class:") {
      setFilterCategoryList(users.filter((users) => users.profile_masters.class_id === userClass))
    }
    else if (filterCategory === "City: ") {
      setFilterCategoryList(users.filter((users) => users.city?.toUpperCase() === filterByInput?.toUpperCase()))
    }
    else if (filterCategory === "Creation Date") {
      setFilterCategoryList(users.filter((users) => moment(users.date_created).isAfter(creationFrom) && moment(users.date_created).isBefore(creationTo)))
    }
    else if (filterCategory === "Post Code: For eg. 123456") {
      if (!isNaN(filterByInput)) {
        setFilterCategoryList(users.filter((users) => users.postcode === filterByInput))
      }
      else {
        alert('Please enter a valid POSTCODE')
      }
    }
    else if (filterCategory === "Activity Status:") {
      setFilterCategoryList(users.filter((users) => users.active === parseInt(userActivity)))
    }
    setFilterByInput('')
  }
  const usersData = useMemo(() => [...filterCategoryList], [filterCategoryList]);

  const usersColumns = useMemo(
    () =>
      filterCategoryList[0]
        ? Object.keys(filterCategoryList[0])
            .filter((key) => key !== 'date_modified' && key !== 'user_id' && key !== 'gender' && key !== "profile_masters")
            .map((key) => {
              if (key === "active")
                return {
                  Header: 'status',
                  accessor: key,
                  Cell: ({ value }) => <h4><b>{value===0? <p style={{color: 'red'}}>InActive</p>: <p style={{color: 'green'}}>Active</p>}</b></h4>,
                  
                };
              if (key === "date_created")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <p style={{width: 90}}>{value.slice(0,-14)}</p>,
                  
                };
              if (key === "dob")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <p style={{width: 90}}>{value}</p>,
                  
                };
              if (key === "mobile_no")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <p style={{width: 110}}>{value}</p>,
                  
                };

              return { Header: key, accessor: key };
            })
        : [],
    [filterCategoryList]
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "CLASS",
        Header: "CLASS",
        Cell: ({ row }) => <p>{row.original.profile_masters.class}</p>,
      },
      {
        id: "BOARD",
        Header: "BOARD",
        Cell: ({ row }) => <p>{row.original.profile_masters.board_master.board_name}</p>,
      },
      {
        id: "DELETE",
        Header: "DELETE",
        Cell: ({ row }) => <Button onClick={() => {
          props.setUserId(row?.original?.user_id);
          props.setFirstName(row?.original?.first_name);
          props.setLastName(row?.original?.last_name);
          props.setEmail(row?.original?.email);
          props.setMobileNo(row?.original?.mobile_no);
          props.setCity(row?.original?.city);
          props.setCountry(row?.original?.country);
          props.setDob(row?.original?.dob);
          props.setGender(row?.original?.gender?.toUpperCase());
          props.setPostcode(row?.original?.postcode);
          props.handleOpen();
        }}>UPDATE</Button>
      },
      {
        id: "SUBSCRIPTIONS",
        Header: "SUBSCRIPTIONS",
        Cell: ({ row }) => <Button onClick={() => navigate("/userSubscriptionList", {
          state: {
            data: row.original
          }
        })}>Subscriptions</Button>
      },
      {
        id: "Performance",
        Header: "PERFORMANCE",
        Cell: ({ row }) => <Button onClick={() => navigate("/userPerformance", {
          state: {
            data: row.original
          }
        })}>Performance</Button>
      }
    ]);
  };

  useEffect(() => {
    setOpenNone(true? filterCategoryList.length  === 0 : false)
  }, [ setOpenNone, filterCategoryList ])

  const tableInstance = useTable(
    {
      columns: usersColumns,
      data: usersData,
    },
    useGlobalFilter,
    tableHooks,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = tableInstance;

  // useEffect(() => {
  //   fetchUsersList();
  // }, []);

  const isEven = (idx) => idx % 2 === 0;

  return (
    <>
      <Grid container justifyContent={'space-between'} mb={5}>
        <Grid item xs={4.5}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
        </Grid>
        <Grid item xs={3} justifyContent={'center'}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
            <Select
              style={{padding: 10}}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterCategory}
              label="Filter By"
              onChange={(e) => setFilterCategory(e.target.value)}
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={'Board & Class:'}>Board & Class</MenuItem>
              <MenuItem value={'Board:'}>Board</MenuItem>
              <MenuItem value={'Class:'}>Class</MenuItem>
              <MenuItem value={'City: '}>City</MenuItem>
              <MenuItem value={'Creation Date'}>Date</MenuItem>
              <MenuItem value={'Post Code: For eg. 123456'}>Post Code</MenuItem>
              <MenuItem value={"Activity Status:"}>Status</MenuItem>
            </Select>
            <Button 
              onClick={filterButtonClickHandler} 
              variant='contained' 
              style={{marginTop: '1rem', color: '#fff', width: '100%'}} 
            >
              Filter
            </Button>
          </FormControl>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <FormControl fullWidth>
            {filterCategory === 'All' ? '' : filterCategory === 'Creation Date' ? 
            (<Grid container justifyContent={'space-between'}>
              <Grid item xs={5.5}>
                <InputLabel id="creationFromLabel" style={{marginTop: '-2rem', marginLeft: '-0.8rem'}}>Creation Date From & To: </InputLabel>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  type='date'
                  labelId='creationFromLable'
                  variant="outlined"
                  value={creationFrom}
                  onChange={creationFromChangeHandler}
                />
              </Grid>
              <Grid item xs={5.5}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  labelId='creationToLable'
                  type='date'
                  variant="outlined"
                  value={creationTo}
                  onChange={creationToChangeHandler}
                />
              </Grid>
            </Grid>) : filterCategory === 'Board & Class:' ? 
            (<Grid container justifyContent={'space-between'}>
              <Grid item xs={5.5}>
                <FormControl
                    fullWidth>
                  <InputLabel id="boardLabel">Board: </InputLabel>
                  <Select
                    style={{padding: 10}}
                    labelId="boardLabel"
                    id="board"
                    value={userBoard}
                    label="Board: "
                    onChange={(e) => {
                      setUserBoard(e.target.value)
                      fetchClassByBoard(e.target.value)
                    }}
                  >
                    {boardList.map((brd) => {
                      if ( brd.active === 1) return (<MenuItem value={brd.board_id} key={brd.board_id}>{brd.board_name}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5.5}>
                <FormControl
                    fullWidth>
                  <InputLabel id="classLabel">Class: </InputLabel>
                  <Select
                    style={{padding: 10}}
                    labelId="classLabel"
                    id="class"
                    value={userClass}
                    label="Class: "
                    onChange={(e) => setUserClass(e.target.value)}
                  >
                    {filteredClassList.map((cls) => {
                      if ( cls.active === 1) return (<MenuItem value={cls.class_id} key={cls.class_id}>{cls.class_name}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>) : filterCategory === 'Activity Status:' ? 
          <><InputLabel id="activityLabel">Activity Status: </InputLabel>
            <Select
              style={{padding: 10}}
              labelId="activityLabel"
              id="activitySelect"
              value={userActivity}
              label="Activity Status: "
              onChange={(e) => setUserActivity(e.target.value)}
            >
              <MenuItem value={'1'} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
              <MenuItem value={'0'} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
            </Select></>
          : filterCategory === 'Board:' ? 
          <><InputLabel id="boardLabel">Board: </InputLabel>
            <Select
              style={{padding: 10}}
              labelId="boardLabel"
              id="board"
              value={userBoard}
              label="Board: "
              onChange={(e) => setUserBoard(e.target.value)}
            >
              {boardList.map((brd) => {
                if ( brd.active === 1) return (<MenuItem value={brd.board_id} key={brd.board_id}>{brd.board_name}</MenuItem>)
              })}
            </Select></>
          : filterCategory === 'Class:' ? 
          <><InputLabel id="classLabel">Class: </InputLabel>
            <Select
              style={{padding: 10}}
              labelId="classLabel"
              id="class"
              value={userClass}
              label="Class: "
              onChange={(e) => setUserClass(e.target.value)}
            >
              {filteredClassList.map((cls) => {
                if ( cls.active === 1) return (<MenuItem value={cls.class_id} key={cls.class_id}>{cls.class_name}</MenuItem>)
              })}
            </Select></>
          :<TextField 
            id="outlined-basic" 
            label={"Enter " + filterCategory} 
            variant="outlined" 
            value={filterByInput} 
            onChange={(e) => setFilterByInput(e.target.value)}
          />}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'}>
        <Grid item flex style={{background: '#4CAF50', color: '#ffffff', fontSize: '1.2rem', padding: '0.8rem', textAlign: 'center', borderRadius: '50%', width: '70px', border: '4px solid',}}>
          <FormControl fullWidth>
            {filterCategoryList.length < 2 ? null :
              <CSVLink {...csvReport}>&#8595;</CSVLink>
            }
          </FormControl>
        </Grid>
      </Grid>
      <Table {...getTableProps()} style={{maxWidth: '100%'}}>
        <TableHead className={"bg-[#1D72E8] text-white"}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeader
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header").toUpperCase().replace('_',' ')}
                  {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);

            return (
              <TableRow
                {...row.getRowProps()}
                className={!isEven(idx) ? "bg-[#4CAF50] bg-opacity-30" : ""}
              >
                {row.cells.map((cell, idx) => (
                  <TableData {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableData>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}