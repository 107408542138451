/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { useEffect, useState } from "react";
import { TestData } from "../tables/data/TestData";
import tw from "twin.macro";
import MDButton from "components/MDButton";
// import { Box, Button, FormControl, FormGroup, Input, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import globalURL from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MDSnackbar from "components/MDSnackbar";
import { Box } from "@mui/system";
import { Modal, Typography } from "@mui/material";
import { SpinnerInfinity } from "spinners-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: '2rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AppContainer = tw.div`
  w-full
  max-w-full
  flex
  flex-col
  items-stretch
  justify-center
  pt-6
  pb-10
  pl-10
  pr-10
`;

function Tables() {

  const navigate = useNavigate();
  const [ testId, setTestId ] = useState('');
  const [ testName, setTestName ] = useState('');

  const [ testList, setTestList ] = useState([]);

  // const [ openupdate, setOpenupdate ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [ message, setMessage ] = useState('');
  const [ openMessage, setOpenMessage ] = useState(false);
  const handleCloseMessage = () => setOpenMessage(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => setOpenDelete(false);

  useEffect(()=> {
    fetchTestList();
  }, [])

  const fetchTestList = async () => {
    const response = await axios
      .get(globalURL + "test/showTest")
      .catch((err) => console.log(err));

    if (response) {
      const test = response.data;
      
      setTestList(test.data);
    }
  };

  const deleteTest = async () => {
    setLoading(true);
    handleCloseDelete();
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        test_id: testId,
        active: 0
      }),
    };
    await fetch(
      globalURL + "test/updateTest_status",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          handleCloseDelete();
          setLoading(false);
          setTestId('');
          setMessage(res.message);
          openSuccessSB();
        } else {
          setLoading(false);
          setTestId('');
          setMessage(res.message);
          handleCloseDelete();
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [errorSB, setErrorSB] = useState(false);
  
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Board Updated Successfully"
      content="Your Board is now updated successfully"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content="Getting Error while updating Board"
      dateTime="Just Now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
                <MDButton
                  variant="contained"
                  color="white"
                  size="small"
                  onClick={() => {
                    navigate('/createtest')
                  }}
                >
                  Create new Test
                </MDButton>

                <Modal
                  open={openDelete}
                  onClose={handleCloseDelete}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} style={{textAlign: 'center'}}>
                    <Typography
                      id="modal-modal-title"
                      variant="h3"
                      component="h2"
                      style={{marginBottom: '2rem'}}
                    >
                      Are You Sure You Want To Delete This Board?
                    </Typography>
                    {testName}
                    <Grid 
                      container 
                      justifyContent={'space-evenly'}
                      style={{ marginTop: "2rem" }}
                    >
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={deleteTest}
                        >
                          Delete
                        </MDButton>
                      </Grid>
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="dark"
                          size="small"
                          onClick={handleCloseDelete}
                        >
                          CLOSE
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                <Modal
                  open={openMessage}
                  onClose={handleCloseMessage}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} style={{textAlign: 'center'}}>
                    <Grid item xs={12}>
                      <Typography
                        id="modal-modal-title"
                        variant="h4"
                        component="h2"
                        style={{marginBottom: '2rem',}}
                      >
                        {message}
                      </Typography>
                    </Grid>
                    <Grid 
                      container 
                      justifyContent={'center'}
                      style={{ marginTop: "2rem" }}
                    >
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="dark"
                          size="small"
                          onClick={handleCloseMessage}
                        >
                          CLOSE
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                {renderSuccessSB}
                {renderErrorSB}
              </MDBox>
              <MDBox pt={3} style={{height: '44rem', overflow: 'auto'}}>
                {loading ? 
                  <Grid container justifyContent={'center'}height={'80vh'}>
                    <SpinnerInfinity
                      size={190} 
                      thickness={180} 
                      speed={100} 
                      color="rgba(59, 172, 57, 1)" 
                      secondaryColor="rgba(57, 145, 172, 0.44)" 
                    />
                  </Grid> :
                <AppContainer>
                  <TestData 
                    // setActivity={setActivity}
                    setTestId={setTestId}
                    setTestName={setTestName}
                    setOpenDelete={setOpenDelete}
                    testList={testList}
                  />
                </AppContainer>}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;