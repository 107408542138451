/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { useContext, useState } from "react";
import { ClassData } from "./data/ClassData";
import tw from "twin.macro";
import MDButton from "components/MDButton";
import { Box, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import { SpinnerInfinity } from "spinners-react";

import globalURL from "../../config";
import '../../index.css'
import ListContext from "layouts/apiContext/listContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: '2rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AppContainer = tw.div`
  w-full
  max-w-full
  flex
  flex-col
  items-stretch
  justify-center
  pt-6
  pb-10
  pl-10
  pr-10
`;

function Tables() {
  
  const { 
    classList,
    fetchClassList,
    mediumList,
    boardList
  } = useContext(ListContext);

  const [ logo, setLogo ] = useState('');
  const [ oldLogo, setOldLogo ] = useState('');
  const [ medium, setMedium ] = useState('');
  const [ boardName, setBoardName ] = useState('');
  const [ className, setClassName ] = useState('');
  const [ updatedClassName, setUpdatedClassName ] = useState('');
  const [ updatedClassId, setUpdatedClassId ] = useState('');
  const [ updatedMedium, setUpdatedMedium ] = useState('');
  const [ activity, setActivity ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const [index_value, setIndex_value] = useState(null);
  const [update_index, setUpdate_index] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setLogo('');
    setBoardName('');
  };
  const [errorSB, setErrorSB] = useState(false);

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [openupdate, setOpenupdate] = useState(false);
  const handleCloseupdate = () => {
    setOpenupdate(false)
    setLogo('');
    setBoardName('');
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => setOpenDelete(false);

  const [ message, setMessage ] = useState('');
  const [ openMessage, setOpenMessage ] = useState(false);
  const handleCloseMessage = () => setOpenMessage(false);

  const createClass = async () => {
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1c2VyX2lkIjoiMSIsImVtYWlsIjoic2hpdmtyOTgxODhAZ21haWwuY29tIiwibG9naW5UaW1lIjoxNjUxNTcyODIwOTYwLCJnZW5lcmF0ZWRfYXQiOjE2NTE1NzI4MjA5NjAsImlhdCI6MTY1MTU3MjgyMCwiZXhwIjoxNjgzMTMwNDIwLCJpc3MiOiJSZWNydWl0In0.T4fHdvweSB4iFL7oXrKXiIh9u6IHvXQtVUfX-mafNZ3Gl3hJyC7AyXSCPgin_r_FRKz2nHnFe5a6epsbM_Idqw'
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
      },
      body: JSON.stringify({
        class_name: className,
        medium: medium,
        created_by: '1',
        index:Number(index_value),
        logo: logo.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''),
        // background: background.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''),
        board_id: boardName,
      }),
    };
    fetch(
      globalURL + "class_master/create_class",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {

          fetchClassList();
          setMessage(res.message);
          setOpenMessage(true);
          handleClose();
          setClassName("");
          setIndex_value(null);
          // setcreated_by("");
          setMedium("");
          setLogo('');
          openSuccessSB();
        } else {
          // tryingfunc();
          fetchClassList();
          setClassName("");
          setIndex_value(null);
          setMedium("");
          setMessage(res.message);
          setOpenMessage(true);
          setLogo('');
          handleClose();
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    })
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Class Updated Successfully"
      content="Your Class is now updated successfully"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content="Getting Error while updating Class"
      dateTime="Just Now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const uploadImage = async (image) => {
    
    const file = image.target.files[0]
    const base64 = await convertBase64(file)
    setLogo(base64);

  }

  const convertBase64 = (file) => {

    return new Promise((resolve, reject) => {

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = (() => {
        resolve(fileReader.result);
      });

      fileReader.onerror = ((error) => {
        reject(error);
      });

    });

  }

  const Updateapi = async () => {
    setLoading(true);
    handleCloseupdate();
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': userToken.replace('"', "").replace('"', ""),
          // 'Authorization': 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1c2VyX2lkIjoiMSIsImVtYWlsIjoic2hpdmtyOTgxODhAZ21haWwuY29tIiwibG9naW5UaW1lIjoxNjUxNTcyODIwOTYwLCJnZW5lcmF0ZWRfYXQiOjE2NTE1NzI4MjA5NjAsImlhdCI6MTY1MTU3MjgyMCwiZXhwIjoxNjgzMTMwNDIwLCJpc3MiOiJSZWNydWl0In0.T4fHdvweSB4iFL7oXrKXiIh9u6IHvXQtVUfX-mafNZ3Gl3hJyC7AyXSCPgin_r_FRKz2nHnFe5a6epsbM_Idqw'
      },
      body: JSON.stringify({
        class_id: updatedClassId,
        class_name: updatedClassName,
        medium: updatedMedium,
        active: activity,
        index:update_index,
        logo: logo.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''),
        // backgroud: background.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''),
        board_id: boardName,
      })
    }
    fetch(
      globalURL + "class_master/update_class",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          // tryingfunc();
          setLoading(false);
          handleCloseupdate();
          fetchClassList();
          setBoardName('');
          setUpdatedClassName("");
          setUpdate_index("")
          setMessage(res.message);
          setOpenMessage(true);
          setUpdatedMedium("");
          setLogo('');
          setUpdatedClassId("");
          openSuccessSB();
        } else {
          // tryingfunc();
          handleCloseupdate();
          fetchClassList();
          setLoading(false);
          setUpdatedClassName("");
          setUpdate_index("")
          setMessage(res.message);
          setOpenMessage(true);
          setBoardName('');
          setLogo('');
          setUpdatedMedium("");
          setUpdatedClassId("");
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    })
  };

  const deleteClass = async () => {
    setLoading(true);
    setOpenDelete();

    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        class_id: updatedClassId,
      }),
    };
    await fetch(
      globalURL + "class_master/delete_class",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          handleCloseDelete();
          fetchClassList();
          setMessage(res.message);
          setOpenMessage(true);
          setLoading(false);
          setUpdatedClassId("");
          setUpdatedClassName("");
          setUpdate_index("")
          openSuccessSB();
        } else {
          handleCloseDelete();
          fetchClassList();
          setLoading(false);
          setMessage(res.message);
          setOpenMessage(true);
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="warning"
              >
                <MDButton
                  variant="contained"
                  size="small"
                  onClick={handleOpen}
                  className={'text-[#000000] bg-white'}
                >
                  Create a new class
                </MDButton>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h3"
                      component="h2"
                    >
                      Create New Class
                    </Typography>
                    <Grid container justifyContent={'center'}>
                      <Grid item xs={6} justifyContent={'center'} style={{textAlign: 'center'}}>
                        <Grid item mb={2}>
                          <label for='logo'>Logo</label>
                          <input
                            id='logo'
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={(image) => uploadImage(image)}
                          />
                        </Grid>
                        <Grid item xs={12} justifyContent={'center'}>
                          {!logo? null : <img 
                            src={logo} 
                            style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                            alt=''
                          />}
                        </Grid>
                      </Grid>
                    </Grid>
                    <div>
                      <FormControl fullWidth>
                        <TextField
                          id="outlined-basic"
                          label="INDEX"
                          variant="outlined"
                          inputMode="numeric"
                          value={index_value}
                          style={{ margin: "2rem 0" }}
                          onChange={(e) => setIndex_value(e.target.value)}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="outlined-basic"
                          label="CLASS NAME"
                          variant="outlined"
                          value={className}
                          style={{ margin: "2rem 0" }}
                          onChange={(e) => setClassName(e.target.value)}
                        />
                      </FormControl>
                      <FormControl style={{ marginBottom: '1rem'}} fullWidth>
                        <InputLabel id="demo-simple-select-label">MEDIUM</InputLabel>
                        <Select
                          style={{padding: 10}}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={medium}
                          label="MEDIUM"
                          onChange={(e) => setMedium(e.target.value)}
                        >
                          {mediumList.map((med) => {

                            if (med.active === 1) return <MenuItem value={med.medium_name} key={med.medium_id}>{med.medium_name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                      <FormControl style={{ margin: "1rem 0 2rem 0" }} fullWidth>
                        <InputLabel id="demo-simple-select-label">BOARD</InputLabel>
                        <Select
                          style={{padding: 11}}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={boardName}
                          label="BOARD"
                          onChange={(e) => {
                            setBoardName(e.target.value)
                          }}
                        >
                          {boardList.map((brd) => {
                            if (brd.active === 1) return <MenuItem value={brd.board_id} key={brd.board_id}>{brd.board_name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <Grid container justifyContent="space-evenly">
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="medium"
                          onClick={createClass}
                          disabled={
                            !className || !medium 
                            || !logo || !boardName || !index_value
                            ? true : false
                          }
                        >
                          SEND
                        </MDButton>
                      </Grid>
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="dark"
                          size="medium"
                          onClick={handleClose}
                        >
                          CLOSE
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                <Modal
                  open={openupdate}
                  onClose={handleCloseupdate}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h3"
                      component="h2"
                    >
                      Update Your Class
                    </Typography>
                    <Grid container justifyContent={'space-between'}>
                      <Grid item xs={6} justifyContent={'center'} style={{textAlign: 'center'}}>
                        <Grid item xs={12} justifyContent={'center'}>
                          {!oldLogo? null : <>
                          <label for='oldLogo'>Old Logo</label>
                            <img 
                              id='oldLogo'
                              src={oldLogo} 
                              style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                              alt=''
                            />
                          </>}
                        </Grid>
                      </Grid>
                      <Grid item xs={6} justifyContent={'center'} style={{textAlign: 'center'}}>
                        <Grid item>
                          <label for='logo'>New Logo</label>
                          {logo? null :
                              <input
                                id='logo'
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={(image) => uploadImage(image)}
                              />
                          }
                        </Grid>
                        <Grid item xs={12} justifyContent={'center'}>
                          {!logo? null : <>
                            <img 
                              id='newLogo'
                              src={logo} 
                              style={{display: 'flex' , margin: 'auto', height: '10rem' , width: '10rem'}} 
                              alt=''
                            />
                          </>}
                        </Grid>
                      </Grid> 
                    </Grid>                   
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Index"
                      variant="outlined"
                      value={update_index}
                      onChange={(e) => setUpdate_index(e.target.value)}
                      style={{ margin: "1rem 0" }}
                    />
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="CLASS NAME"
                      variant="outlined"
                      value={updatedClassName}
                      onChange={(e) => setUpdatedClassName(e.target.value)}
                      style={{ margin: "1rem 0" }}
                    />
                    <FormControl 
                      fullWidth
                      style={{ margin: "1rem 0" }}
                    >
                      <InputLabel id="demo-simple-select-label">MEDIUM</InputLabel>
                      <Select
                        style={{padding: 10}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={updatedMedium}
                        label="MEDIUM"
                        onChange={(e) => setUpdatedMedium(e.target.value)}
                      >
                        {mediumList.map((med) => {
                          if (med.active === 1) return <MenuItem value={med.medium_name} key={med.medium_id}>{med.medium_name}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                    <FormControl 
                      fullWidth
                      style={{ margin: "1rem 0" }}
                    >
                      <InputLabel id="activityLabel">Activity Status: </InputLabel>
                        <Select
                          fullWidth
                          style={{padding: 11}}
                          labelId="activityLabel"
                          id="activitySelect"
                          value={activity}
                          label="Activity Status: "
                          onChange={(e) => setActivity(e.target.value)}
                        >
                          <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
                          <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl 
                      style={{ margin: '1rem 0'}} 
                      fullWidth>
                      <InputLabel id="demo-simple-select-label">BOARD</InputLabel>
                      <Select
                        style={{padding: 11}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={boardName}
                        label="BOARD"
                        onChange={(e) => {
                          setBoardName(e.target.value)
                        }}
                      >
                        {boardList.map((brd) => {
                          if (brd.active === 1) return <MenuItem value={brd.board_id} key={brd.board_id}>{brd.board_name}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                    <Grid 
                      container 
                      justifyContent={'space-evenly'}
                      style={{ marginTop: "20px" }}
                    >
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={Updateapi}
                          disabled={
                            !updatedClassName || !updatedMedium
                            || !boardName
                              ? true
                              : false
                          }
                        >
                          Update
                        </MDButton>
                      </Grid>
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="dark"
                          size="small"
                          onClick={handleCloseupdate}
                        >
                          CLOSE
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                <Modal
                  open={openDelete}
                  onClose={handleCloseDelete}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} style={{textAlign: 'center'}}>
                    <Typography
                      id="modal-modal-title"
                      variant="h3"
                      component="h2"
                      style={{marginBottom: '2rem'}}
                    >
                      Are You Sure You Want To Delete This Class?
                    </Typography>
                    {updatedClassName}
                    <Grid 
                      container 
                      justifyContent={'space-evenly'}
                      style={{ marginTop: "2rem" }}
                    >
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={deleteClass}
                        >
                          Delete
                        </MDButton>
                      </Grid>
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="dark"
                          size="small"
                          onClick={handleCloseDelete}
                        >
                          CLOSE
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                <Modal
                  open={openMessage}
                  onClose={handleCloseMessage}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} style={{textAlign: 'center'}}>
                    <Grid item xs={12}>
                      <Typography
                        id="modal-modal-title"
                        variant="h4"
                        component="h2"
                        style={{marginBottom: '2rem',}}
                      >
                        {message}
                      </Typography>
                    </Grid>
                    <Grid 
                      container 
                      justifyContent={'center'}
                      style={{ marginTop: "2rem" }}
                    >
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="dark"
                          size="small"
                          onClick={handleCloseMessage}
                        >
                          CLOSE
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                {renderSuccessSB}
                {renderErrorSB}
              </MDBox>
              <MDBox pt={3} style={{height: '44rem', overflow: 'auto'}}>
                {loading ? 
                  <Grid container justifyContent={'center'}height={'80vh'}>
                    <SpinnerInfinity 
                      size={190} 
                      thickness={180} 
                      speed={100} 
                      color="rgba(59, 172, 57, 1)" 
                      secondaryColor="rgba(57, 145, 172, 0.44)" 
                    />
                  </Grid> :
                  <AppContainer>
                    <ClassData 
                      setUpdatedClassName={setUpdatedClassName} 
                      setUpdatedClassId={setUpdatedClassId}
                      setUpdatedMedium={setUpdatedMedium}
                      setActivity={setActivity}
                      setUpdate_index={setUpdate_index}
                      setBoardName={setBoardName}
                      setOldLogo={setOldLogo}
                      setOpenupdate={setOpenupdate}
                      setOpenDelete={setOpenDelete}
                      classList={classList}
                    />
                  </AppContainer>
                  }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;