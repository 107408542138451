/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { useEffect, useState } from "react";
import tw from "twin.macro";
import MDButton from "components/MDButton";
import { Box, FormControl, FormGroup, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import globalURL from "../../config";
// import { Carousel } from "react-responsive-carousel";
import '../../index.css'
import ListContext from "layouts/apiContext/listContext";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: '2rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AppContainer = tw.div`
  w-full
  max-w-full
  flex
  flex-col
  items-stretch
  justify-center
  pt-6
  pb-10
  pl-10
  pr-10
`;

function UserPerformance() {
  
  const { 
    classList,
    subjectList,
    topicList,
    videoList,
    fetchUserList,
    bannerList,
    userList
  } = useContext(ListContext);
  const [ userPerformance, setUserPerformance ] = useState([]);
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [errorSB, setErrorSB] = useState(false);

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [openupdate, setOpenupdate] = useState(false);
  const handleCloseupdate = () => setOpenupdate(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => setOpenDelete(false);

  const [openNone, setOpenNone] = useState(false);
  const handleCloseNone = () => setOpenNone(false);

  const [ message, setMessage ] = useState('');
  const [ openMessage, setOpenMessage ] = useState(false);
  const handleCloseMessage = () => setOpenMessage(false);

  const [openMore, setOpenMore] = useState(false);
  const handleCloseMore = () => {
    setOpenMore(false)
  };

  useEffect(() => {
    fetchUserPerformance();
  }, [])

  const fetchUserPerformance = async () => {
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
      },
      body: JSON.stringify({
        user_id: location.state.data.user_id
      }),
    };
    fetch(
      globalURL + "user_activity/get_performance",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          handleClose();
          setUserPerformance(res.data.performance_data)
        //   setMessage(res.message);
        //   setOpenMessage(true);
        //   openSuccessSB();
        } else {
          handleClose();
          openErrorSB();
          setMessage(res.message);
          setOpenMessage(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    })
  }

  const dataPie = {
      labels: ['Completed', 'Remaining'],
      datasets: [
        {
          label: 'Completion of Class',
          data: [parseFloat(userPerformance?.class_data?.percent), (100 - parseFloat(userPerformance?.class_data?.percent))],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
          //   'rgba(255, 206, 86, 0.2)',
          //   'rgba(75, 192, 192, 0.2)',
          //   'rgba(153, 102, 255, 0.2)',
          //   'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
          //   'rgba(255, 206, 86, 1)',
          //   'rgba(75, 192, 192, 1)',
          //   'rgba(153, 102, 255, 1)',
          //   'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };

//   const handleAPI = async () => {
//     async function getUserToken() {
//       return await localStorage.getItem("userInfo");
//     }
//     getUserToken().then((userToken) => {
//     const requestOptions = {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
//       },
//       body: JSON.stringify({
//         "first_name": firstName,
//         "last_name":lastName,
//         "email":email,
//         "mobile_no": mobileNo,
//         "city":address,
//         "country":'India',
//         "dob":dob,
//         "gender":gender,
//         "postcode":postcode,
//         "board":'',
//         "class":'',
//         "userType":userType,
//         "class_id": "class_a6c3d7eaa841"
//       }),
//     };
//     fetch(
//       globalURL + "admin/user_create",
//       requestOptions
//     )
//       .then((res) => res.json())
//       .then((res) => {
//         if (res.message === "Success") {
//           handleClose();
//           setFirstName("");
//           setMessage(res.message);
//           setOpenMessage(true);
//           setLastName('');
//           setEmail('');
//           setMobileNo('');
//           setCity('');
//           setCountry('');
//           setDob('');
//           setGender('');
//           setPostcode('');
//           setUserType('');
//           openSuccessSB();
//         } else {
//           handleClose();
//           openErrorSB();
//           setMessage(res.message);
//           setOpenMessage(true);
//         }
//       })
//       .catch((e) => {
//         console.log(e);
//       });
//     })
//   };

//   const updateAPI = async () => {
//     async function getUserToken() {
//       return await localStorage.getItem("userInfo");
//     }
//     getUserToken().then((userToken) => {
//     const requestOptions = {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
//         // 'Authorization': "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1c2VyX2lkIjoiMSIsImVtYWlsIjoic2hpdmtyOTgxODhAZ21haWwuY29tIiwibG9naW5UaW1lIjoxNjUxNTcyODIwOTYwLCJnZW5lcmF0ZWRfYXQiOjE2NTE1NzI4MjA5NjAsImlhdCI6MTY1MTU3MjgyMCwiZXhwIjoxNjgzMTMwNDIwLCJpc3MiOiJSZWNydWl0In0.T4fHdvweSB4iFL7oXrKXiIh9u6IHvXQtVUfX-mafNZ3Gl3hJyC7AyXSCPgin_r_FRKz2nHnFe5a6epsbM_Idqw",
//       },
//       body: JSON.stringify({
//         "user_id": userId,
//         "first_name": firstName,
//         "last_name":lastName,
//         "email":email,
//         "mobile_no": mobileNo,
//         "city":city,
//         "country":'India',
//         "dob":dob,
//         "gender":gender,
//         "postcode":postcode,
//         // active: activity
//       }),
//     };
//     fetch(
//       globalURL + "admin/update_user",
//       requestOptions
//     )
//       .then((res) => res.json())
//       .then((res) => {
//         if (res.message === "Success") {
//           handleCloseupdate();
//           setUserId('');
//           setFirstName("");
//           setMessage(res.message);
//           setOpenMessage(true);
//           setLastName('');
//           setEmail('');
//           setMobileNo('');
//           setCity('');
//           setCountry('');
//           setDob('');
//           setGender('');
//           setPostcode('');
//           fetchUserList();
//           openSuccessSB();
//         } else {
//           setMessage(res.message);
//           setOpenMessage(true);
//           handleCloseupdate();
//           openErrorSB();
//         }
//       })
//       .catch((e) => {
//         console.log(e);
//       });
//     })
//   };

//   const deleteUser = async () => {
//     async function getUserToken() {
//       return await localStorage.getItem("userInfo");
//     }
//     getUserToken().then((userToken) => {
//     const requestOptions = {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
//       },
//       body: JSON.stringify({
//         user_id: userId,
//       }),
//     };
//     fetch(
//       globalURL + "admin/delete_user",
//       requestOptions
//     )
//       .then((res) => res.json())
//       .then((res) => {
//         if (res.message === "Success") {
//           handleCloseDelete();
//           setUserId('');
//           setMessage(res.message);
//           setOpenMessage(true);
//           openSuccessSB();
//         } else {
//           setUserId('');
//           setMessage(res.message);
//           setOpenMessage(true);
//           handleCloseDelete();
//           openErrorSB();
//         }
//       })
//       .catch((e) => {
//         console.log(e);
//       });
//     })
//   };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="User Updated Successfully"
      content="Your User is now updated successfully"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content="Getting Error while updating User"
      dateTime="Just Now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  console.log(location.state.data)
  console.log("===userPerformance",userPerformance);

//   const totalVideoFunc = () => {
//     let total = 0;
//     for (let i = 0; i < userPerformance.subject_data)
//   }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  Users Performance for {location.state.data.first_name} {location.state.data.last_name}
                </MDTypography>

              {/* <Typography
                id="modal-modal-title"
                variant="contained"
                style={{color: '#1D72E8', background: '#ffffff'}}
                component="h2"
              >
                User List
              </Typography> */}
              {/* <MDButton
                variant="contained"
                color="white"
                size="small"
                onClick={handleOpen}
              >
                Create new Admin
              </MDButton> */}
              
              {renderSuccessSB}
              {renderErrorSB}
              </MDBox>
              <MDBox pt={3} style={{height: '44rem', overflow: 'auto'}}>
                <AppContainer>
                    Class Performance
                    <Grid xs={4}>
                        <Pie data={dataPie} />
                    </Grid>
                </AppContainer>
                <AppContainer>
                    <Grid container>
                        <Grid item xs={12}>
                            <p className="pb-4"> Subject Performance</p>
                        </Grid>
                    {
                        userPerformance && userPerformance?.subject_data?.map(sub => {
                            const dataPie = {
                                labels: ['Completed', 'Remaining'],
                                datasets: [
                                  {
                                    label: 'Completion of Class',
                                    data: [parseFloat(sub?.video_seen_percentage || 0), (100 - parseFloat(sub?.video_seen_percentage) || 0)],
                                    backgroundColor: [
                                      'rgba(255, 99, 132, 0.2)',
                                      'rgba(54, 162, 235, 0.2)',
                                    //   'rgba(255, 206, 86, 0.2)',
                                    //   'rgba(75, 192, 192, 0.2)',
                                    //   'rgba(153, 102, 255, 0.2)',
                                    //   'rgba(255, 159, 64, 0.2)',
                                    ],
                                    borderColor: [
                                      'rgba(255, 99, 132, 1)',
                                      'rgba(54, 162, 235, 1)',
                                    //   'rgba(255, 206, 86, 1)',
                                    //   'rgba(75, 192, 192, 1)',
                                    //   'rgba(153, 102, 255, 1)',
                                    //   'rgba(255, 159, 64, 1)',
                                    ],
                                    borderWidth: 1,
                                  },
                                ],
                              };
                            return (
                                <Grid item xs={3} pb={2}>
                                    <p className="text-sm">{sub.subject}</p>
                                    <Pie data={dataPie}/>
                                </Grid>
                            )
                        })
                    }
                    <Grid item xs={3}>
                        <p className="text-sm">Total Seen Videos</p>
                        <p className="text-lg text-center">{userPerformance.total_seen_video}</p>
                        
                    </Grid>
                    </Grid>
                    {/* <Grid xs={4}>
                        <Pie data={dataPie} />
                    </Grid> */}
                </AppContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default UserPerformance;