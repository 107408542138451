import React, { useMemo } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import tw from "twin.macro";
import { GlobalFilter } from "./GlobalFilter";
import '../../../index.css'
import { useNavigate } from "react-router-dom";

const Table = tw.table`
  table-fixed
  text-base
  text-gray-900
`;

const TableHead = tw.thead`
  p-2
  bg-gradient-to-l
  from-[#EC407A]
  to-[#D81B60]
`;

const TableRow = tw.tr`
  text-center
  border
  border-blue-500
`;

const TableHeader = tw.th`
  border
  border-blue-500
  p-2
`;

const TableBody = tw.tbody`

`;

const TableData = tw.td`
  border
  border-blue-500
  p-5
`;

const Button = tw.button`
  pl-4
  pr-4
  pt-2
  pb-2
  text-white
  rounded-md
  bg-[#e91e63]
  hover:bg-blue-300
  hover:cursor-pointer
  transition-colors
`;

export function TestData(props) {

  const navigate = useNavigate();

  const testData = useMemo(() => [...props.testList], [props.testList]);

  const testColumns = useMemo(
    () =>
      props.testList[0]
        ? Object.keys(props.testList[0])
            .filter((key) => key !== 'id' && key !== 'test_id' && key !== 'subject_id' && key !== 'class_id' && key !== 'topic_id' && key !== 'board_id' && key !== 'date_modified' && key !== 'test_logo' && key !== 'classData' && key !== 'subjectData' && key !== 'TopicData' && key !== 'created_by' && key !== 'test_date')
            .map((key) => {
              if (key === "active")
                return {
                  Header: 'status',
                  accessor: key,
                  Cell: ({ value }) => <h4><b>{value===0? <p style={{color: 'red'}}>InActive</p>: <p style={{color: 'green'}}>Active</p>}</b></h4>,
                  
                };
              if (key === "date_created")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <p className="w-[6rem]">{value}</p>
                  
                };
              if ( key === "class_master")
                return {
                  Header: 'Class',
                  accessor: key,
                  Cell: ({ value }) => <p>{value.class_name}</p>
                }
              if ( key === "subject_master")
                return {
                  Header: 'Subject',
                  accessor: key,
                  Cell: ({ value }) => <p>{value.subject}</p>
                }
              if ( key === "topic_master")
                return {
                  Header: 'Topic',
                  accessor: key,
                  Cell: ({ value }) => <p>{value.topic}</p>
                }
              if ( key === "adminMaster")
                return {
                  Header: 'Created By',
                  accessor: key,
                  Cell: ({ value }) => <p>{value.adminName}</p>
                }
              if ( key === "board_master")
                return {
                  Header: 'board',
                  accessor: key,
                  Cell: ({ value }) => <p>{value.board_name}</p>
                }
              return { Header: key, accessor: key };
            })
        : [],
    [props.testList]
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "ACTIONS",
        Header: "ACTIONS",
        Cell: ({ row }) => (
          <div className="flex">
            <Button 
              style={{marginRight: '1rem', width: '5rem'}}
              onClick={() => {
                navigate('/updatetest', {
                  state: {
                    testId: row.original.test_id,
                  }
                })
            }}>
              Edit
            </Button>
            <Button 
              style={{marginRight: '1rem', width: '5rem'}}
              onClick={() => {
                navigate('/testdetails', {
                  state: {
                    testId: row.original.test_id,
                  }
                })
            }}>
              View
            </Button>
            <Button 
              style={{width: '5rem'}}
              onClick={() => {
                props.setTestId(row.original.test_id)
                props.setOpenDelete(true)
                props.setTestName(row.original.test_name)
            }}>
              Delete
            </Button>
          </div>
        ),
      },
    ]);
  };

  const tableInstance = useTable(
    {
      columns: testColumns,
      data: testData,
    },
    useGlobalFilter,
    tableHooks,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = tableInstance;

  // useEffect(() => {
  //   fetchBoardsList();
  // }, []);

  const isEven = (idx) => idx % 2 === 0;

  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={state.globalFilter}
      />
      <Table {...getTableProps()} style={{maxWidth: '100%'}}>
        <TableHead className={"bg-[#1D72E8] text-white"}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeader
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header").toUpperCase().replace('_',' ')}
                  {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);

            return (
              <TableRow
                {...row.getRowProps()}
                className={!isEven(idx) ? "bg-[#e91e63] bg-opacity-30" : ""}
              >
                {row.cells.map((cell, idx) => (
                  <TableData {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableData>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}