import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { CSVLink } from "react-csv";
import axios from "axios";
import globalURL from "../../../config";
// import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import tw from "twin.macro";
import { GlobalFilter } from "./GlobalFilter";
import '../../../index.css'
import colors from "assets/theme/base/colors";
import ListContext from "layouts/apiContext/listContext";

const Table = tw.table`
  table-fixed
  text-base
  text-gray-900
`;

const TableHead = tw.thead`
  p-2
  bg-gradient-to-r
  from-[#F44335]
  to-[#f65f53]
`;

const TableRow = tw.tr`
  text-center
  border
  border-blue-500
`;

const TableHeader = tw.th`
  border
  border-blue-500
  p-2
`;

const TableBody = tw.tbody`

`;

const TableData = tw.td`
  border
  border-blue-500
  p-5
`;

const Button = tw.button`
  pl-4
  pr-4
  pt-2
  pb-2
  text-white
  rounded-md
  bg-gradient-to-r
  from-[#F44335]
  to-[#f65f53]
  hover:bg-blue-300
  hover:cursor-pointer
  transition-colors
`;

export function SubscriptionData(props) {

  const { fetchSubscriptionList } = useContext(ListContext);
  const [subscriptions, setSubscriptions] = useState();
  const [ adminType, setAdminType ] = useState('');
  const [ adminTypeList, setAdminTypeList ] = useState([]);
  const [ filterCategoryList, setFilterCategoryList ] = useState([]);
  const [ activity, setActivity ] = useState('ALL');
  const [ csvReport, setCsvReport ] = useState({
    data: subscriptions,
    headers: [
      { label:  "Subscription Id", key: "subscriptioinID"},
      { label: "Subscription Name", key: "subscription_name"},
      { label: "Subscription Type", key: "type"},
      { label: "Subscription Amount", key: "amount"},
      { label: "Valid Days", key: "valid_days"}
    ],
    filename: 'subscriptions.csv'
  });

  const activityChangeHandler = (e) => {
    setActivity(e.target.value)
    if (e.target.value === 'ALL') {
      setFilterCategoryList(subscriptions)
    }
    else {
      setFilterCategoryList(subscriptions.filter((brd) => brd.active === e.target.value))
    }
  }

  useEffect(() => {
    setSubscriptions(props.subscriptionList);
    setFilterCategoryList(props.subscriptionList);
  }, [props.subscriptionList])

  const filterButtonClickHandler = () => {
    if (adminType === "All") {
      setFilterCategoryList(subscriptions);
    }
    else {
      setFilterCategoryList(subscriptions.filter((subscriptions) => subscriptions.user_type === adminType))
    }
  }

  useEffect(() => {
    
    const headers = [
        { label: "Subscription Name", key: "subscription_name"},
        { label: "Subscription Type", key: "type"},
        { label: "Subscription Amount", key: "amount"},
        { label: "Valid Days", key: "valid_days"}
    ]

    setCsvReport({
      data: filterCategoryList,
      headers: headers,
      filename: 'Subscription List.csv'
    })

  }, [filterCategoryList])

  const usersData = useMemo(() => [...filterCategoryList], [filterCategoryList]);

  const usersColumns = useMemo(
    () =>
      filterCategoryList[0]
        ? Object.keys(filterCategoryList[0])
            .filter((key) => key !== "subscriptioinID" && key !== 'date_modified')
            .map((key) => {
              if (key === "active")
                return {
                  Header: 'status',
                  accessor: key,
                  Cell: ({ value }) => <b><h4>{value===0? <p style={{color: 'red'}}>InActive</p>: <p style={{color: 'green'}}>Active</p>}</h4></b>,
                  
                };
              if (key === "date_created")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <p style={{width: 90}}>{value}</p>,
                  
                };

              return { Header: key, accessor: key };
            })
        : [],
    [filterCategoryList]
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "ACTIONS",
        Header: "ACTIONS",
        Cell: ({ row }) => (
          <>
            {/* <Button 
              style={{width: '5rem'}}
              onClick={() => {
                props.setOpenMore(true)
            }}>
              View
            </Button> */}
            <Button 
              style={{margin: '0 1rem', width: '5rem'}}
              onClick={() => {
                props.setOpenupdate(true)
                props.setSubscriptionName(row.original.subscription_name)
                props.setType(row.original.type)
                props.setAmount(row.original.amount)
                props.setValidDays(row.original.valid_days)
                props.setSubscriptioinID(row.original.subscriptioinID)
                props.setActivity(row.original.active)
            }}>
              Edit
            </Button>
            <Button 
              style={{width: '5rem'}}
              onClick={() => {
                props.setOpenDelete(true)
                props.setSubscriptionName(row.original.subscription_name)
                props.setType(row.original.type)
                props.setAmount(row.original.amount)
                props.setValidDays(row.original.valid_days)
                props.setSubscriptioinID(row.original.subscriptioinID)
                props.setActivity(row.original.active)
            }}>
              Delete
            </Button>
          </>
        ),
      },
    ]);
  };

  const tableInstance = useTable(
    {
      columns: usersColumns,
      data: usersData,
    },
    useGlobalFilter,
    tableHooks,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = tableInstance;

  useEffect(() => {
    fetchSubscriptionList();
  }, []);

  const isEven = (idx) => idx % 2 === 0;

  return (
    <>
      <Grid container justifyContent={'space-between'} mb={5}>
        <Grid item xs={4} >
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
        </Grid>
        <Grid item lg={3}  md={8}>
          <FormControl 
            fullWidth
          >
            <InputLabel id="activityLabel">Activity Status: </InputLabel>
              <Select
                fullWidth
                style={{padding: 11}}
                labelId="activityLabel"
                id="activitySelect"
                value={activity}
                label="Activity Status: "
                onChange={activityChangeHandler}
              >
                <MenuItem value={'ALL'} style={{color: 'green', fontWeight: 'bold'}} >ALL</MenuItem>
                <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
                <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
              </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Subscription Name</InputLabel>
            <Select
              style={{padding: 10}}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={adminType}
              label="Subscription Name"
              onChange={(e) => setAdminType(e.target.value)}
            >
              <MenuItem value={'All'}>All</MenuItem>
              {filterCategoryList.map((admin) => {
                return <MenuItem value={admin.userType} key={Math.random().toString()}>{admin.userType}</MenuItem>
              })}
            </Select>
            <Button 
              onClick={filterButtonClickHandler} 
              variant='contained' 
              style={{marginTop: '1rem', color: '#fff', width: '100%'}} 
            >
              Filter
            </Button>
          </FormControl>
        </Grid> */}
      </Grid>
      <Grid container justifyContent={'space-between'}>
        <Grid item flex style={{background: '#e9423f', color: '#ffffff', fontSize: '1.2rem', padding: '0.8rem', textAlign: 'center', borderRadius: '50%', width: '70px', border: '4px solid',}}>
          <FormControl fullWidth>
            {filterCategoryList.length < 2 ? null :
              <CSVLink {...csvReport}>&#8595;</CSVLink>
            }
          </FormControl>
        </Grid>
      </Grid>
      <Table {...getTableProps()}>
        <TableHead className={"bg-[#1D72E8] text-white"}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={Math.random().toString()}>
              {headerGroup.headers.map((column) => (
                <TableHeader
                key={Math.random().toString()}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header").toUpperCase().replace('_',' ')}
                  {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);

            return (
              <TableRow
                {...row.getRowProps()}
                key={Math.random().toString()}
                className={!isEven(idx) ? "bg-[#e53b37] bg-opacity-30" : ""}
              >
                {row.cells.map((cell, idx) => (
                  <TableData {...cell.getCellProps()} key={Math.random().toString()}>
                    {cell.render("Cell")}
                  </TableData>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}