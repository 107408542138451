import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data

// Dashboard components
import React, { useContext, useEffect, useState } from "react";

import { Typography, InputLabel, MenuItem, FormControl, Select, Button, TextField, Card } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import BackupIcon from "@mui/icons-material/Backup";
// import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { SpinnerInfinity } from "spinners-react";
import globalURL from "../../config";
import ListContext from "layouts/apiContext/listContext";

function BestVideoUpload() {

  // const { 
  //   filteredClassList,
  //   fetchClassByBoard,
  //   boardList
  // } = useContext(ListContext);

  const [loading, setLoading] = useState(false);
  // const [ className, setClassName ] = useState('Choose');
  // const [ subjectName, setSubjectName ] = useState('Choose');
  // const [ topicName, setTopicName ] = useState('Choose');
  // const [ filteredSubjectList, setFilteredSubjectList ] = useState([]);
  // const [ filteredTopicList, setFilteredTopicList ] = useState([]);
  const [videoName, setVideoName] = useState('');
  const navigate = useNavigate();
  const [thumbnail, setThumbnail] = useState('');
  const [subtitle, setSubtitle] = useState('');
  // const [ board, setBoard ] = useState('Choose');
  const [error, setError] = useState(false);

  // const classChangeHandler = async (event) => {
  //   // setClas(event.target.value)

  //   if (event.target.value !== 'ALL') {
  //     return (await fetch (
  //       globalURL + "subject/get_subject_by_class", {
  //         method: "POST",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           class_id: event.target.value
  //         }),
  //       }
  //     )
  //     .then((res) => res.json())
  //     .then((res) => setFilteredSubjectList(res.data.data)))
  //   }

  // };

  // const subjectChangeHandler = async (event) => {
  //   // setSubject(event.target.value)

  //   async function getUserToken() {
  //     return await localStorage.getItem("userInfo");
  //   }
  //   if (event.target.value !== 'ALL') {
  //     getUserToken().then((userToken) => {
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
  //       },
  //       body: JSON.stringify({
  //         subject_id: event.target.value
  //       })
  //     }
  //     fetch (globalURL + "topic/get_topic_by_subject", 
  //       requestOptions
  //     )
  //     .then((res) => res.json())
  //     .then((res) => setFilteredTopicList(res.data.data))
  //     })
  //   }

  // };

  // const fetchSubjectList = async () => {

  //   return (await fetch(
  //     globalURL + "subject/get_allsubject"
  //   )
  //     .then((res) => res.json())
  //     .then((res) => {
  //       setFilteredSubjectList(res.data)
  //     })
  //   )
  // };

  // const fetchTopicList = async () => {

  //   async function getUserToken() {
  //     return await localStorage.getItem("userInfo");
  //   }
  //   getUserToken().then((userToken) => {
  //   const requestOptions = {
  //     method: "GET",
  //     headers: {
  //       'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
  //       // 'Authorization': "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1c2VyX2lkIjoiMSIsImVtYWlsIjoic2hpdmtyOTgxODhAZ21haWwuY29tIiwibG9naW5UaW1lIjoxNjUxNTcyODIwOTYwLCJnZW5lcmF0ZWRfYXQiOjE2NTE1NzI4MjA5NjAsImlhdCI6MTY1MTU3MjgyMCwiZXhwIjoxNjgzMTMwNDIwLCJpc3MiOiJSZWNydWl0In0.T4fHdvweSB4iFL7oXrKXiIh9u6IHvXQtVUfX-mafNZ3Gl3hJyC7AyXSCPgin_r_FRKz2nHnFe5a6epsbM_Idqw",
  //     },
  //   }
  //     fetch (
  //     globalURL + "topic/get_topiclist",
  //     requestOptions
  //   )
  //     .then((res) => res.json())
  //     .then((res) => {
  //       setFilteredTopicList(res.data)
  //     })
  //     .catch((e) => console.log('get_topiclist api is down'));
  //     })
  // };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "video/*": [".mp4", ".m4v"],
    },
  });

  const handleSubmit = async (value) => {

    setLoading(true);
    const formData = new FormData();
    formData.append('video', acceptedFiles[0])
    formData.append('thambnail', thumbnail.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''));
    if (subtitle !== "") {
      formData.append('subtitle', subtitle.replace('data:text/vtt;base64,', '').replace('data:application/octet-stream;base64,', ''));
      formData.append('filext', 'vtt');
    }
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
        },
        body: formData
      }
      fetch(
        globalURL + `adminVideo/add_best_video?video_name=${videoName}`,
        // globalURL + `adminVideo/add_video?class_id=${className}&subject_id=${subjectName}&topic_id=${topicName}&board_id=${board}&video_name=${videoName}`,
        requestOptions
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.message === 'Success') {
            setLoading(false);
            navigate("/bestvideolist");
            // navigate("/videolist", {
            //   state: {
            //     class: className,
            //     subject: subjectName,
            //     topic: topicName
            //   },
            // });
          }
          else {
            setLoading(false);
          }
        })
    })
  };

  // useEffect(() => {
  //   fetchSubjectList();
  //   fetchTopicList();
  // }, [])

  const uploadImage = async (image) => {

    if (image.size > 524288) {
      alert("thumbnail's size should be less than 512kb")
      setThumbnail('');
    }
    else {
      // const file = image.target.files[0]
      const base64 = await convertBase64(image)
      setThumbnail(base64);
    }


  }

  const uploadSub = async (image) => {

    const file = image.target.files[0]
    const base64 = await convertBase64(file)
    setSubtitle(base64);

  }

  const convertBase64 = (file) => {

    return new Promise((resolve, reject) => {

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = (() => {
        resolve(fileReader.result);
      });

      fileReader.onerror = ((error) => {
        reject(error);
      });

    });

  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ?
        <Grid container justifyContent={'center'} height={'80vh'}>
          <SpinnerInfinity
            size={190}
            thickness={180}
            speed={100}
            color="rgba(59, 172, 57, 1)"
            secondaryColor="rgba(57, 145, 172, 0.44)"
          />
        </Grid>
        : <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3} justifyContent={'space-between'}>
                <Grid item xs={6} {...getRootProps()}>
                  <Card>
                    <Grid
                      container
                      justifyContent="space-around"
                      spacing="10"
                      py={10}
                      style={{ textAlign: "center" }}
                    >
                      <Grid item xs={12}>
                        <input {...getInputProps()} />
                        {!acceptedFiles.length && (
                          <BackupIcon fontSize="large"></BackupIcon>
                        )}
                      </Grid>
                      {!acceptedFiles.length && (
                        <Grid item xs={12}>
                          <Typography variant="h6" component="h2">
                            Click or Drag and Drop Video Here
                          </Typography>
                        </Grid>
                      )}
                      {!!acceptedFiles.length && (
                        <Grid item mt={3.5} py={6}>
                          {acceptedFiles.map((file) => (
                            <Typography variant="h6" component="h2">
                              Video Selected
                            </Typography>
                          ))}
                        </Grid>
                      )}
                    </Grid>

                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing="10"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "green",
                            padding: "0 70px",
                            borderRadius: 0,
                          }}
                          size="large"
                        >
                          <AddIcon fontSize="large" color="white" />
                        </Button>
                      </Grid>

                      <Grid item>
                        <Typography variant="h6" component="h2">
                          Click to upload video here
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card>
                    <Grid
                      container
                      justifyContent="space-around"
                      spacing="10"
                      py={7.3}
                      style={{ textAlign: "center" }}
                    >
                      {!thumbnail && (
                        <Grid item xs={12} my={6}>
                          <Typography variant="h6" component="h2">
                            Click or Drag and Drop Thumbnail Here
                          </Typography>
                        </Grid>
                      )}
                      {thumbnail && (
                        <Grid item>
                          {thumbnail &&
                            <img
                              src={thumbnail}
                              style={{ display: 'flex', margin: 'auto', height: '10rem', width: '10rem' }}
                              alt=''
                            />
                          }
                        </Grid>
                      )}
                    </Grid>

                    <Grid
                      container
                      justifyContent="space-between"
                    >
                      <Grid container justifyContent={'space-between'}>
                        <Grid item xs={6}>
                          <InputLabel id="demo-simple-select-label">Thumbnail <span style={{ color: 'red' }}>size smaller than 512kb</span></InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel id="demo-simple-select-label">Subtitles</InputLabel>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent={'space-between'}>
                        <Grid item xs={6}>
                          <input
                            id='logo'
                            type="file"
                            multiple
                            accept=".png"
                            onChange={(e) => uploadImage(e.target.files[0])}
                          // onChange={(e) => setThumbnail(e.target.files)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <input
                            id='subs'
                            type="file"
                            multiple
                            accept=".vtt"
                            onChange={(sub) => uploadSub(sub)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  alignContent={"cener"}
                >
                  <Grid item>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="class-label">Options: </InputLabel>
                    </FormControl>
                  </Grid>
                  {/* <Grid item>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="board-label">Board</InputLabel>
                    <Select
                      value={board}
                      name="board"
                      label="Board"
                      labelId="board-label"
                      style={{lineHeight:'1.85rem'}}
                      onChange={(e) => {
                        setBoard(e.target.value)
                        fetchClassByBoard(e.target.value)
                      }}
                    >
                      <MenuItem value={'Choose'} key={Math.random().toString()} >CHOOSE</MenuItem>
                      {boardList?.map((brd) => {

                        if( brd.active === 1 ) return  <MenuItem value={brd.board_id} key={Math.random().toString()} >{brd.board_name}</MenuItem>

                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="class-label">Class</InputLabel>
                    <Select
                      value={className}
                      name="class"
                      label="Class"
                      labelId="class-label"
                      style={{lineHeight:'1.85rem'}}
                      onChange={(e) => {
                        setClassName(e.target.value)
                        classChangeHandler(e)
                      }}
                    >
                      <MenuItem value={'Choose'} key={Math.random().toString()}>CHOOSE</MenuItem>
                      {filteredClassList.map((clas) => {

                        if (clas.active === 1) return  <MenuItem label={clas.class_name} value={clas.class_id} key={Math.random().toString()} >{clas.class_name}</MenuItem>

                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="subject-label">Subject</InputLabel>
                    <Select
                      value={subjectName}
                      name="subject"
                      label="subject"
                      labelId="subject-label"
                      style={{lineHeight:'1.85rem'}}
                      onChange={(e) => {
                        setSubjectName(e.target.value)
                        subjectChangeHandler(e)
                      }}
                    >
                      <MenuItem value={'Choose'} key={Math.random().toString()}>CHOOSE</MenuItem>
                      {filteredSubjectList.map((subject) => {

                        if (subject.active === 1) return  <MenuItem label={subject.subject} value={subject.subject_id} key={Math.random().toString()} >{subject.subject}</MenuItem>

                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="board-label">Topic</InputLabel>
                    <Select
                      value={topicName}
                      name="topic"
                      label="topic"
                      labelId="topic-label"
                      style={{lineHeight:'1.85rem'}}
                      onChange={(e) => setTopicName(e.target.value)}
                    >
                      <MenuItem value={'Choose'} key={Math.random().toString()}>CHOOSE</MenuItem>
                      {filteredTopicList.map((topic) => {
                        if (topic.active === 1) return  <MenuItem value={topic.topic_id} key={Math.random().toString()} >{topic.topic}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid> */}
                  <Grid item>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <TextField
                        id="outlined-basic"
                        label="Video Name"
                        variant="standard"
                        value={videoName}
                        onChange={(e) => setVideoName(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3} justifyContent={"space-evenly"} mt={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "green",
                      padding: "0 100px",
                      borderRadius: 20,
                      margin: "10px",
                      color: "white",
                    }}
                    size="large"
                    startIcon={<BackupIcon />}
                    onClick={handleSubmit}
                    disabled={
                      !videoName
                        ? true
                        : false
                    }
                  >
                    <Typography variant="span">upload</Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    href='/bestvideolist'
                    variant="contained"
                    style={{
                      backgroundColor: "green",
                      padding: "0 70px",
                      borderRadius: 20,
                      margin: "10px",
                      color: "white",
                    }}
                    size="large"
                    startIcon={<BackupIcon />}
                  >
                    <Typography variant="span">Show Best Video List</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>}
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default BestVideoUpload;
