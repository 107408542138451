/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { useEffect, useState } from "react";
import { UserData } from "./data/UserData";
import tw from "twin.macro";
import MDButton from "components/MDButton";
import { Box, FormControl, FormGroup, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import globalURL from "../../config";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// import { Carousel } from "react-responsive-carousel";
import '../../index.css'
import ListContext from "layouts/apiContext/listContext";
import { useContext } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: '2rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AppContainer = tw.div`
  w-full
  max-w-full
  flex
  flex-col
  items-stretch
  justify-center
  pt-6
  pb-10
  pl-10
  pr-10
`;

function Tables() {
  
  const { 
    classList,
    subjectList,
    topicList,
    videoList,
    fetchUserList,
    bannerList,
    userList
  } = useContext(ListContext);

  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ mobileNo, setMobileNo ] = useState('');
  const [ userId, setUserId ] = useState('');
  const [ city, setCity ] = useState('');
  const [ country, setCountry ] = useState('');
  const [ dob, setDob ] = useState('');
  const [ gender, setGender ] = useState('');
  const [ postcode, setPostcode ] = useState('');
  const [ userType, setUserType ] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [address, setAddress] = useState('');
  const [ userTypeList, setUserTypeList ] = useState([]);
  const [ activity, setActivity ] = useState('');
  const [ rowData, setRowData ] = useState({});

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [errorSB, setErrorSB] = useState(false);

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [openupdate, setOpenupdate] = useState(false);
  const handleCloseupdate = () => setOpenupdate(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => setOpenDelete(false);

  const [openNone, setOpenNone] = useState(false);
  const handleCloseNone = () => setOpenNone(false);

  const [ message, setMessage ] = useState('');
  const [ openMessage, setOpenMessage ] = useState(false);
  const handleCloseMessage = () => setOpenMessage(false);

  const [openMore, setOpenMore] = useState(false);
  const handleCloseMore = () => {
    setOpenMore(false)
    setGender('');
    setCity('');
    setCountry('');
  };

  useEffect(() => {
    fetchUserTypeList();
  }, [])

  const fetchUserTypeList = async () => {

    return (
      await fetch(
      globalURL + "userType/show_userType"
    )
    .then((res) => res.json())
    .then((res) => setUserTypeList(res.data))
    .catch((e) => console.log('get_classes api is down'))
    )

  }

  const handleAPI = async () => {
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
      },
      body: JSON.stringify({
        "first_name": firstName,
        "last_name":lastName,
        "email":email,
        "mobile_no": mobileNo,
        "city":address,
        "country":'India',
        "dob":dob,
        "gender":gender,
        "postcode":postcode,
        "board":'',
        "class":'',
        "userType":userType,
        "class_id": "class_a6c3d7eaa841"
      }),
    };
    fetch(
      globalURL + "admin/user_create",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          handleClose();
          setFirstName("");
          setMessage(res.message);
          setOpenMessage(true);
          setLastName('');
          setEmail('');
          setMobileNo('');
          setCity('');
          setCountry('');
          setDob('');
          setGender('');
          setPostcode('');
          setUserType('');
          openSuccessSB();
        } else {
          handleClose();
          openErrorSB();
          setMessage(res.message);
          setOpenMessage(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    })
  };

  const updateAPI = async () => {
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
        // 'Authorization': "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1c2VyX2lkIjoiMSIsImVtYWlsIjoic2hpdmtyOTgxODhAZ21haWwuY29tIiwibG9naW5UaW1lIjoxNjUxNTcyODIwOTYwLCJnZW5lcmF0ZWRfYXQiOjE2NTE1NzI4MjA5NjAsImlhdCI6MTY1MTU3MjgyMCwiZXhwIjoxNjgzMTMwNDIwLCJpc3MiOiJSZWNydWl0In0.T4fHdvweSB4iFL7oXrKXiIh9u6IHvXQtVUfX-mafNZ3Gl3hJyC7AyXSCPgin_r_FRKz2nHnFe5a6epsbM_Idqw",
      },
      body: JSON.stringify({
        "user_id": userId,
        "first_name": firstName,
        "last_name":lastName,
        "email":email,
        "mobile_no": mobileNo,
        "city":city,
        "country":country,
        "dob":dob,
        "gender":gender,
        "postcode":postcode,
        // active: activity
      }),
    };
    fetch(
      globalURL + "admin/update_user",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          handleCloseupdate();
          setUserId('');
          setFirstName("");
          setMessage(res.message);
          setOpenMessage(true);
          setLastName('');
          setEmail('');
          setMobileNo('');
          setCity('');
          setCountry('');
          setDob('');
          setGender('');
          setPostcode('');
          fetchUserList();
          openSuccessSB();
        } else {
          setMessage(res.message);
          setOpenMessage(true);
          handleCloseupdate();
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    })
  };

  const deleteUser = async () => {
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
      },
      body: JSON.stringify({
        user_id: userId,
      }),
    };
    fetch(
      globalURL + "admin/delete_user",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          handleCloseDelete();
          setUserId('');
          setMessage(res.message);
          setOpenMessage(true);
          openSuccessSB();
        } else {
          setUserId('');
          setMessage(res.message);
          setOpenMessage(true);
          handleCloseDelete();
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    })
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="User Updated Successfully"
      content="Your User is now updated successfully"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content="Getting Error while updating User"
      dateTime="Just Now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} xl={4}>
              {bannerData.length &&
                <Carousel autoPlay={true} infiniteLoop={true} interval={3000} showThumbs={false}>
                {bannerData.map((image) => {
                  return (
                    <div key ={image.banner_id}>
                      <img src={image.banner} style={{height: '18.1rem', width: '30rem'}}/>
                      <p className="legend">{image.date_created}</p>
                    </div>
                  );
                })}
              </Carousel>}
            </Grid> */}
            <Grid item xs={12} md={6} xl={2}>
              <DefaultInfoCard
                icon="person"
                color="success"
                title="Users"
                description="New Users"
                value={userList.length}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={2}>
              <DefaultInfoCard
                color="success"
                icon="pause"
                title="Videos"
                description="Total Videos"
                value={videoList.length}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={2}>
              <DefaultInfoCard
                icon="school"
                color="success"
                title="Class"
                description="Total Classes"
                value={classList.length}
              />
              {/* <Invoices /> */}
            </Grid>
            <Grid item xs={12} md={6} xl={2}>
              <DefaultInfoCard
                color="success"
                icon="subject"
                title="Subject"
                description="Total Subjects"
                value={subjectList.length}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={2}>
              <DefaultInfoCard
                icon="photo"
                color="success"
                title="Banners"
                description="Total Banners"
                value={bannerList.length}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={2}>
              <DefaultInfoCard
                icon="list"
                color="success"
                title="Topics"
                description="Total Topics"
                value={topicList.length}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
              <Typography
                  // variant="contained"
                  size="small"
                  // onClick={() => setOpen(true)}
                  className={'text-[#ffffff]'}
              >
                User List
              </Typography>

              {/* <Typography
                id="modal-modal-title"
                variant="contained"
                style={{color: '#1D72E8', background: '#ffffff'}}
                component="h2"
              >
                User List
              </Typography> */}
              {/* <MDButton
                variant="contained"
                color="white"
                size="small"
                onClick={handleOpen}
              >
                Create new Admin
              </MDButton> */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                  >
                    Update User
                  </Typography>
                  <Grid container m={'1.5rem 0'} py={2} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="FIRST NAME"
                        variant="outlined"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="LAST NAME"
                        variant="outlined"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <FormControl fullWidth >
                          <FormGroup>
                            <InputLabel id="genderSelectLabel">GENDER</InputLabel>
                            <Select
                              style={{padding: 12}}
                              labelId="genderSelectLabel"
                              id="genderSelect"
                              label="GENDER"
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <MenuItem value={'MALE'}>MALE</MenuItem>
                              <MenuItem value={'FEMALE'}>FEMALE</MenuItem>
                              <MenuItem value={'OTHERS'}>OTHERS</MenuItem>
                            </Select>
                          </FormGroup>
                        </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mb={'1.5rem'} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="EMAIL"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <FormControl style={{width: '100%'}}>
                        <TextField
                          fullWidth
                          type='date'
                          id="outlined-basic"
                          variant="outlined"
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="POST CODE"
                        variant="outlined"
                        value={postcode}
                        onChange={(e) => setPostcode(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container mb={'1.5rem'} pt={2} justifyContent={'space-evenly'}>
                    {/* <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="ADDRESS"
                        variant="outlined"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Grid> */}
                    {/* <Grid item xs={3.5}>
                      <FormControl fullWidth >
                        <FormGroup>
                          <InputLabel id="adminTypeSelectLabel">DESIGNATION</InputLabel>
                          <Select
                            style={{padding: 12}}
                            labelId="adminTypeSelectLabel"
                            id="adminTypeSelect"
                            label="DESIGNATION"
                            value={userType}
                            onChange={(e) => setUserType(e.target.value)}
                          >
                            {userTypeList.map((ut) => {
                              return <MenuItem value={ut.userType} key={ut.userType_id}>{ut.userType}</MenuItem>
                            })}
                          </Select>
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="EMPLOYEE ID"
                        variant="outlined"
                        value={employeeId}
                        onChange={(e) => setEmployeeId(e.target.value)}
                      />
                    </Grid> */}
                  </Grid>
                  <Grid container mb={'1.5rem'} py={2} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="MOBILE NO"
                        variant="outlined"
                        value={mobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={'space-evenly'}>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={updateAPI}
                      >
                        SEND
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="medium"
                        onClick={handleClose}
                        >
                        CLOSE
                      </MDButton>
                    </Grid>                        
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openupdate}
                onClose={handleCloseupdate}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                  >
                    Update User
                  </Typography>
                  <Grid container m={'1.5rem 0'} py={2} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="FIRST NAME"
                        variant="outlined"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="LAST NAME"
                        variant="outlined"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <FormControl fullWidth >
                          <FormGroup>
                            <InputLabel id="genderSelectLabel">GENDER</InputLabel>
                            <Select
                              style={{padding: 12}}
                              labelId="genderSelectLabel"
                              id="genderSelect"
                              label="GENDER"
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <MenuItem value={'MALE'}>MALE</MenuItem>
                              <MenuItem value={'FEMALE'}>FEMALE</MenuItem>
                              <MenuItem value={'OTHERS'}>OTHERS</MenuItem>
                            </Select>
                          </FormGroup>
                        </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mb={'1.5rem'} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="EMAIL"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <FormControl style={{width: '100%'}}>
                        <TextField
                          fullWidth
                          type='date'
                          id="outlined-basic"
                          variant="outlined"
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="POST CODE"
                        variant="outlined"
                        value={postcode}
                        onChange={(e) => setPostcode(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container mb={'1.5rem'} py={2} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="MOBILE NO"
                        variant="outlined"
                        value={mobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="CITY"
                        variant="outlined"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3.5} mt={-1.4}>
                      <InputLabel id="activityLabel">Activity Status: </InputLabel>
                      <Select
                        fullWidth
                        style={{padding: 11}}
                        labelId="activityLabel"
                        id="activitySelect"
                        value={activity}
                        label="Activity Status: "
                        onChange={(e) => setActivity(e.target.value)}
                      >
                        <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
                        <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={'space-evenly'}>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={updateAPI}
                      >
                        SEND
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="medium"
                        onClick={handleCloseupdate}
                        >
                        CLOSE
                      </MDButton>
                    </Grid>                        
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: 'center'}}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                    style={{marginBottom: '2rem'}}
                  >
                    Are You Sure You Want To Delete This User?
                  </Typography>
                  {firstName?.toUpperCase()} {lastName?.toUpperCase()}
                  <Grid 
                    container 
                    justifyContent={'space-evenly'}
                    style={{ marginTop: "2rem" }}
                  >
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={deleteUser}
                      >
                        Delete
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="small"
                        onClick={handleCloseDelete}
                      >
                        CLOSE
                      </MDButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openMore}
                onClose={handleCloseMore}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: 'center'}}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                    style={{marginBottom: '2rem'}}
                  >
                    More Info About: -
                  </Typography>
                  {firstName?.toUpperCase()} {lastName?.toUpperCase()}
                  <Grid container m={'1.5rem 0'} py={2} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="NAME"
                        variant="outlined"
                        value={firstName?.toUpperCase() + ' ' + lastName?.toUpperCase()}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="EMAIL"
                        variant="outlined"
                        value={email?.toUpperCase()}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="DOB"
                        variant="outlined"
                        // value={gender?.toUpperCase()}
                        value={dob}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid container m={'1.5rem 0'} py={2} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="MOBILE NO"
                        variant="outlined"
                        value={mobileNo}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="USER TYPE"
                        variant="outlined"
                        value={userType?.toUpperCase()}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="GENDER"
                        variant="outlined"
                        value={gender?.toUpperCase()}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid container m={'1.5rem 0'} py={2} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="CITY"
                        variant="outlined"
                        value={city?.toUpperCase()}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="COUNTRY"
                        variant="outlined"
                        value={country?.toUpperCase()}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid 
                    container 
                    justifyContent={'space-evenly'}
                    style={{ marginTop: "2rem" }}
                  >
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="small"
                        onClick={handleCloseMore}
                      >
                        CLOSE
                      </MDButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openNone}
                onClose={handleCloseNone}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: 'center'}}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                    style={{marginBottom: '2rem'}}
                  >
                    No Data Available!!
                  </Typography>
                  <Grid 
                    container 
                    justifyContent={'center'}
                    style={{ marginTop: "2rem" }}
                  >
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="small"
                        onClick={handleCloseNone}
                      >
                        CLOSE
                      </MDButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openMessage}
                onClose={handleCloseMessage}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: 'center'}}>
                  <Grid item xs={12}>
                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h2"
                      style={{marginBottom: '2rem',}}
                    >
                      {message}
                    </Typography>
                  </Grid>
                  <Grid 
                    container 
                    justifyContent={'center'}
                    style={{ marginTop: "2rem" }}
                  >
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="small"
                        onClick={handleCloseMessage}
                      >
                        CLOSE
                      </MDButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
              
              {renderSuccessSB}
              {renderErrorSB}
              </MDBox>
              <MDBox pt={3} style={{height: '44rem', overflow: 'auto'}}>
                <AppContainer>
                  <UserData
                    setOpenupdate={setOpenupdate}
                    setUserId={setUserId}
                    setFirstName={setFirstName}
                    setLastName={setLastName}
                    setEmail={setEmail}
                    setMobileNo={setMobileNo}
                    setCity={setCity}
                    setCountry={setCountry}
                    setDob={setDob}
                    handleOpen={handleOpen}
                    setGender={setGender}
                    setPostcode={setPostcode}
                    setActivity={setActivity}
                    setUserType={setUserType}
                    setOpenDelete={setOpenDelete}
                    setOpenMore={setOpenMore}
                    setOpenNone={setOpenNone}
                    setRowData={setRowData}
                    userList={userList}
                  />
                </AppContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;