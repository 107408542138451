import { createContext, useState } from 'react';

const AuthContext = createContext();

export function AuthProvider({ children }) {

    const [ adminName, setAdminName ] = useState('');

    const [ isAuth, setIsAuth ] = useState(true? localStorage.getItem('userInfo') : false)

    return (
        <AuthContext.Provider value={{isAuth, setIsAuth, adminName, setAdminName}}>
            {children}
        </AuthContext.Provider>
    )

}

export default AuthContext;