
import React, { useContext, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import tw from "twin.macro";
import { GlobalFilter } from "./GlobalFilter";
import '../../../index.css'
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import ListContext from "layouts/apiContext/listContext";

const Table = tw.table`
  table-fixed
  text-base
  text-gray-900
`;

const TableHead = tw.thead`
  border
  border-blue-500
  bg-gradient-to-r
  from-[#fb8c00]
  to-[#fc9d26]
  p-2
`;

const TableRow = tw.tr`
  text-center
  border
  border-blue-500
`;

const TableHeader = tw.th`
  border
  border-blue-500
  p-2
`;

const TableBody = tw.tbody`

`;

const TableData = tw.td`
  border
  border-blue-500
  p-5
`;

const Button = tw.button`
  pl-4
  pr-4
  pt-2
  pb-2
  text-white
  rounded-md
  bg-[#fb8c00]
  hover:bg-blue-300
  hover:cursor-pointer
  transition-colors
`;

export function ClassData(props) {
  
  const { 
    classList,
    boardList
  } = useContext(ListContext);

  const [ filteredClassList, setFilteredClassList ] = useState(classList);
  const [ board, setBoard ] = useState('ALL');
  const clasData = useMemo(() => [...filteredClassList], [filteredClassList]);
  const [ activity, setActivity ] = useState('ALL');
  const [ csvReport, setCsvReport ] = useState({
    data: clasData,
    headers: [
      { label: "Class Name", key: "class_name" },
      { label: "Medium", key: "medium"},
      { label: "Status", key: "active"},
      { label: "Date Created", key: "date_created"}
    ],
    filename: 'Class List.csv'
  });

  const activityChangeHandler = (e) => {
    setActivity(e.target.value)
    if (e.target.value === 'ALL') {
      setFilteredClassList(classList)
    }
    else {
      setFilteredClassList(classList.filter((brd) => brd.active === e.target.value))
    }
  }

  const boardChangeHandler = (e) => {
    setBoard(e.target.value);
    if (e.target.value === 'ALL') {
      setFilteredClassList(classList)
    }
    else {
      setFilteredClassList(classList.filter((cls) => cls.board_id === e.target.value))
    }

  }

  useEffect(() => {
    setFilteredClassList(classList);
  }, [classList])

  useEffect(() => {

    
    const headers = [
      { label: "Class Name", key: "class_name" },
      { label: "Medium", key: "medium"},
      { label: "Status", key: "active"},
      { label: "Date Created", key: "date_created"},
    ]

    // do {
      setCsvReport({
        data: clasData,
        headers: headers,
        filename: 'Class List.csv'
      })
    // }
    // while (clasData.length > 2) 

  }, [clasData])

  const clasColumns = useMemo(
    () =>
      filteredClassList[0]
        ? Object.keys(filteredClassList[0])
            .filter((key) => key !== "date_modified" &&  key !=='class_id' &&  key !=='board_id' &&  key !=='backgroud' &&  key !=='created_by')
            .map((key) => {
              if (key === "logo")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => !value? null : <img 
                    src={value} 
                    style={{ margin: 'auto', width: 70, height: 70 }} 
                    alt=''
                  />,
                };
                if (key === "active")
                  return {
                    Header: 'Status',
                    accessor: key,
                    Cell: ({ value }) => <h4><b>{value===0? <p style={{color: 'red'}}>InActive</p>: <p style={{color: 'green'}}>Active</p>}</b></h4>,
                    
                  };
              if (key === "date_created")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <p>{value.slice(0,-14)}</p>,
                };
                if (key === "adminMaster")
                  return {
                    Header: "Created By",
                    accessor: key,
                    Cell: ({ value }) => <p>{value.adminName}</p>,
                    
                  };
                if (key === "board_master")
                  return {
                    Header: "Board",
                    accessor: key,
                    Cell: ({ value }) => <p>{value.board_name}</p>,
                    
                  };

              return { Header: key, accessor: key };
            })
        : [],
    [filteredClassList]
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "ACTIONS",
        Header: "ACTIONS",
        Cell: ({ row }) => {
          return (
          <>

            <Button 
              style={{marginRight: '1rem', width: '5rem'}}
              onClick={() => {
                props.setUpdatedClassName(row.original.class_name);
                props.setUpdate_index(row.original.index);
                props.setUpdatedClassId(row.original.class_id);
                props.setUpdatedMedium(row.original.medium);
                props.setActivity(row.original.active);
                props.setBoardName(row.original.board_id);
                props.setOldLogo(row.original.logo);
                props.setOpenupdate(true);
            }}>
              Edit
            </Button>
            <Button 
              style={{width: '5rem'}}
              onClick={() => {
                props.setUpdatedClassName(row.original.class_name)
                props.setUpdatedClassId(row.original.class_id)
                props.setOpenDelete(true)
            }}>
              Delete
          </Button>
          </>
        )},
      },
    ]);
  };

  const tableInstance = useTable(
    {
      columns: clasColumns,
      data: clasData,
    },
    useGlobalFilter,
    tableHooks,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = tableInstance;

  const isEven = (idx) => idx % 2 === 0;

  return (
    <>
    <Grid container justifyContent={'space-between'} mb={5}>
      <Grid item xs={4}>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={state.globalFilter}
        />
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel id="board-label">Board: </InputLabel>
          <Select
            value={board}
            name="board"
            label="Board: "
            labelId="board-label"
            style={{ padding: 11}}
            onChange={boardChangeHandler}
          >
            <MenuItem value={'ALL'} key={Math.random().toString()} >ALL</MenuItem>
            {boardList?.map((brd) => {

              if( brd.active === 1 ) return  <MenuItem value={brd.board_id} key={Math.random().toString()} >{brd.board_name}</MenuItem>

            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl 
          fullWidth
        >
          <InputLabel id="activityLabel">Activity Status: </InputLabel>
            <Select
              fullWidth
              style={{padding: 11}}
              labelId="activityLabel"
              id="activitySelect"
              value={activity}
              label="Activity Status: "
              onChange={activityChangeHandler}
            >
              <MenuItem value={'ALL'} style={{color: 'green', fontWeight: 'bold'}} >ALL</MenuItem>
              <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
              <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
            </Select>
        </FormControl>
      </Grid>
    </Grid>
      <Grid container justifyContent={'space-between'}>
        <Grid item flex style={{background: '#fb8c00', color: '#ffffff', fontSize: '1.2rem', padding: '0.8rem', textAlign: 'center', borderRadius: '50%', width: '70px', border: '4px solid',}}>
          <FormControl fullWidth>
            {clasData.length < 2 ? null :
              <CSVLink {...csvReport}>&#8595;</CSVLink>
            }
          </FormControl>
        </Grid>
      </Grid>
      <Table {...getTableProps()} style={{maxWidth: '100%'}}>
        <TableHead className={"bg-[#1D72E8] text-white"}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeader
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header").toUpperCase().replace('_',' ')}
                  {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);

            return (
              <TableRow
                {...row.getRowProps()}
                className={!isEven(idx) ? "bg-[#fb8c00] bg-opacity-30" : ""}
              >
                {row.cells.map((cell, idx) => (
                  <TableData {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableData>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}