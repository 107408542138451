/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { useState, useContext } from "react";
import { BoardData } from "./data/BoardData";
import tw from "twin.macro";
import MDButton from "components/MDButton";
import { Box, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import { SpinnerInfinity } from "spinners-react";

import globalURL from "../../config";
import '../../index.css'
import ListContext from "layouts/apiContext/listContext";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: '2rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AppContainer = tw.div`
  w-full
  max-w-full
  flex
  flex-col
  items-stretch
  justify-center
  pt-6
  pb-10
  pl-10
  pr-10
`;

function Tables() {
  
  const { 
    boardList,
    fetchBoardList
  } = useContext(ListContext);

  const [ boardName, setBoardName ] = useState('');
  const [ updatedBoardId, setUpdatedBoardId ] = useState('');
  // const [ boardsList, setBoardsList ] = useState([]);
  const [activity, setActivity] = useState('');
  const [ loading, setLoading ] = useState(false);
  
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setBoardName('');
  };
  const [errorSB, setErrorSB] = useState(false);
  
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [openupdate, setOpenupdate] = useState(false);
  const handleCloseupdate = () => {
    setOpenupdate(false);
    setBoardName('');
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => {
    setOpenDelete(false);
    setBoardName('');
  };

  const [ message, setMessage ] = useState('');
  const [ openMessage, setOpenMessage ] = useState(false);
  const handleCloseMessage = () => setOpenMessage(false);

  const createBoard = async () => {
    setLoading(true);
    handleClose();
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
      },
      body: JSON.stringify({
        'created_by': '1',
        'board_name': boardName,
      })
    };
    fetch(
      globalURL + "board_master/create_board",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          setMessage(res.message);
          setOpenMessage(true);
          setBoardName('');
          setLoading(false);
          handleClose();
          fetchBoardList();
          // setcreated_by("");
          openSuccessSB();
        } else {
          setMessage(res.message);
          setOpenMessage(true);
          setLoading(false);
          fetchBoardList();
          handleClose();
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    })
  };

  const Updateapi = async () => {
    setLoading(true);
    handleCloseupdate();
    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
      },
      body: JSON.stringify({
        'board_name': boardName,
        'board_id': updatedBoardId,
        active: activity
      })
    };
    fetch(
      globalURL + "board_master/update_board",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {

          handleCloseupdate();
          setBoardName('');
          setMessage(res.message);
          setLoading(false);
          setOpenMessage(true);
          fetchBoardList();
          setUpdatedBoardId('');
          setOpenupdate(false);
          openSuccessSB();
        } else {
          handleCloseupdate();
          setMessage(res.message);
          setOpenMessage(true);
          setLoading(false);
          fetchBoardList();
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    })
  };

  const deleteBoard = async () => {
    setLoading(true);
    handleCloseDelete();
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        board_id: updatedBoardId,
      }),
    };
    await fetch(
      globalURL + "board_master/delete_board",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          handleCloseDelete();
          fetchBoardList();
          setUpdatedBoardId("");
          setMessage(res.message);
          setLoading(false);
          setOpenMessage(true);
          setBoardName("");
          openSuccessSB();
        } else {
          setUpdatedBoardId("");
          fetchBoardList();
          setMessage(res.message);
          setLoading(false);
          setOpenMessage(true);
          setBoardName("");
          handleCloseDelete();
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Board Updated Successfully"
      content="Your Board is now updated successfully"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content="Getting Error while updating Board"
      dateTime="Just Now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                coloredShadow="secondary"
              >
                <MDButton
                  variant="contained"
                  size="small"
                  onClick={handleOpen}
                  className={'text-[#000000] bg-white'}
                >
                  Create new board
                </MDButton>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h3"
                      component="h2"
                    >
                      Create New Board
                    </Typography>
                    <div>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="BOARD NAME"
                        variant="outlined"
                        value={boardName}
                        style={{ margin: "2rem auto" }}
                        onChange={(e) => setBoardName(e.target.value)}
                      />
                    </div>
                    <Grid container justifyContent={'space-evenly'}>
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={createBoard}
                          disabled={
                            !boardName ? true : false
                          }
                        >
                          SEND
                        </MDButton>

                      </Grid>
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="dark"
                          size="small"
                          onClick={handleClose}
                        >
                          CLOSE
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                <Modal
                  open={openupdate}
                  onClose={handleCloseupdate}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h3"
                      component="h2"
                    >
                      Update Your Board
                    </Typography>
                    <Grid container m={'1.5rem 0'} py={2} justifyContent={'space-around'}>
                      <Grid item xs={5.5}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="BOARD NAME"
                          variant="outlined"
                          value={boardName}
                          onChange={(e) => setBoardName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={5.5} mt={-1.4}>
                        <InputLabel id="activityLabel">Activity Status: </InputLabel>
                        <Select
                          fullWidth
                          style={{padding: 11}}
                          labelId="activityLabel"
                          id="activitySelect"
                          value={activity}
                          label="Activity Status: "
                          onChange={(e) => setActivity(e.target.value)}
                        >
                          <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
                          <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid 
                      container 
                      justifyContent={'space-evenly'}
                      style={{ marginTop: "20px" }}
                    >
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={Updateapi}
                          disabled={
                            !boardName? true : false
                          }
                        >
                          Update
                        </MDButton>
                      </Grid>
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="dark"
                          size="small"
                          onClick={handleCloseupdate}
                        >
                          CLOSE
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                <Modal
                  open={openDelete}
                  onClose={handleCloseDelete}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} style={{textAlign: 'center'}}>
                    <Typography
                      id="modal-modal-title"
                      variant="h3"
                      component="h2"
                      style={{marginBottom: '2rem'}}
                    >
                      Are You Sure You Want To Delete This Board?
                    </Typography>
                    {boardName}
                    <Grid 
                      container 
                      justifyContent={'space-evenly'}
                      style={{ marginTop: "2rem" }}
                    >
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={deleteBoard}
                        >
                          Delete
                        </MDButton>
                      </Grid>
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="dark"
                          size="small"
                          onClick={handleCloseDelete}
                        >
                          CLOSE
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                <Modal
                  open={openMessage}
                  onClose={handleCloseMessage}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} style={{textAlign: 'center'}}>
                    <Grid item xs={12}>
                      <Typography
                        id="modal-modal-title"
                        variant="h4"
                        component="h2"
                        style={{marginBottom: '2rem',}}
                      >
                        {message}
                      </Typography>
                    </Grid>
                    <Grid 
                      container 
                      justifyContent={'center'}
                      style={{ marginTop: "2rem" }}
                    >
                      <Grid item xs={4}>
                        <MDButton
                          fullWidth
                          variant="contained"
                          color="dark"
                          size="small"
                          onClick={handleCloseMessage}
                        >
                          CLOSE
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                {renderSuccessSB}
                {renderErrorSB}
              </MDBox>
              <MDBox pt={3} style={{height: '44rem', overflow: 'auto'}}>
                {loading ? 
                  <Grid container justifyContent={'center'}height={'80vh'}>
                    <SpinnerInfinity 
                      size={190} 
                      thickness={180} 
                      speed={100} 
                      color="rgba(59, 172, 57, 1)" 
                      secondaryColor="rgba(57, 145, 172, 0.44)" 
                    />
                  </Grid> :
                  <AppContainer>
                    <BoardData 
                      setBoardName={setBoardName}
                      setUpdatedBoardId={setUpdatedBoardId}
                      setActivity={setActivity}
                      setOpenupdate={setOpenupdate}
                      setOpenDelete={setOpenDelete}
                      boardList={boardList}
                    />
                  </AppContainer>
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;