import Grid from "@mui/material/Grid";
import React, { useEffect, useState, useContext } from "react";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import { Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import { useLocation } from "react-router-dom";
import { SpinnerInfinity } from "spinners-react";
import globalURL from "../../config";
import MDSnackbar from "components/MDSnackbar";
import ListContext from "layouts/apiContext/listContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: '2rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


function ConceptList() {
  
  const { 
    classList,
    filteredClassList,
    fetchClassByBoard,
    subjectList,
    filteredSubjectList,
    filteredTopicList,
    fetchSubjectByClass,
    fetchTopicBySubject,
    topicList,
    boardList
  } = useContext(ListContext);
  
  const [ filteredConceptList, setFilteredConceptList ] = useState([]);
  const [ conceptList, setConceptList ] = useState([]);
  const [ filteredClassList1, setFilteredClassList1 ] = useState(classList);
  const [ filteredSubjectList1, setFilteredSubjectList1 ] = useState(subjectList);
  const [ filteredTopicList1, setFilteredTopicList1 ] = useState(topicList);
  const [ board, setBoard ] = useState('ALL');
  const [ clas, setClas ] = useState('ALL');
  const [ subject, setSubject ] = useState('ALL');
  const [ topic, setTopic ] = useState('ALL');
  const [ loading, setLoading ] = useState(true);
  const location = useLocation();
  const [ updatedBoard, setUpdatedBoard ] = useState('');
  const [ updatedClassId, setUpdatedClassId ] = useState('');
  const [ updatedSubjectId, setUpdatedSubjectId ] = useState('');
  const [ updatedTopicId, setUpdatedTopicId ] = useState('');
  // const [ activity, setActivity ] = useState();
  // const [ updatedMedium, setUpdatedMedium ] = useState('');
  const [ updatedConceptName, setUpdatedConceptName ] = useState('');
  const [ updatedConceptId, setUpdatedConceptId ] = useState('');
  const [ activity, setActivity] = useState();
  const [ concept, setConcept ] = useState('');
  
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [openupdate, setOpenupdate] = useState(false);
  const handleCloseupdate = () => setOpenupdate(false);

  const [openNone, setOpenNone] = useState(false);
  const handleCloseNone = () => setOpenNone(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const boardFilterVideoHandler = async (event) => {
    setBoard(event.target.value)

    if ( event.target.value === 'ALL' ) {
      setFilteredConceptList(conceptList)
      setFilteredClassList1(classList.filter((dt) => dt.active !== 0 && dt.active !== 2))
    }
    else {
      setFilteredConceptList(conceptList.filter((cpt) => cpt.board_id === event.target.value))
    }

    if (event.target.value !== 'ALL') {
      return ( await fetch (
        globalURL + `class_master/get_classes?board_id=${event.target.value}`
      )
      .then((res) => res.json())
      .then((res) => {
        setFilteredClassList1(res.data.filter((dt) => dt.active !== 0 && dt.active !== 2))
      })
      )
    }

  }

  const classFilterConceptHandler = async (event) => {
    setClas(event.target.value)

    if ( event.target.value === 'ALL' ) {
      setFilteredConceptList(conceptList)
      setFilteredSubjectList1(subjectList.filter((dt) => dt.active !== 0 && dt.active !== 2))
    }
    else (
      setFilteredConceptList(conceptList.filter((concept) => concept.class_id === event.target.value))
    )

    if (event.target.value !== 'ALL') {
      return ( await fetch (
        globalURL + "subject/get_subject_by_class", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            class_id: event.target.value
          }),
        }
      )
      .then((res) => res.json())
      .then((res) => setFilteredSubjectList1(res.data.data.filter((dt) => dt.active !== 0 && dt.active !== 2))))
    }

  }

  const subjectFilterConceptHandler = async (event) => {
    setSubject(event.target.value)

    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    if (event.target.value !== 'ALL') {
      getUserToken().then((userToken) => {
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
        },
        body: JSON.stringify({
          subject_id: event.target.value
        })
      }
      fetch (globalURL + "topic/get_topic_by_subject", 
        requestOptions
      )
      .then((res) => res.json())
      .then((res) => setFilteredTopicList1(res.data.data.filter((dt) => dt.active !== 0 && dt.active !== 2)))
      })
    }

    if ( event.target.value === 'ALL' ) {
      setFilteredConceptList(conceptList)
      setFilteredTopicList1(topicList.filter((dt) => dt.active !== 0 && dt.active !== 2))
    }
    else (
      setFilteredConceptList(conceptList.filter((concept) => concept.subject_id === event.target.value))
    )
  }

  const topicFilterConceptHandler = (event) => {
    setTopic(event.target.value)

    if ( event.target.value === 'ALL' ) {
      setFilteredConceptList(conceptList)
    }
    else (
      setFilteredConceptList(conceptList.filter((concept) => concept.topic === event.target.value))
    )
  }

  const filterHandler = () => {
    setFilteredConceptList(conceptList.filter((concept) => concept.class_id === clas &&  concept.subject_id === subject && concept.topic_id === topic))
  }

  const fetchConceptList = async () => {
    
    return ( await fetch(
      globalURL + "adminVideo/get_concept"
    )
      .then((res) => res.json())
      .then((res) => {
        setConceptList(res.data.filter((dt) => dt.active !== 2)); 
        setFilteredConceptList(res.data.filter((dt) => dt.active !== 2))
        setLoading(false);
      })
    )
  };

  useEffect(() => {
    if (location.state?.class){
      setClas(location.state?.class);
    }
    if (location.state?.subject){
      setSubject(location.state?.subject);
    }
    if (location.state?.topic){
      setTopic(location.state?.topic);
    }

  }, [location])

  const Updateapi = async (value) => {
    
    setLoading(true);
    handleCloseupdate();
    const formData = new FormData();
    formData.append('video', concept)

    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
      },
      body: formData
    }
      fetch (
      globalURL + `adminVideo/update_concept?concept_id=${updatedConceptId}&class_id=${updatedClassId}&subject_id=${updatedSubjectId}&topic_id=${updatedTopicId}&concept_name=${updatedConceptName}&active=${activity}&board_id=${updatedBoard}`,
      requestOptions
    )
    .then((res) => res.json())
    .then((res) => {
      if (res.message === 'Success') {
        setLoading(false);
        handleCloseupdate();
        setUpdatedClassId('')
        setUpdatedBoard('')
        setUpdatedSubjectId('')
        setUpdatedTopicId('')
        fetchConceptList();
        setUpdatedConceptId('')
        setUpdatedConceptName('')
        openSuccessSB();
        handleCloseupdate();
      }
      else {
        setLoading(false);
        handleCloseupdate();
        setOpenupdate(false);
        setUpdatedClassId('')
        setUpdatedBoard('')
        setUpdatedSubjectId('')
        setUpdatedTopicId('');
        fetchConceptList();
        setUpdatedConceptId('')
        setUpdatedConceptName('')
        openErrorSB();
      }
    })
  })
  };

  const deleteConcept = async (value) => {
    
    setLoading(true);
    handleCloseupdate();
    const formData = new FormData();
    formData.append('video', '')

    async function getUserToken() {
      return await localStorage.getItem("userInfo");
    }
    getUserToken().then((userToken) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        'Authorization': "Bearer " + userToken.replace('"', "").replace('"', ""),
      },
      body: formData
    }
      fetch (
      globalURL + `adminVideo/update_concept?concept_id=${updatedConceptId}&class_id=${updatedClassId}&subject_id=${updatedSubjectId}&topic_id=${updatedTopicId}&concept_name=${updatedConceptName}&active=${2}`,
      requestOptions
    )
    .then((res) => res.json())
    .then((res) => {
      if (res.message === 'Success') {
        setLoading(false);
        handleCloseDelete();
        setUpdatedClassId('')
        setUpdatedSubjectId('')
        setUpdatedTopicId('')
        fetchConceptList();
        setUpdatedConceptId('')
        setUpdatedBoard('')
        setUpdatedConceptName('')
        openSuccessSB();
        handleCloseupdate();
      }
      else {
        setLoading(false);
        handleCloseDelete();
        setOpenupdate(false);
        setUpdatedClassId('')
        setUpdatedSubjectId('')
        setUpdatedTopicId('');
        setUpdatedBoard('')
        fetchConceptList();
        setUpdatedConceptId('')
        setUpdatedConceptName('')
        openErrorSB();
      }
    })
  })
  };

  useEffect(() => {
    
    fetchConceptList();
  
  }, []);

  useEffect(() => {
    setOpenNone(true? filteredConceptList.length  === 0 : false)
  }, [filteredConceptList])

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Concept Updated Successfully"
      content="Your Concept is now updated successfully"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content="Getting Error while updating Concept"
      dateTime="Just Now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card style={{marginTop: '2rem', justifyContent: 'center'}}>
        <MDBox py={3}>
          <MDBox
            mx={2}
            mt={-6}
            py={3}
            px={2}
            variant="gradient"
            bgColor="secondary"
            borderRadius="lg"
            coloredShadow="secondary"
          >
            <MDButton
              href='/conceptupload'
              variant="contained"
              color="white"
              size="small"
            >
              Upload new Concept
            </MDButton>

            <Modal
              open={openupdate}
              onClose={handleCloseupdate}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h4"
                  component="h2"
                >
                  Update Your Concept
                </Typography>
                <Grid container justifyContent={'space-between'}>
                  <Grid item xs={3.5}>
                    <FormControl 
                      fullWidth
                      style={{ marginTop: "20px" }}
                    >
                      <InputLabel id="demo-simple-select-label">BOARD</InputLabel>
                      <Select
                        style={{padding: 11}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={updatedBoard}
                        label="BOARD"
                        onChange={(e) => {
                          setUpdatedBoard(e.target.value)
                          fetchClassByBoard(e.target.value)
                        }}
                      >
                        {boardList.map((med) => {
                          return <MenuItem value={med.board_id} key={med.board_id}>{med.board_name}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3.5}>
                    <FormControl 
                      fullWidth
                      style={{ marginTop: "20px" }}
                    >
                      <InputLabel id="demo-simple-select-label">CLASS</InputLabel>
                      <Select
                        style={{padding: 11}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={updatedClassId}
                        label="CLASS"
                        onChange={(e) => {
                          setUpdatedClassId(e.target.value)
                          fetchSubjectByClass(e.target.value)
                        }}
                      >
                        {filteredClassList.map((cls) => {
                          return <MenuItem value={cls.class_id} key={cls.class_id}>{cls.class_name}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3.5}>
                    <FormControl 
                      fullWidth
                      style={{ marginTop: "20px" }}
                    >
                      <InputLabel id="demo-simple-select-label">SUBJECT</InputLabel>
                      <Select
                        style={{padding: 11}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={updatedSubjectId}
                        label="SUBJECT"
                        onChange={(e) => {
                          setUpdatedSubjectId(e.target.value);
                          fetchTopicBySubject(e.target.value);
                        }}
                      >
                        {filteredSubjectList.map((sub) => {
                          return <MenuItem value={sub.subject_id} key={sub.subject_id}>{sub.subject}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid item xs={3.5}>
                    <FormControl 
                      fullWidth
                      style={{ marginTop: "20px" }}
                    >
                      <InputLabel id="demo-simple-select-label">TOPIC</InputLabel>
                      <Select
                        style={{padding: 12}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={updatedTopicId}
                        label="TOPIC"
                        onChange={(e) => setUpdatedTopicId(e.target.value)}
                      >
                        {filteredTopicList.map((tpc) => {
                          return <MenuItem value={tpc.topic_id} key={tpc.topic_id}>{tpc.topic}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3.5}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label="CONCEPT NAME"
                        variant="outlined"
                        value={updatedConceptName}
                        onChange={(e) => setUpdatedConceptName(e.target.value)}
                        style={{ marginTop: "20px" }}
                      />
                  </Grid>
                  <Grid item xs={3.5} mt={1}>
                    <InputLabel id="activityLabel">Activity Status: </InputLabel>
                    <Select
                      fullWidth
                      style={{padding: 11.5}}
                      labelId="activityLabel"
                      id="activitySelect"
                      value={activity}
                      label="Activity Status: "
                      onChange={(e) => setActivity(e.target.value)}
                    >
                      <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} key={Math.random().toString()} >Active</MenuItem>
                      <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} key={Math.random().toString()} >InActive</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid container justifyContent={'center'}>
                  <Grid item>
                    <input
                      style={{width: '15rem', marginTop: '1.25rem'}}
                      id='subs'
                      type="file"
                      multiple
                      accept=".pdf"
                      onChange={(e) => {
                        setConcept(e.target.files[0])
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid 
                  container 
                  justifyContent={'space-evenly'}
                  style={{ marginTop: "20px" }}
                >
                  <Grid item xs={4}>
                    <MDButton
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={Updateapi}
                      disabled={
                        !updatedClassId
                        || !updatedSubjectId || !updatedTopicId 
                        || !updatedConceptName
                          ? true
                          : false
                      }
                    >
                      Update
                    </MDButton>
                  </Grid>
                  <Grid item xs={4}>
                    <MDButton
                      fullWidth
                      variant="contained"
                      color="dark"
                      size="small"
                      onClick={handleCloseupdate}
                    >
                      CLOSE
                    </MDButton>
                  </Grid>
                </Grid>
              </Box>
            </Modal>

            <Modal
              open={openDelete}
              onClose={handleCloseDelete}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} style={{textAlign: 'center'}}>
                <Typography
                  id="modal-modal-title"
                  variant="h3"
                  component="h2"
                  style={{marginBottom: '2rem'}}
                >
                  Are You Sure You Want To Delete This Concept?
                </Typography>
                {updatedConceptName}
                <Grid 
                  container 
                  justifyContent={'space-evenly'}
                  style={{ marginTop: "2rem" }}
                >
                  <Grid item xs={4}>
                    <MDButton
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={deleteConcept}
                    >
                      Delete
                    </MDButton>
                  </Grid>
                  <Grid item xs={4}>
                    <MDButton
                      fullWidth
                      variant="contained"
                      color="dark"
                      size="small"
                      onClick={handleCloseDelete}
                    >
                      CLOSE
                    </MDButton>
                  </Grid>
                </Grid>
              </Box>
            </Modal>

            <Modal
              open={openNone}
              onClose={handleCloseNone}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} style={{textAlign: 'center'}}>
                <Typography
                  id="modal-modal-title"
                  variant="h4"
                  component="h2"
                  style={{marginBottom: '2rem'}}
                >
                  No Data Available!!
                </Typography>
                <Grid 
                  container 
                  justifyContent={'center'}
                  style={{ marginTop: "2rem" }}
                >
                  <Grid item xs={4}>
                    <MDButton
                      fullWidth
                      variant="contained"
                      color="dark"
                      size="small"
                      onClick={handleCloseNone}
                    >
                      CLOSE
                    </MDButton>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
              
              {renderSuccessSB}
              {renderErrorSB}
          </MDBox>
          <Grid container justifyContent={'space-evenly'}>
            <Grid item lg={2} md={3} xs={6} ml={5} mt={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Board</InputLabel>
                <Select
                  style={{padding: 10}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={board}
                  label="Class"
                  onChange={boardFilterVideoHandler}
                >
                  <MenuItem value={'ALL'}>ALL</MenuItem>
                  {boardList.map((brd) => {
                    if( brd.active === 1 ) return <MenuItem value={brd.board_id} key={brd.board_id}>{brd.board_name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={2} md={3} xs={6} mt={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Class</InputLabel>
                <Select
                  style={{padding: 10}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={clas}
                  label="Class"
                  onChange={classFilterConceptHandler}
                >
                  <MenuItem value={'ALL'}>ALL</MenuItem>
                  {filteredClassList1.map((cls) => {
                    if( cls.active === 1 ) return <MenuItem value={cls.class_id} key={cls.class_id}>{cls.class_name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={2} md={3} xs={6} mt={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Subject</InputLabel>
                <Select
                  style={{padding: 10}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subject}
                  label="Subject"
                  onChange={subjectFilterConceptHandler}
                >
                  <MenuItem value={'ALL'}>ALL</MenuItem>
                  {filteredSubjectList1.map((sub) => {
                    if( sub.active === 1 ) return <MenuItem value={sub.subject_id} key={sub.subject_id}>{sub.subject}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={2} md={3} xs={6} mt={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Topic</InputLabel>
                <Select
                  style={{padding: 10}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={topic}
                  label="Topic"
                  onChange={topicFilterConceptHandler}
                >
                  <MenuItem value={'ALL'}>ALL</MenuItem>
                  {filteredTopicList1?.map((tpc) => {
                    if( tpc.active === 1 ) return <MenuItem value={tpc.topic_id} key={tpc.topic_id}>{tpc.topic}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} mt={3} >
              <Button
                fullWidth
                variant='contained'
                onClick={filterHandler}
                style={{color: '#ffffff', background: 'rgb(0,5,255)'}}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
          <Grid item mt={6} lg={12}>
            {loading ? 
              <Grid container justifyContent={'center'}height={'80vh'}>
                <SpinnerInfinity 
                  size={190} 
                  thickness={180} 
                  speed={100} 
                  color="rgba(59, 172, 57, 1)" 
                  secondaryColor="rgba(57, 145, 172, 0.44)" 
                />
              </Grid>
              :  <Grid container justifyContent={'space-evenly'}>
              {filteredConceptList.map((concept) => {
                return (
                  <Card style={{background: '#bee5ff', width: '25rem', alignItems: 'center', marginBottom: '2rem', padding: '1rem'}} key={Math.random().toString()} >
                      <Grid item lg={11} md={11} xs={11}>
                        <Grid container direction="row" spacing={2}>
                          <Grid item lg={12} >
                            <Box >
                              <Typography variant="h5"> Concept Name: {concept.concept_name}</Typography>
                              <Typography variant="h5">Class: {concept.class_master?.class_name}</Typography> 
                              <Typography variant="h5">Subject: {concept.subject_master?.subject}</Typography>
                              <Typography variant="h5">Topic: {concept.topic_master?.topic}</Typography>
                              <Button 
                                href={concept.concept_pdf}
                                variant='contained'
                                style={{color: '#ffffff', padding: '1rem', width: '21rem', marginTop: '1rem', background: 'rgb(0,5,255)'}} //, marginTop: '6.2rem'
                              >
                                Click to download the concept</Button>
                              <Grid container mt={2} justifyContent={'space-between'}>
                                <Grid item xs={6}>                                  
                                  <Button 
                                    fullWidth
                                    onClick={() => {
                                      setOpenupdate(true);
                                      setUpdatedClassId(concept.class_id);
                                      setUpdatedSubjectId(concept.subject_id);
                                      setUpdatedTopicId(concept.topic_id);
                                      setUpdatedConceptName(concept.concept_name);
                                      setUpdatedConceptId(concept.concept_id);
                                      setUpdatedBoard(concept.board_id)
                                      setActivity(concept.active)
                                    }}
                                    variant='contained'
                                    style={{color: '#ffffff', padding: '1rem', background: 'rgb(0,5,255)'}} //, marginTop: '6.2rem'
                                  >
                                    Edit
                                  </Button>
                                </Grid>
                                <Grid item xs={6} align={'right'}>
                                  <Button 
                                    variant='contained'
                                    style={{color: '#ffffff', padding: '1rem', width: '10rem', background: 'rgb(0,5,255)'}} //, marginTop: '6.2rem'
                                    onClick={() => {
                                      setOpenDelete(true);
                                      setUpdatedClassId(concept.class_id);
                                      setUpdatedSubjectId(concept.subject_id);
                                      setUpdatedTopicId(concept.topic_id);
                                      setUpdatedConceptName(concept.concept_name);
                                      setUpdatedConceptId(concept.concept_id);
                                      setActivity(concept.active)
                                    }}
                                  >
                                    Delete</Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                    </Grid>
                  </Card>
                );
              })}
            </Grid>}
          </Grid>
        </MDBox>
      </Card>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default ConceptList;
