import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { CSVLink } from "react-csv";
import axios from "axios";
import globalURL from "../../../config";
// import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import tw from "twin.macro";
import { GlobalFilter } from "./GlobalFilter";
import '../../../index.css'
import colors from "assets/theme/base/colors";

const Table = tw.table`
  table-fixed
  text-base
  text-gray-900
`;

const TableHead = tw.thead`
  p-2
  border-blue-500
  bg-gradient-to-l
  from-[#EC407A]
  to-[#D81B60]
`;

const TableRow = tw.tr`
  text-center
  border
  border-blue-500
`;

const TableHeader = tw.th`
  border
  p-2
`;

const TableBody = tw.tbody`

`;

const TableData = tw.td`
  border
  border-blue-500
  p-5
`;

const Button = tw.button`
  pl-4
  pr-4
  pt-2
  pb-2
  text-white
  rounded-md
  bg-[#e91e63]
  hover:bg-blue-300
  hover:cursor-pointer
  transition-colors
`;

export function AdminData(props) {

  const [users, setusers] = useState([]);
  const [ adminType, setAdminType ] = useState('');
  const [ adminTypeList, setAdminTypeList ] = useState([]);
  const [ filterCategoryList, setFilterCategoryList ] = useState([]);
  const [ activity, setActivity ] = useState('ALL');
  const [ csvReport, setCsvReport ] = useState({
    data: users,
    headers: [
      { label:  "User Id", key: "adminId"},
      { label: "User Name", key: "adminName"},
    ],
    filename: 'trial.csv'
  });

  const activityChangeHandler = (e) => {
    setActivity(e.target.value)
    if (e.target.value === 'ALL') {
      setFilterCategoryList(users)
    }
    else {
      setFilterCategoryList(users.filter((brd) => brd.active === e.target.value))
    }
  }

  useEffect(() => {
    setusers(props.adminList);
    setFilterCategoryList(props.adminList);
  }, [props.adminList])

  const filterButtonClickHandler = () => {
    if (adminType === "All") {
      setFilterCategoryList(users);
    }
    else {
      setFilterCategoryList(users.filter((users) => users.user_type === adminType))
    }
  }

  useEffect(() => {
    
    const headers = [
      { label:  "Admin Name", key: "adminName"},
      { label: "Email", key: "adminEmail"},
      { label: "Status", key: "active"},
      { label: "Admin Type", key: "user_type" },
      { label: "Date Created", key: "date_created"}
    ]

    setCsvReport({
      data: filterCategoryList,
      headers: headers,
      filename: 'Admin List.csv'
    })

  }, [filterCategoryList])

  const fetchAdminTypeList = async () => {
    const response = await axios
      .get(globalURL + "userType/show_userType")
      .catch((err) => console.log(err));

    if (response) {
      const users = response.data;

      setAdminTypeList(users.data);
    }
  };

  const usersData = useMemo(() => [...filterCategoryList], [filterCategoryList]);

  const usersColumns = useMemo(
    () =>
      filterCategoryList[0]
        ? Object.keys(filterCategoryList[0])
            .filter((key) => key !== "adminId" && key !== 'emp_id' && key !== 'password' && key !== 'salt' && key !== 'date_modified')
            .map((key) => {
              if (key === "active")
                return {
                  Header: 'status',
                  accessor: key,
                  Cell: ({ value }) => <b><h4>{value===0? <p style={{color: 'red'}}>InActive</p>: <p style={{color: 'green'}}>Active</p>}</h4></b>,
                  
                };
              if (key === "date_created")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <p style={{width: 90}}>{value}</p>,
                  
                };

              return { Header: key, accessor: key };
            })
        : [],
    [filterCategoryList]
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "ACTIONS",
        Header: "ACTIONS",
        Cell: ({ row }) => (
          <>
            {/* <Button 
              style={{width: '5rem'}}
              onClick={() => {
                props.setOpenMore(true)
            }}>
              View
            </Button> */}
            <Button 
              style={{margin: '0 1rem', width: '5rem'}}
              onClick={() => {
                props.setOpenupdate(true)
                props.setFirstName(row.original.adminName)
                props.setEmail(row.original.adminEmail)
                props.setEmployeeId(row.original.emp_id)
                props.setAdminPassword(row.original.password)
                props.setUserType(row.original.user_type)
                props.setAdminId(row.original.adminId)
                props.setActivity(row.original.active)
            }}>
              Edit
            </Button>
            <Button 
              style={{width: '5rem'}}
              onClick={() => {
                props.setOpenDelete(true)
                props.setFirstName(row.original.adminName)
                props.setEmail(row.original.adminEmail)
                props.setEmployeeId(row.original.emp_id)
                props.setAdminPassword(row.original.password)
                props.setUserType(row.original.user_type)
                props.setAdminId(row.original.adminId)
                props.setActivity(row.original.active)
            }}>
              Delete
            </Button>
          </>
        ),
      },
    ]);
  };

  const tableInstance = useTable(
    {
      columns: usersColumns,
      data: usersData,
    },
    useGlobalFilter,
    tableHooks,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = tableInstance;

  useEffect(() => {
    fetchAdminTypeList();
  }, []);

  const isEven = (idx) => idx % 2 === 0;

  return (
    <>
      <Grid container justifyContent={'space-between'} mb={5}>
        <Grid item xs={4} >
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
        </Grid>
        <Grid item lg={3}  md={8}>
          <FormControl 
            fullWidth
          >
            <InputLabel id="activityLabel">Activity Status: </InputLabel>
              <Select
                fullWidth
                style={{padding: 11}}
                labelId="activityLabel"
                id="activitySelect"
                value={activity}
                label="Activity Status: "
                onChange={activityChangeHandler}
              >
                <MenuItem value={'ALL'} style={{color: 'green', fontWeight: 'bold'}} >ALL</MenuItem>
                <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
                <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
              </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Admin Type</InputLabel>
            <Select
              style={{padding: 10}}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={adminType}
              label="Admin Type"
              onChange={(e) => setAdminType(e.target.value)}
            >
              <MenuItem value={'All'}>All</MenuItem>
              {adminTypeList.map((admin) => {
                return <MenuItem value={admin.userType} key={Math.random().toString()}>{admin.userType}</MenuItem>
              })}
            </Select>
            <Button 
              onClick={filterButtonClickHandler} 
              variant='contained' 
              style={{marginTop: '1rem', color: '#fff', width: '100%'}} 
            >
              Filter
            </Button>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'}>
        <Grid item flex style={{background: '#e91e63', color: '#ffffff', fontSize: '1.2rem', padding: '0.8rem', textAlign: 'center', borderRadius: '50%', width: '70px', border: '4px solid',}}>
          <FormControl fullWidth>
            {filterCategoryList.length < 2 ? null :
              <CSVLink {...csvReport}>&#8595;</CSVLink>
            }
          </FormControl>
        </Grid>
      </Grid>
      <Table {...getTableProps()}>
        <TableHead className={"bg-[rgb(0,5,255)] text-white"}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeader
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header").toUpperCase().replace('_',' ')}
                  {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);

            return (
              <TableRow
                {...row.getRowProps()}
                className={!isEven(idx) ? "bg-[#e91e63] bg-opacity-30" : ""}
              >
                {row.cells.map((cell, idx) => (
                  <TableData {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableData>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}