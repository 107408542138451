import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from 'AuthContext';

// const isAuth = true? localStorage.getItem('userType') !== 'null' : false;

const ProtectedRoutes = () => {

  const {isAuth} = useContext(AuthContext);

  return isAuth ? <Outlet /> : <Navigate to='/'/>
}

export default ProtectedRoutes
