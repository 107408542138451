import React, { useContext, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import tw from "twin.macro";
import { GlobalFilter } from "./GlobalFilter";
import '../../../index.css'
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import ListContext from "layouts/apiContext/listContext";

const Table = tw.table`
  table-fixed
  text-base
  text-gray-900
`;

const TableHead = tw.thead`
  p-2
  bg-gradient-to-r
  from-[#7b809a]
  to-[#8f93a9]
`;

const TableRow = tw.tr`
  text-center
  border
  border-blue-500
`;

const TableHeader = tw.th`
  border
  border-blue-500
  p-2
`;

const TableBody = tw.tbody`

`;

const TableData = tw.td`
  border
  border-blue-500
  p-5
`;

const Button = tw.button`
  pl-4
  pr-4
  pt-2
  pb-2
  text-white
  rounded-md
  bg-[#7b809a]
  hover:bg-blue-300
  hover:cursor-pointer
  transition-colors
`;

export function BoardData(props) {
  
  const { 
    boardList
  } = useContext(ListContext);

  const [ filteredBoardList, setFilteredBoardList ] = useState(boardList);
  const boardsData = useMemo(() => [...filteredBoardList], [filteredBoardList]);
  const [ activity, setActivity ] = useState('ALL');
  const [ csvReport, setCsvReport ] = useState({
    data: props.boardList,
    headers: [
      { label:  "Board Id", key: "board_id"},
      { label: "Board Name", key: "board_name"},
    ],
    filename: 'trial.csv'
  });

  const activityChangeHandler = (e) => {
    setActivity(e.target.value)
    if (e.target.value === 'ALL') {
      setFilteredBoardList(boardList)
    }
    else {
      setFilteredBoardList(boardList.filter((brd) => brd.active === e.target.value))
    }
  }

  useEffect(() => {
    setFilteredBoardList(boardList);
  }, [boardList])

  useEffect(() => {
    
    const headers = [
      { label:  "Board Id", key: "board_id"},
      { label: "Board Name", key: "board_name"},
    ]

    setCsvReport({
      data: boardsData,
      headers: headers,
      filename: 'Board List.csv'
    })

  }, [boardsData])

  const boardsColumns = useMemo(
    () =>
      filteredBoardList[0]
        ? Object.keys(filteredBoardList[0])
            .filter((key) => key !== 'date_modified' && key !== 'board_id' && key !== "created_by")
            .map((key) => {
              if (key === "active")
                return {
                  Header: 'status',
                  accessor: key,
                  Cell: ({ value }) => <h4><b>{value===0? <p style={{color: 'red'}}>InActive</p>: <p style={{color: 'green'}}>Active</p>}</b></h4>,
                  
                };
              if (key === "date_created")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <p>{value.slice(0,-14)}</p>,
                };
              if (key === "adminMaster")
                return {
                  Header: "Created By",
                  accessor: key,
                  Cell: ({ value }) => <p>{value.adminName}</p>,
                  
                };
              return { Header: key, accessor: key };
            })
        : [],
    [filteredBoardList]
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "ACTIONS",
        Header: "ACTIONS",
        Cell: ({ row }) => (
          <>
            <Button 
              style={{marginRight: '1rem', width: '5rem'}}
              onClick={() => {
                props.setBoardName(row.original.board_name)
                props.setUpdatedBoardId(row.original.board_id)
                props.setActivity(row.original.active)
                props.setOpenupdate(true)
            }}>
              Edit
            </Button>
            <Button 
              style={{width: '5rem'}}
              onClick={() => {
                props.setBoardName(row.original.board_name)
                props.setUpdatedBoardId(row.original.board_id)
                props.setOpenDelete(true)
            }}>
              Delete
            </Button>
          </>
        ),
      },
    ]);
  };

  const tableInstance = useTable(
    {
      columns: boardsColumns,
      data: boardsData,
    },
    useGlobalFilter,
    tableHooks,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = tableInstance;

  // useEffect(() => {
  //   fetchboardList();
  // }, []);

  const isEven = (idx) => idx % 2 === 0;

  return (
    <>
      <Grid container justifyContent={'space-between'} mb={5}>
        <Grid item lg={4} md={8}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
        </Grid>
        <Grid item lg={3}  md={8}>
          <FormControl 
            fullWidth
          >
            <InputLabel id="activityLabel">Activity Status: </InputLabel>
              <Select
                fullWidth
                style={{padding: 11}}
                labelId="activityLabel"
                id="activitySelect"
                value={activity}
                label="Activity Status: "
                onChange={activityChangeHandler}
              >
                <MenuItem value={'ALL'} style={{color: 'green', fontWeight: 'bold'}} >ALL</MenuItem>
                <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
                <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
              </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'}>
        <Grid item flex style={{background: '#7b809a', color: '#ffffff', fontSize: '1.2rem', padding: '0.8rem', textAlign: 'center', borderRadius: '50%', width: '70px', border: '4px solid',}}>
          <FormControl fullWidth>
            {boardsData.length < 2 ? null :
              <CSVLink {...csvReport}>&#8595;</CSVLink>
            }
          </FormControl>
        </Grid>
      </Grid>
      <Table {...getTableProps()} style={{maxWidth: '100%'}}>
        <TableHead className={"bg-[#1D72E8] text-white"}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeader
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header").toUpperCase().replace('_',' ')}
                  {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);

            return (
              <TableRow
                {...row.getRowProps()}
                className={!isEven(idx) ? "bg-[#7b809a] bg-opacity-30" : ""}
              >
                {row.cells.map((cell, idx) => (
                  <TableData {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableData>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}