/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { v4 } from 'uuid';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { useContext, useEffect, useState } from "react";
import { AdminData } from "../tables/data/AdminData";
import tw from "twin.macro";
import { SpinnerInfinity } from "spinners-react";
import MDButton from "components/MDButton";
import { Box, Button, FormControl, FormGroup, InputLabel, MenuItem, Modal, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import globalURL from "../../config";
import axios from "axios";
import ListContext from "layouts/apiContext/listContext";
import { valid } from "chroma-js";
import { UserSubscriptionData } from "layouts/tables/data/UserSubscriptionData";
import { useLocation } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: '2rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AppContainer = tw.div`
  w-full
  max-w-full
  flex
  flex-col
  items-stretch
  justify-center
  pt-6
  pb-10
  pl-10
  pr-10
`;

function Tables() {

  const { fetchSubscriptionList, subscriptionList, userSubscriptionList, fetchUserSubscriptionList } = useContext(ListContext);
  const [ subscription_name, setSubscriptionName ] = useState('');
  const [ subscriptioinID, setSubscriptioinID ] = useState("");
  const [ user_subscriptioinID, setUserSubscriptioinID ] = useState("");
  const [ valid_days, setValidDays ] = useState("");
  const [ date_started, setDateStarted ] = useState("");
  const [ paymentSelected, setPaymentSelected ] = useState(false);
  const [ end_date, setEndDate ] = useState("");
  const [ amount, setAmount ] = useState("");
  const [ activity, setActivity ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const location = useLocation();

  const [ baseImage, setBaseImage ] = useState('');
  const [ oldImage, setOldImage ] = useState('');

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [errorSB, setErrorSB] = useState(false);

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [openupdate, setOpenupdate] = useState(false);
  const handleCloseupdate = () => setOpenupdate(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => setOpenDelete(false);

  const [ message, setMessage ] = useState('');
  const [ openMessage, setOpenMessage ] = useState(false);
  const handleCloseMessage = () => setOpenMessage(false);


  const uploadImage = async (image) => {
    
    const file = image.target.files[0]
    const base64 = await convertBase64(file)
    setBaseImage(base64);

  }

  const convertBase64 = (file) => {

    return new Promise((resolve, reject) => {

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = (() => {
        resolve(fileReader.result);
      });

      fileReader.onerror = ((error) => {
        reject(error);
      });

    });

  }
  const [openMore, setOpenMore] = useState(false);
  const handleCloseMore = () => {
    setOpenMore(false)
  };

  useEffect(() => {
    fetchSubscriptionList();
    fetchUserSubscriptionList();
  }, []);

  const createPayment = async () => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: location?.state?.data?.user_id,
        amount,
        refund: "0",
        order_id: `order-${v4()}`,
        paymentImage: baseImage.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '')
      }),
    };
    await fetch(
      globalURL + "adminSubcription/create_payment",
      requestOptions
    )
    .then(res => res.json())
    .then(res => {
      if (res.message === "Success") {
        setAmount("");
        setBaseImage("");
        handleAPI();
      } else {
        setLoading(false);
      }
    })
  }

  const createEnrollment = async () => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: location?.state?.data?.user_id,
        amount,
        refund: "0",
        subscriptioinID: "",
        date_started,
        end_date,
        order_id: "from prev res",
        paymentImage: baseImage.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '')
      }),
    };
    await fetch(
      globalURL + "adminSubcription/create_enrol_payment",
      requestOptions
    )
    .then(res => res.json())
    .then(res => {
      if (res.message === "Success") {
        // setAmount("");
        setBaseImage("");
        handleAPI();
      } else {
        setLoading(false);
      }
    })
  }

  const handleAPI = async () => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: location?.state?.data?.user_id,
        subscriptioinID
      }),
    };
    await fetch(
      globalURL + "adminSubcription/create_user_subcription",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          setMessage(res.message);
          setOpenMessage(true);
          handleClose();
          setLoading(false);
          setSubscriptionName("");
          fetchUserSubscriptionList();
          setValidDays();
          openSuccessSB();
        } else {
          // fetchUsersList();
          handleClose();
          setValidDays("");
          fetchUserSubscriptionList();
          setSubscriptionName("");
          setMessage(res.message);
          setLoading(false);
          setOpenMessage(true);
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateAPI = async () => {
    setLoading(true);
    const requestOptions = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        date_started,
        end_date,
        subscriptioinID,
        user_subscriptioinID
      }),
    };
    await fetch(
      globalURL + "adminSubcription/update_user_subcription",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "Success") {
          fetchSubscriptionList();
          handleCloseupdate();
          setMessage(res.message);
          setSubscriptioinID("");
          fetchUserSubscriptionList();
          setLoading(false);
          setOpenMessage(true);
          setValidDays("");
          setSubscriptionName("");
          openSuccessSB();
        } else {
          setMessage(res.message);
          setOpenMessage(true);
          fetchUserSubscriptionList();
          setValidDays("");
          setSubscriptionName("");
          setSubscriptioinID("");
          setLoading(false);
          // fetchUsersList();
          fetchSubscriptionList();
          handleCloseupdate();
          openErrorSB();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

//   const deleteUser = async () => {
//     setLoading(true);
//     const requestOptions = {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         "adminEmail": amount,
//         // "password": adminPassword,
//         "adminName": subscription_name,
//         "userType": userType,
//         "emp_id": employeeId,
//         "valid_days": valid_days,
//         active: 2
//       }),
//     };
//     await fetch(
//       globalURL + "admin/update_admin",
//       requestOptions
//     )
//       .then((res) => res.json())
//       .then((res) => {
//         if (res.message === "Success") {
//           fetchAdminList();
//           handleCloseDelete();
//           setMessage(res.message);
//           setLoading(false);
//           setOpenMessage(true);
//           setValidDays('');
//           setSubscriptionName("");
//           setType('');
//           setAmount('');
//           setAdminPassword();
//           openSuccessSB();
//         } else {
//           setMessage(res.message);
//           setOpenMessage(true);
//           setLoading(false);
//           // fetchUsersList();
//           fetchAdminList();
//           handleCloseDelete();
//           openErrorSB();
//         }
//       })
//       .catch((e) => {
//         console.log(e);
//       });
//   };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Subscription Updated Successfully"
      content="Your Subscription is now updated successfully"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content="Getting Error while updating Subscription"
      dateTime="Just Now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
              >
              <MDButton
                  variant="contained"
                  size="small"
                  onClick={handleOpen}
                  className={'text-[#000000] bg-white'}
              >
                Create new User Subscription
              </MDButton>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                  >
                    Create New Subscription for {`${location?.state?.data?.first_name} ${location?.state?.data?.last_name}`}
                  </Typography>
                  <Grid container my={'1.5rem'} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <InputLabel id="subscriptionLabel">Subscription: </InputLabel>
                      <Select
                        fullWidth
                        style={{padding: 11}}
                        labelId="subscriptionLabel"
                        id="subscriptionSelect"
                        value={subscriptioinID}
                        label="Subscription: "
                        onChange={(e) => setSubscriptioinID(e.target.value)}
                      >
                        {
                          subscriptionList?.map(sub => (
                            <MenuItem value={sub.subscriptioinID} key={sub.subscriptioinID} style={{color: 'green', fontWeight: 'bold'}} >{sub.subscription_name}</MenuItem>
                          ))
                        }
                      </Select>
                    </Grid>
                    <Grid xs={5.5} 
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end"
                      }}
                    >
                      <Button
                        size="large"
                        variant="contained"
                        style={{
                          color: "white"
                        }}
                        onClick={() => setPaymentSelected(true)}
                      >
                        Click Here for Payment Details
                      </Button>
                    </Grid>
                    {
                      paymentSelected? 
                        <Grid item xs={10}>
                          <Grid item xs={5} m="auto">
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="Amount"
                              variant="outlined"
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                              style={{ marginTop: "20px", marginBottom: "20px" }}
                            />
                        </Grid>
                        <Grid container justifyContent={'center'}>
                          <Grid item xs={4} mb={2} alignContent={'center'}>
                            <input
                              type="file"
                              multiple
                              accept="image/*"
                              onChange={(image) => uploadImage(image)}
                            />
                          </Grid>
                          <Grid item xs={11} justifyContent={'center'}>
                            {!baseImage? null : <img 
                              src={baseImage} 
                              style={{display: 'flex' , margin: 'auto', height: '13rem' , width: '13rem'}} 
                              alt=''
                            />}
                          </Grid>
                        </Grid>
                      </Grid>
                        : null
                      }
                  </Grid>
                  {/* <Grid container m={'1.5rem 0'} py={2} justifyContent={'space-evenly'}>
                    <Grid item xs={5} mb={5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="SUBSCRIPTION NAME"
                        variant="outlined"
                        value={subscription_name}
                        onChange={(e) => setSubscriptionName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={5} mb={5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="SUBSCRIPTION TYPE"
                        variant="outlined"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="AMOUNT"
                        variant="outlined"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="VALID DAYS"
                        variant="outlined"
                        value={valid_days}
                        onChange={(e) => setValidDays(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}
                  <Grid container justifyContent={'space-evenly'}>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={createPayment}
                        disabled={
                          !subscriptioinID
                          ? true : false
                        }
                      >
                        SEND
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="medium"
                        onClick={handleClose}
                        >
                        CLOSE
                      </MDButton>
                    </Grid>                        
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openupdate}
                onClose={handleCloseupdate}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                  >
                    Update Subscription
                  </Typography>
                  <Grid container m={'1.5rem 0'} justifyContent={'space-evenly'}>
                    <Grid item xs={5} mb={5}>
                      <InputLabel className="mb-2" id="date_started">Date Started</InputLabel>
                      <OutlinedInput
                        labelId="date_started"
                        fullWidth 
                        type="date"
                        value={date_started}
                        onChange={(e) => setDateStarted(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={5} mb={5}>
                      <InputLabel className="mb-2" id="end_date">End Date</InputLabel>
                      <OutlinedInput
                        labelId="end_date"
                        fullWidth 
                        type="date"
                        value={end_date}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid container mb={'1.5rem'} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <InputLabel id="activityLabel">Activity Status: </InputLabel>
                      <Select
                        fullWidth
                        style={{padding: 11}}
                        labelId="activityLabel"
                        id="activitySelect"
                        value={activity}
                        label="Activity Status: "
                        onChange={(e) => setActivity(e.target.value)}
                      >
                        <MenuItem value={1} style={{color: 'green', fontWeight: 'bold'}} >Active</MenuItem>
                        <MenuItem value={0} style={{color: 'red', fontWeight: 'bold'}} >InActive</MenuItem>
                      </Select>
                    </Grid>
                  </Grid> */}
                  <Grid container justifyContent={'space-evenly'}>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={updateAPI}
                        disabled={
                          !date_started || !end_date?
                          true : false
                        }
                      >
                        SEND
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="medium"
                        onClick={handleCloseupdate}
                        >
                        CLOSE
                      </MDButton>
                    </Grid>                        
                  </Grid>
                </Box>
              </Modal>

              {/* <Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: 'center'}}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                    style={{marginBottom: '2rem'}}
                  >
                    Are You Sure You Want To Delete This User?
                  </Typography>
                  {subscription_name.toUpperCase()}
                  <Grid 
                    container 
                    justifyContent={'space-evenly'}
                    style={{ marginTop: "2rem" }}
                  >
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={deleteUser}
                      >
                        Delete
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="small"
                        onClick={handleCloseDelete}
                      >
                        CLOSE
                      </MDButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal> */}

              <Modal
                open={openMessage}
                onClose={handleCloseMessage}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: 'center'}}>
                  <Grid item xs={12}>
                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h2"
                      style={{marginBottom: '2rem',}}
                    >
                      {message}
                    </Typography>
                  </Grid>
                  <Grid 
                    container 
                    justifyContent={'center'}
                    style={{ marginTop: "2rem" }}
                  >
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="small"
                        onClick={handleCloseMessage}
                      >
                        CLOSE
                      </MDButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openMore}
                onClose={handleCloseMore}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{textAlign: 'center'}}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                    style={{marginBottom: '2rem'}}
                  >
                    More Info About: -
                  </Typography>
                  {/* {subscription_name?.toUpperCase()} {type?.toUpperCase()}
                  <Grid container m={'1.5rem 0'} py={2} justifyContent={'space-evenly'}>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="CITY"
                        variant="outlined"
                        value={city?.toUpperCase()}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="COUNTRY"
                        variant="outlined"
                        value={country?.toUpperCase()}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="GENDER"
                        variant="outlined"
                        value={gender?.toUpperCase()}
                        disabled
                      />
                    </Grid> */}
                  {/* </Grid> */}
                  <Grid 
                    container 
                    justifyContent={'space-evenly'}
                    style={{ marginTop: "2rem" }}
                  >
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="small"
                        // onClick={MoreUser}
                      >
                        Okay
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        size="small"
                        onClick={handleCloseMore}
                      >
                        CLOSE
                      </MDButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
              
              {renderSuccessSB}
              {renderErrorSB}
              </MDBox>
              <MDBox pt={3} style={{height: '44rem', overflow: 'auto'}}>
                {loading ? 
                  <Grid container justifyContent={'center'}height={'80vh'}>
                    <SpinnerInfinity 
                      size={190} 
                      thickness={180} 
                      speed={100} 
                      color="rgba(59, 172, 57, 1)" 
                      secondaryColor="rgba(57, 145, 172, 0.44)" 
                    />
                  </Grid> :
                <AppContainer>
                  <UserSubscriptionData 
                    setOpenupdate={setOpenupdate}
                    setValidDays={setValidDays}
                    setSubscriptionName={setSubscriptionName}
                    subscriptionList={subscriptionList}
                    userSubscriptionList={userSubscriptionList}
                    setDateStarted={setDateStarted}
                    setEndDate={setEndDate}
                    subs
                    setSubscriptioinID={setSubscriptioinID}
                    setUserSubscriptioinID={setUserSubscriptioinID}
                    setActivity={setActivity}
                    setOpenDelete={setOpenDelete}
                    setOpenMore={setOpenMore}
                    userData={location?.state?.data}
                  />
                </AppContainer>}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;